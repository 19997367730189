import React, { useState, useEffect, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import AgoraRTC from 'agora-rtc-sdk-ng';

import { AuthContext } from './../../../components/AuthProvider';
import { getStatusesApi } from './../../api/user'

import { getOrCreateUser, getRoles } from "./../../store/actions/UserActions";
import { getOrganizations } from "./../../store/actions/OrganizationActions";
import Loader from '../../../components/common/Loader';
import { getCurrentPositionInTeam, getTeams, receiveCurrentTeam } from '../../store/actions/TeamActions';
import { RoutePaths } from '../../contants';
import useAgora from '../../../hooks/useAgora';

export const DictionariesContext = React.createContext();
const { Consumer, Provider } = DictionariesContext;

const client = AgoraRTC.createClient({ mode: 'live', codec: 'vp8', excludedArea: "CHINA" });
client.setClientRole('host');
//AgoraRTC.setLogLevel(2);//3 - ERROR lvl

export const DictionariesProvider = ({ children }) => {
    const ctx = useContext(AuthContext);
    const dispatch = useDispatch();
    const [userAvailabilityStatuses, setUserAvailabilityStatuses] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const history = useHistory();
    const agoraObj = useAgora(client);

    useEffect(() => {
        (async () => {
            await ctx.getToken();

            try {
                const { data } = await getStatusesApi();
                setUserAvailabilityStatuses(data);

                const user = await dispatch(getOrCreateUser());

                const inviteId = localStorage.getItem('invite-id');
                if (inviteId) {
                    localStorage.removeItem('invite-id');

                    if (user.isGuest) {
                        ctx.logout();
                        return;
                    } else {
                        history.replace(`${RoutePaths.Invite}/${inviteId}`);
                        return;
                    }
                }

                await dispatch(getOrganizations());

                if (!user.defaultTeam) {
                    setLoaded(true);
                    return;
                }

                const teamId = await dispatch(getCurrentPositionInTeam()) || user.defaultTeam.id;

                if (teamId) {
                    await dispatch(receiveCurrentTeam(teamId));
                    await dispatch(getRoles(teamId));
                    await dispatch(getTeams());
                }

                setLoaded(true);
            } catch (e) {
                console.warn(e);
                //setLoaded(true);
            }
        })();
    }, []);

    useEffect(() => {
        if (!agoraObj) return;

        window._agoraHelper.setAgora(agoraObj);

        const onBeforeUnload = function () { agoraObj.clear(); }
        window.addEventListener("beforeunload", onBeforeUnload, false);

        return () => {
            window.removeEventListener("beforeunload", onBeforeUnload);
        }
    }, [agoraObj]);

    const dictionaries = {
        userAvailabilityStatuses,
        agora: agoraObj
    };

    if (!loaded)
        return <Loader />;

    return <Provider value={dictionaries}>{children}</Provider>;
};

export { Consumer as DictionariesConsumer };