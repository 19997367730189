import { RESET } from './../actions';
import { RECEIVE_ORGANIZATIONS, RECEIVE_ORGANIZATION_DETAILS } from './../actions/OrganizationActions';

/*
{
  "id": "497f6eca-6276-4993-bfeb-53cbbbba6f08",
  "name": "string",
  "teams": [
    {
      "id": "497f6eca-6276-4993-bfeb-53cbbbba6f08",
      "name": "string"
    }
  ]
}
*/
const initialState = [];

export default function (state = initialState, action) {
  switch (action.type) {
    case RESET:
      return initialState;
    case RECEIVE_ORGANIZATIONS:
      return [...action.payload];
    case RECEIVE_ORGANIZATION_DETAILS:
      let org = state.find(s => s.id === action.payload.id);

      if (org)
        return state.map(o => o.id !== action.payload.id ? o : action.payload);

      return [...state, action.payload];
    default:
      return state;
  }
}