import React, { useState, useEffect } from 'react';

import { Container, Card, CardBody, Row, Col } from 'reactstrap';

let _timerId;

function Blink({ children }) {
    const [delay, setDelay] = useState(1000);
    const [isVisible, visible] = useState(false);

    useEffect(() => {
        _timerId = setTimeout(() => {
            visible(!isVisible);
        }, delay);
    }, [isVisible]);

    useEffect(() => {
        return () => {
            if (_timerId)
                clearTimeout(_timerId);
        };
    }, []);

    return (
        <>
            {isVisible && children}
        </>
    );
}

export default Blink;
