import { Formik, Form, Field } from 'formik';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { Container, Row, Col, Card, CardBody, Label, Button, Input, Media } from 'reactstrap';

import UserManagement from '../../components/UserManagement';
import { Configuration } from '../../data/api/config';
import { postImageApi } from '../../data/api/files';
import { dataURLtoBlob } from '../../utils/UserHelper';
import { RFInput } from '../../components/common/RFInput';
import { updateTeam } from '../../data/store/actions/TeamActions';
import { RoutePaths } from '../../data/contants';
import { useHistory } from 'react-router';
import InviteTeammateModal from '../../components/Modals/InviteTeammateModal';

const selectTeamInfo = state => state.team;

function UserManagementPage() {
    const { teams, currentTeamId } = useSelector(selectTeamInfo);
    const [logoPreview, setLogo] = useState(null);
    const [isInviteOpen, toggleInviteModal] = useState(false);

    const dispatch = useDispatch();
    const history = useHistory();
    const { t } = useTranslation();

    const renderEditTeam = () => {
        const team = teams.find(t => t.id === currentTeamId) || {};

        return (
            <Formik
                initialValues={{
                    teamName: team.name || '',
                    logo: team.logo
                }}
                validationSchema={Yup.object({
                    teamName: Yup.string().required(),
                })}
                onSubmit={async (values, { setSubmitting }) => {
                    setSubmitting(true);

                    let logoLink = team.logo;

                    if (logoPreview) {
                        const resizedImage = dataURLtoBlob(logoPreview);
                        const { data } = await postImageApi(resizedImage);
                        logoLink = `${Configuration.basePath}/${data.link}`;
                    }

                    dispatch(updateTeam({
                        id: team.id,
                        logo: logoLink,
                        name: values.teamName
                    }));

                    setSubmitting(false);
                }}
            >
                {({ submitForm, isSubmitting, setFieldValue }) => (
                    <Form>
                        <div>
                            <Field
                                component={RFInput}
                                name='teamName'
                                type='text'
                                label={t('team_members_page.team_name_label')}
                                variant='outlined'
                            />
                        </div>
                        <div className="form-group">
                            <Label>{t("team_members_page.change_team_logo_label")}</Label>
                            <div className='pb-2'>
                                {(team.logo || logoPreview) &&
                                    <Media body src={logoPreview || team.logo} alt={team.name} />
                                }
                            </div>
                            <Row>
                                <Col className='col-auto'>
                                    <Button className='text-nowrap btn-block' color='primary' style={{ minWidth: 120 }} onClick={() => { document.getElementById('team_logo_file').click(); }}>{t('team_members_page.logo_from_file_btn')}</Button>
                                </Col>
                            </Row>
                            <Input id="team_logo_file" name="logo" type="file" style={{ visibility: 'hidden', width: 0, height: 0, display: 'block' }} onChange={async (event) => {
                                try {
                                    if (!event.currentTarget || !event.currentTarget.files[0])
                                        return;

                                    const filerdr = new FileReader();
                                    filerdr.onload = (e) => {
                                        const img = new Image();

                                        img.onload = async () => {
                                            try {
                                                var canvas = document.createElement('canvas');
                                                var ctx = canvas.getContext('2d');
                                                canvas.width = 90;
                                                canvas.height = 90;
                                                const iwidth = Math.min(90, img.width);
                                                const iheight = iwidth * (img.height / img.width);
                                                ctx.drawImage(img, canvas.width * 0.5 - iwidth * 0.5, canvas.height * 0.5 - iheight * 0.5, iwidth, iheight);

                                                const dataUrl = canvas.toDataURL('image/png');
                                                setLogo(dataUrl);
                                            } catch (e) { console.warn(e) }
                                        }
                                        img.src = e.target.result;
                                    }

                                    filerdr.readAsDataURL(event.currentTarget.files[0]);
                                } catch (e) { }
                            }} className="form-control" />
                        </div>
                        <div>
                            <Row>
                                <Col className='col-auto'>
                                    <Button
                                        variant='contained'
                                        color='success'
                                        disabled={isSubmitting}
                                        onClick={submitForm}
                                    >
                                        {t('common.btn.submit')}
                                    </Button>
                                </Col>
                                <Col className='col-auto'>
                                    <Button
                                        color='secondary'
                                        disabled={isSubmitting}
                                        onClick={() => { history.push(RoutePaths.Root) }}
                                    >
                                        {t('common.btn.gotoOffice')}
                                    </Button>
                                </Col>
                            </Row>
                        </div>
                    </Form>
                )}
            </Formik>
        );
    }

    return (
        <Container fluid>
            <div className="page-title">
                <h3>{t('team_members_page.header')}</h3>
            </div>
            <Row
                direction="column"
                justify="flex-start"
            >
                <Col xs={12}>
                    <Card>
                        <CardBody>
                            <Row>
                                <Col sm="6">
                                    {renderEditTeam()}
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
                <Col xs={12}>
                    <Card>
                        <CardBody>
                            <Row className="justify-content-end mb-4">
                                <Col className="col-auto">
                                    <Button color='primary' onClick={() => { toggleInviteModal(true); }}>
                                        {t('team_members_page.invite_teammate_btn')}
                                    </Button>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm="12">
                                    <UserManagement />
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <InviteTeammateModal isOpen={isInviteOpen} onClose={() => { toggleInviteModal(false); }} />
        </Container>
    );
}

export default UserManagementPage;