import axios from "axios";
import { Configuration } from './../config';

export function getOrCreateUserApi(token) {
    return axios.get(`${Configuration.basePath}/api/user`, token ? { headers: { Authorization: `Bearer ${token}` } } : null);
}

export function getStatusApi(statusId = null) {
    return axios.get(`${Configuration.basePath}/api/user/status`);
}

export function getStatusesApi() {
    return axios.get(`${Configuration.basePath}/api/user/dictionaries/availabilityStatuses`);
}

export function getRolesApi(teamId) {
    return axios.get(`${Configuration.basePath}/api/user/roles/${teamId}`);
}

/*
profileDetails:
    FirstName string Nullable
    LastName string Nullable
    MiddleName string Nullable
    NickName string Nullable
    Picture string Nullable
    Email string Nullable
*/
export function postProfileDetailsApi(profileDetails) {
    return axios.post(
        `${Configuration.basePath}/api/user/profile`,
        profileDetails
    );
}

/*
{
"id": "497f6eca-6276-4993-bfeb-53cbbbba6f08",
"availabilityStatus": 1,
"moodIcon": 0,
"moodText": "string",
"onYourMindText": "string"
}
*/
export function postStatusApi(statusObj) {
    return axios.post(`${Configuration.basePath}/api/user/status`, statusObj);
}