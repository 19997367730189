import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import get from 'lodash/get';

import { Button, FormGroup, Row, Col } from 'reactstrap';

import { postInvitationsApi } from './../../data/api/invite';
import { RFInput, RFCheckbox, RFMultiCheckbox } from './../common/RFInput';
import RFDatePicker from './../common/RFDatePicker';
import { getRooms } from './../../data/store/actions/TeamActions';
import { RoutePaths } from '../../data/contants';
import { useHistory } from 'react-router';

const selectRoleData = state => state.user.role;

function PublicSpaceInviteForm({ details, onSuccess }) {
    const dispatch = useDispatch();
    const history = useHistory();
    const { t } = useTranslation();

    const { teamId, teamName } = useSelector(selectRoleData);
    const { rooms } = useSelector(state => state.team);

    const roomOptions = rooms.map(r => ({ value: r.id, label: r.name }));

    useEffect(() => {
        (async () => {
            try {
                await dispatch(getRooms(teamId));
            } catch (e) { }
        })();
    }, [teamId]);

    return (
        <Formik
            enableReinitialize={true}
            initialValues={{
                team: teamName || "",
                email: "",
                roomsIds: get(details, 'publicRooms', []),
                link: get(details, 'link', ''),
                isMemberInvite: false,
                isPublic: true,
                showAvatars: true,
                showMembersDetails: true,
                roomChatEnabled: true,
                voiceCallEnabled: true,
                videoCallEnabled: true
            }}
            validationSchema={Yup.object({
                roomsIds: Yup.array().required(),
                link: Yup.string().required().min(4).matches(/^[a-z][a-z0-9_]+$/)
            })}
            onSubmit={async (values, { setSubmitting }) => {
                if (!teamId) {
                    console.warn('Empty teamId');
                    return;
                }

                setSubmitting(true);

                const { email, isMemberInvite, isPublic, showAvatars, showMembersDetails, roomChatEnabled, voiceCallEnabled, videoCallEnabled, roomsIds, link } = values;
                //const emails = email.split(',').map(e => e.trim());

                postInvitationsApi(
                    {
                        id: get(details, 'id'),
                        teamId: teamId,
                        //email: emails,
                        publicRooms: roomsIds,
                        link,
                        isMemberInvite: isMemberInvite,
                        isPublic: isPublic,
                        publicRoomSettings: {
                            showAvatars,
                            showMembersDetails,
                            roomChatEnabled,
                            voiceCallEnabled,
                            videoCallEnabled
                        }
                    })
                    .then((resp) => {
                        if (!onSuccess)
                            return;

                        onSuccess(resp.data);
                    })
                    .catch(console.warn);

                setSubmitting(false);
            }}
        >
            {({ submitForm, isSubmitting }) => (
                <Form>
                    {/* <FormGroup>
                        <Field
                            component={RFInput}
                            name='email'
                            type='text'
                            label={t('team_management_page.create_invite_form.label_email')}
                        />
                    </FormGroup> */}
                    <FormGroup>
                        <Field
                            component={RFInput}
                            name='link'
                            type='text'
                            label={t('team_management_page.create_invite_form.label_link')}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Field
                            component={RFMultiCheckbox}
                            id='checkbox_roomsIds'
                            name='roomsIds'
                            label={t('team_management_page.create_invite_form.label_roomsIds')}
                            options={roomOptions}
                        />
                    </FormGroup>
                    <FormGroup>
                        <h5>{t('team_management_page.create_invite_form.header_inviteSettings')}</h5>
                        <Field
                            component={RFCheckbox}
                            id='checkbox_showAvatars'
                            name='showAvatars'
                            type='checkbox'
                            label={t('team_management_page.create_invite_form.label_showAvatars')}
                        />
                        <Field
                            component={RFCheckbox}
                            id='checkbox_showMembersDetails'
                            name='showMembersDetails'
                            type='checkbox'
                            label={t('team_management_page.create_invite_form.label_showMembersDetails')}
                        />
                        <Field
                            component={RFCheckbox}
                            id='checkbox_roomChatEnabled'
                            name='roomChatEnabled'
                            type='checkbox'
                            label={t('team_management_page.create_invite_form.label_roomChatEnabled')}
                        />
                        <Field
                            component={RFCheckbox}
                            id='checkbox_voiceCallEnabled'
                            name='voiceCallEnabled'
                            type='checkbox'
                            label={t('team_management_page.create_invite_form.label_voiceCallEnabled')}
                        />
                        <Field
                            component={RFCheckbox}
                            id='checkbox_videoCallEnabled'
                            name='videoCallEnabled'
                            type='checkbox'
                            label={t('team_management_page.create_invite_form.label_videoCallEnabled')}
                        />
                    </FormGroup>
                    <div>
                        <Row>
                            <Col className='col-auto'>
                                <Button
                                    color='success'
                                    disabled={isSubmitting}
                                    onClick={submitForm}
                                >
                                    {t('common.btn.submit')}
                                </Button>
                            </Col>
                            <Col className='col-auto'>
                                <Button
                                    color='secondary'
                                    disabled={isSubmitting}
                                    onClick={() => { history.push(RoutePaths.Root) }}
                                >
                                    {t('common.btn.gotoOffice')}
                                </Button>
                            </Col>
                        </Row>
                    </div>
                </Form>
            )}
        </Formik>
    );
}

export default PublicSpaceInviteForm;
