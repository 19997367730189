import React, { useContext, useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import Masonry from 'react-masonry-css';
import { useResizeDetector } from 'react-resize-detector';

import { HubConnectorContext } from '../../data/context/HubConnector';
import { DictionariesContext } from '../../data/context/Dictionaries';
import { getRooms, getTeams, receiveCurrentTeam, getCurrentPositionInTeam, receiveRoomEnterRequestProcessed, receiveRoomInviteRequestProcessed } from './../../data/store/actions/TeamActions';

import { Form, FormGroup, Input, Row, Col, Modal, ModalHeader, ModalBody, ModalFooter, Button, Media } from 'reactstrap';

import Room from '../Room';
import CallArea from '../CallArea';
import { Configuration } from '../../data/api/config';
import { getRoles } from '../../data/store/actions/UserActions';
import useContextMenu from '../../hooks/useContextMenu';
import GuestRoomInviteModal from '../Modals/GuestRoomInviteModal';
import { setRoomChat, startPrivateChat } from '../../data/store/actions/ChatActions';
import RoomChat from '../Chat/RoomChat';
import { RoomMenuTypes } from '../../data/contants';
import GuestKnockModal from '../Modals/GuestKnockModal';
import KnockRequestModal from '../Modals/KnockRequestModal';
import PrivateChat from '../Chat/PrivateChat';
import { Sounds, play as PlaySound, stop as StopSound } from '../../utils/SoundHelper';
import { asyncRequestCompleted, asyncRequestStarted, AsyncRequestType, receiveMediaInfo } from '../../data/store/actions/AppActions';
import OnLoadModal from '../Modals/OnLoadModal';
import { callInProgressSelector, currentTeamIdSelector, inviteRequestsSelector, knockRequestsSelector, roomsSelector, userSelector, teamsSelector, roomCallSelector, myCurrentRoomSelector } from './selectors';
import { agoraJoinStateSelector } from '../../data/store/selectors/appSelectors';
import { roomChatIsOpenSelector } from '../../data/store/selectors/chatSelectors';
import { RoomMenu } from '../RoomMenu';

function TeamArea() {
    const dicCtx = useContext(DictionariesContext);
    const hubCtx = useContext(HubConnectorContext);

    const [currentCall, setCurrentCall] = useState({ roomId: '' });
    const [openGuestKnockModal, toggleGuestKnockModal] = useState(false);
    //const [chatIsOpen, toggleChat] = useState(false);

    const reqCallInProgress = useSelector(callInProgressSelector);
    const user = useSelector(userSelector);
    const rooms = useSelector(roomsSelector);
    const currentTeamId = useSelector(currentTeamIdSelector);
    const teams = useSelector(teamsSelector);
    const knockRequests = useSelector(knockRequestsSelector);
    const inviteRequests = useSelector(inviteRequestsSelector);
    const roomCall = useSelector(roomCallSelector);
    const room = useSelector(myCurrentRoomSelector);
    const roomChatIsOpen = useSelector(roomChatIsOpenSelector);

    const joinState = useSelector(agoraJoinStateSelector);

    const { teamId } = useParams();
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const onCallStarted = (call) => {
        window._agoraHelper.forceClearDelay();
        dispatch(asyncRequestStarted({ type: AsyncRequestType.initCall, data: call, complete: false }));

        const callId = `channel-room-${call.roomId}`;
        setCurrentCall({ callId, callType: call.callType, roomId: call.roomId });
        hubCtx.chat.startCall(call.callType, callId);//call.callId
    }

    const { leave, join, shareScreen, clearScreenTrack, startCameraTrack, setEncoder, setCallEndedHandler } = dicCtx.agora;

    useEffect(() => {
        (async () => {
            if (teamId) {
                await dispatch(receiveCurrentTeam(teamId));
            } else if (!currentTeamId) {
                const tId = await dispatch(getCurrentPositionInTeam());
                await dispatch(receiveCurrentTeam(tId));
            }

            await dispatch(getTeams());
        })();
    }, []);

    setCallEndedHandler(() => {
        hubCtx.chat.stopCall(currentCall.callId);
    });

    useEffect(() => {
        (async () => {
            if (!currentTeamId)
                return;

            await dispatch(getRoles(currentTeamId));

            if (rooms.length > 0 && rooms[0].team.id === currentTeamId) {
                await hubCtx.chat.connect();
                return;
            }

            const _rooms = await dispatch(getRooms(currentTeamId));
            const tId = await dispatch(getCurrentPositionInTeam());

            await hubCtx.chat.connect();

            if (!tId && user.isGuest) {
                toggleGuestKnockModal(true);
                return;
            }

            if (tId !== currentTeamId && _rooms) {
                dispatch(setRoomChat({ roomId: _rooms[0].id }));
                hubCtx.chat.enterRoom(_rooms[0].id);
            }
        })();
    }, [currentTeamId]);

    useEffect(() => {
        if (!roomCall || joinState || roomCall.count === 0)
            return;

        (async () => {
            window._agoraHelper.forceClearDelay();
            dispatch(asyncRequestStarted({ type: AsyncRequestType.initCall, data: { callId: roomCall.id }, complete: false }));

            if (roomCall.type.includes('video')) {
                await join({ mic: true, cam: true }, Configuration.agoraAppId, roomCall.id, null, user.id);
                dispatch(asyncRequestCompleted({ type: AsyncRequestType.initCall, complete: true }));
            } else {
                //dispatch(receiveMediaInfo({ cam: false }));
                await join({ mic: true }, Configuration.agoraAppId, roomCall.id, null, user.id);
                dispatch(asyncRequestCompleted({ type: AsyncRequestType.initCall, complete: true }));
            }

            setCurrentCall({ callId: roomCall.id, callType: roomCall.type, roomId: roomCall.roomId });
        })();
    }, [roomCall.isNew]);

    const gotoRoom = async (room) => {
        const inRoom = room.participants.find(u => u.id === user.id && u.online);

        if (!room.available || inRoom)
            return;

        //setLoadingState(true);

        if (joinState) {
            hubCtx.chat.stopCall(currentCall.callId);
            await leave();
        }

        dispatch(setRoomChat({ roomId: room.id }));
        hubCtx.chat.enterRoom(room.id);
        //setCurrentCall({ roomId: room.id });
    };

    const onGroupVoiceCallHandler = async (room) => {
        const inRoom = room.participants.find(u => u.id === user.id && u.online);

        if (!room.available || (inRoom && joinState))
            return;

        if (!inRoom) {
            if (joinState)
                hubCtx.chat.stopCall(currentCall.callId);
            dispatch(setRoomChat({ roomId: room.id }));
            hubCtx.chat.enterRoom(room.id);
        }

        await leave();
        //const uid = await join({ mic: true }, Configuration.agoraAppId, `channel-voice-room-${room.id}`, null, user.id);
        dispatch(receiveMediaInfo({ mic: true }));
        onCallStarted({ callId: user.id, callType: 'video', roomId: room.id, audio: true, video: true });
    };

    const onGroupVideoCallHandler = async (room) => {
        const inRoom = room.participants.find(u => u.id === user.id && u.online);

        if (!room.available || (inRoom && joinState))
            return;

        if (!inRoom) {
            if (joinState)
                hubCtx.chat.stopCall(currentCall.callId);
            dispatch(setRoomChat({ roomId: room.id }));
            hubCtx.chat.enterRoom(room.id);
        }

        await leave();
        //const uid = await join({ cam: true, mic: true }, Configuration.agoraAppId, `channel-cam-room-${room.id}`, null, user.id);
        onCallStarted({ callId: user.id, callType: 'video', roomId: room.id, audio: true, video: true });
    };

    const onLeaveHandler = async () => {
        await leave();
        hubCtx.chat.stopCall(currentCall.callId);
    };

    const onScreenShareHandler = async () => {
        shareScreen();
    }

    const renderKnockRequest = () => {
        var req = knockRequests[0];

        if (req) {
            PlaySound(Sounds.snd_door_knock, true, 0.5);
            return <KnockRequestModal request={req} onComplete={(req) => dispatch(receiveRoomEnterRequestProcessed(req))} />
        }

        StopSound(Sounds.snd_door_knock);
    }

    const renderInviteRequests = () => {
        var req = inviteRequests[0];

        if (req)
            return <Modal isOpen={true}>
                <ModalBody>
                    <div className='text-center'>
                        <div>
                            <Media body className='rounded-circle' style={{ width: 50, height: 50 }} src={req.callerUserPic} alt={req.callerUserName} />
                        </div>
                        <span style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>{req.callerUserName}</span>
                    </div>
                    <div className='mt-3'>
                        <div className='text-center'>
                            <h5>{t('invite_response_modal.asking_to', { room: req.roomName })}</h5>
                        </div>
                        <div className='text-center'>
                            {req.message}
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter className='justify-content-around'>
                    <Row>
                        <Col>
                            <Button className='text-nowrap btn-block mb-3' color='success' autoFocus onClick={() => { dispatch(receiveRoomInviteRequestProcessed(req)); hubCtx.chat.roomInviteAnswer(req.roomId, req.callerUserId, "", true); }}>{t('invite_response_modal.accept_btn', { room: req.roomName })}</Button>
                        </Col>
                        <Col>
                            <Button className='text-nowrap btn-block mb-3' color='primary' onClick={() => { dispatch(receiveRoomInviteRequestProcessed(req)); hubCtx.chat.roomInviteAnswer(req.roomId, req.callerUserId, t('invite_response_modal.later_msg'), false); }}>{t('invite_response_modal.later_btn')}</Button>
                        </Col>
                        <Col>
                            <Button className='text-nowrap btn-block' color='secondary' onClick={() => { dispatch(receiveRoomInviteRequestProcessed(req)); hubCtx.chat.roomInviteAnswer(req.roomId, req.callerUserId, t('invite_response_modal.reject_msg'), false); }}>{t('invite_response_modal.reject_btn')}</Button>
                        </Col>
                    </Row>
                </ModalFooter>
            </Modal>
    }

    const renderContextMenuAction = () => {
        if (!inviteTeammateModal.open)
            return;

        let { userId, roomId } = cmData;

        if (inviteTeammateModal.type === RoomMenuTypes.GUEST_ROOM_INVITE) {
            if (inviteTeammateModal.data)
                roomId = inviteTeammateModal.data.roomId;

            const room = rooms.find(r => r.id === roomId);

            return (
                <GuestRoomInviteModal
                    room={{ id: room.id, name: room.name }}
                    onClose={onContextMenuClose}
                />
            )
        }

        if (inviteTeammateModal.type === RoomMenuTypes.MEMBER_ROOM_INVITE) {
            let targetUser = null;

            if (roomId) {
                const room = rooms.find(r => r.id === roomId);
                targetUser = room && room.participants.find(p => p.online && p.id === userId);
            }

            return (
                <Modal isOpen={true} toggle={onContextMenuClose}>
                    <ModalHeader toggle={onContextMenuClose}>{t('member_room_invite_modal.header', { user: targetUser.name })}</ModalHeader>
                    <ModalBody>
                        <Form onSubmit={onContextMenuSuccess}>
                            <FormGroup>
                                {/* <Label for="inputInviteMsg">Message</Label> */}
                                <Input type="textarea" name="form-control" id="inputInviteMsg" placeholder={t('member_room_invite_modal.message_placeholder')} />
                            </FormGroup>
                            <FormGroup className="justify-content-around">
                                <Button color='success' type='submit' autoFocus className='mr-3'>{t('member_room_invite_modal.btn.ok')}</Button>
                                <Button color='secondary' onClick={onContextMenuClose}>{t('common.btn.cancel')}</Button>
                            </FormGroup>
                        </Form>
                    </ModalBody>
                </Modal>
            );
        }

        return;
    }

    const [inviteTeammateModal, toggleInviteTeammateModal] = useState({ open: false });

    const onOpen = (e) => {
        const userId = e.target.getAttribute("data-user");
        const roomId = e.target.getAttribute("data-roomid");

        if (!userId && !roomId)
            return { success: false };

        return { success: true, data: { userId: userId === user.id ? null : userId, roomId } };
    }

    const onMenuClick = ({type, data}) => {
        const { userId } = cmData;
        if (userId && type === RoomMenuTypes.PRIVATE_CHAT) {
            dispatch(startPrivateChat(userId));
            return;
        }

        toggleInviteTeammateModal({ open: true, type, data });
    }

    const onContextMenuSuccess = (e) => {
        hubCtx.chat.inviteTeammate(cmData.userId, e.target[0].value);
        toggleInviteTeammateModal({ open: false });
    }

    const onContextMenuClose = () => {
        toggleInviteTeammateModal({ open: false });
    }

    const startCameraTrackHandler = () => { dispatch(receiveMediaInfo({ cam: true })); };
    const stopSharescreenHandler = () => { clearScreenTrack(); startCameraTrack(); };
    const onGuestKnockModalClose = () => { toggleGuestKnockModal(false); };

    const onResize = useCallback((width) => {
        let x = 4;

        if (width <= 500)
            x = 1;
        else if (width <= 650)
            x = 2;
        else if (width <= 800)
            x = 3;
        else
            x = Math.round(width / 250);

        setMasonryBreakpointCount(Math.min(x, 6));
    }, []);
    const [masonryBreakpointCount, setMasonryBreakpointCount] = useState(4);
    const { ref: rd_ref } = useResizeDetector({
        handleHeight: false,
        refreshMode: 'debounce',
        refreshRate: 50,
        onResize
    });
    const { xPos, yPos, showMenu, cmData } = useContextMenu(rd_ref, onOpen);
    //const room = (rooms || []).find(r => r.participants.filter(u => u && u.online).find(u => u.id === user.id));
    const wrapperStyle = { width: `calc(100% - ${roomChatIsOpen ? 330 : 0}px)` };
    const roomChatWrapperStyle = { width: roomChatIsOpen ? 360 : 0 };
    const privateChatStyle = { right: 374 - (roomChatIsOpen ? 0 : 370) };

    return (
        <>
            <div style={wrapperStyle}>
                <Row>
                    <Col>
                        {/* <Breadcrumb title="Team" /> */}
                        {/* {(teams && teams.length > 1)
                            ? <div className="page-title">
                                <Row>
                                    <Col>
                                        <h2>{getTeamName()}</h2>
                                    </Col>
                                    <Col xs={3}>
                                        <TeamPicker teams={teams} />
                                    </Col>
                                </Row>
                            </div>
                            : <div className='mb-4' />
                        } */}
                        <div className='mb-4' />
                        {joinState &&
                            <Row>
                                <Col xs={12}>
                                    <CallArea
                                        callData={{ isVideoCall: room && room.call ? !room.call.type.includes('video') : null }}
                                        user={user}
                                        onScreenShareHandler={onScreenShareHandler}
                                        stopSharescreen={stopSharescreenHandler}
                                        onLeave={onLeaveHandler}
                                        setAgoraClientEncoder={setEncoder}
                                        startCameraTrack={startCameraTrackHandler}
                                    />
                                </Col>
                            </Row>
                        }
                        <div ref={rd_ref}>
                            <Masonry
                                breakpointCols={masonryBreakpointCount}
                                className="rooms-grid"
                                columnClassName="rooms-grid-column"
                            >
                                {rooms && rooms.map(r =>
                                    <Room key={`room-id-${r.id}`}
                                        room={r}
                                        onGroupVoiceCallHandler={onGroupVoiceCallHandler}
                                        onGroupVideoCallHandler={onGroupVideoCallHandler}
                                        selectRoomHandler={gotoRoom}
                                        onLeave={onLeaveHandler}
                                        onMenuClick={onMenuClick}
                                        callInProgress={joinState && r.id === room?.id}
                                    />
                                )}
                            </Masonry>
                        </div>
                        {showMenu && <RoomMenu xPos={xPos} yPos={yPos} roomId={cmData.roomId} userId={cmData.userId} onMenuClick={onMenuClick} />}
                        {renderKnockRequest()}
                        {renderInviteRequests()}
                        {renderContextMenuAction()}
                        {openGuestKnockModal && <GuestKnockModal room={rooms ? rooms.find(r => r.available) : null} onClose={onGuestKnockModalClose} />}
                    </Col>
                </Row>
            </div>
            <div style={roomChatWrapperStyle}>
                <RoomChat />
            </div>
            <PrivateChat contStyle={privateChatStyle} />
            <OnLoadModal isOpen={reqCallInProgress && !reqCallInProgress.complete} />
        </>
    )
}

export default TeamArea;