import React from 'react';
import { useDispatch } from 'react-redux';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { Trans } from 'react-i18next'
import { useTranslation } from 'react-i18next';

import { Button } from 'reactstrap';

import { receiveOrganizationDetails } from "../../../data/store/actions/OrganizationActions";
import { postProfileDetails } from "../../../data/store/actions/UserActions";
import { getRooms, receiveCurrentTeam } from '../../../data/store/actions/TeamActions';
import { RFCheckbox, RFInput } from '../../common/RFInput';
import { Configuration } from '../../../data/api/config';
import { getOrCreateUserApi } from '../../../data/api/user';
import { postOrganizationApi } from '../../../data/api/organization';

function CreateOrganizationForm({ userInfo }) {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    return (
        <Formik
            initialValues={{
                name: "",
                firstName: userInfo.firstName || '',
                lastName: userInfo.lastName || '',
                tos: false
            }}
            validationSchema={Yup.object({
                name: Yup.string().required(),
                //defaultTeamName: Yup.string().required(),
                tos: Yup.bool().required().oneOf([true])
            })}
            onSubmit={async (values, { setSubmitting }) => {
                setSubmitting(true);

                values.defaultTeamName = values.name;

                const { data: org } = await postOrganizationApi(values);
                const { data: user } = await getOrCreateUserApi();

                if (user.defaultTeam) {
                    dispatch(receiveCurrentTeam(user.defaultTeam.id));
                    dispatch(getRooms(user.defaultTeam.id));
                }

                setSubmitting(false);

                dispatch(receiveOrganizationDetails(org));
                dispatch(postProfileDetails({
                    ...user,
                    firstName: values.firstName,
                    lastName: values.lastName
                }));
            }}
        >
            {({ submitForm, isSubmitting }) => (
                <Form>
                    <div margin={1}>
                        <Field
                            component={RFInput}
                            name='name'
                            type='text'
                            label={t('create_organization_page.label_name')}
                            variant='outlined'
                        />
                    </div>
                    <div margin={1}>
                        <Field
                            component={RFInput}
                            name='firstName'
                            type='text'
                            label={t('common.form.first_name_label')}
                            variant='outlined'
                        />
                    </div>
                    <div margin={1}>
                        <Field
                            component={RFInput}
                            name='lastName'
                            type='text'
                            label={t('common.form.last_name_label')}
                            variant='outlined'
                        />
                    </div>
                    <div>
                        <Field
                            component={RFCheckbox}
                            id='checkbox_tos'
                            name='tos'
                            type='checkbox'
                            label={
                                <Trans i18nKey="create_organization_page.tos">
                                    Accept <a href={Configuration.tos} target="_blank">Terms of Service</a> and <a href={Configuration.policy} target="_blank">Privacy Policy</a>
                                </Trans>
                            }
                        />
                    </div>
                    <div margin={1}>
                        <Button
                            variant='contained'
                            color='primary'
                            disabled={isSubmitting}
                            onClick={submitForm}
                        >
                            {t('create_organization_page.btn.create')}
                        </Button>
                    </div>
                </Form>
            )}
        </Formik>
    );
}

export default CreateOrganizationForm;
