import React, { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { roomsSelector, userSelector } from '../TeamArea/selectors';
import { useTranslation } from 'react-i18next';
import { PermissionEnum, RoomMenuTypes } from '../../data/contants';
import { hasPermissions } from '../../utils/UserHelper';
import { HubConnectorContext } from '../../data/context/HubConnector';
import { setRoomChat, toggleRoomChat } from '../../data/store/actions/ChatActions';
export const RoomMenu = ({ xPos, yPos, roomId, onMenuClick, userId = null }) => {
    const rooms = useSelector(roomsSelector);
    const user = useSelector(userSelector);
    const { t } = useTranslation();
    const { chat } = useContext(HubConnectorContext);
    const dispatch = useDispatch();

    const getMenu = () => {
        let targetUserInRoom = false;
        let roomName = '';
        let room;
        if (roomId) {
            room = rooms.find(r => r.id === roomId);
            targetUserInRoom = room && room.participants.find(p => p.online && p.id === user.id);
            roomName = room?.name;
        }

        const items = [];
        if (!targetUserInRoom && userId && hasPermissions(user.role.permissions, [PermissionEnum.SendInviteToRoom]))
            items.push(<a key='room-menu-room-invite' className="dropdown-item" href={null} role="button" onClick={() => onMenuClick({ type: RoomMenuTypes.MEMBER_ROOM_INVITE })}>{t('context_menu.invite')}</a>);
        if ((userId && userId !== user.id))
            items.push(<a key='room-menu-private-chat' className="dropdown-item" href={null} role="button" onClick={() => onMenuClick({ type: RoomMenuTypes.PRIVATE_CHAT })}>{t('context_menu.private_chat')}</a>);
        if (roomId && hasPermissions(user.role.permissions, [PermissionEnum.SendInviteToRoom]))
            items.push(<a key='room-menu-public-room-invite' className="dropdown-item" href={null} role="button" style={{ whiteSpace: 'break-spaces' }} onClick={() => onMenuClick({ type: RoomMenuTypes.GUEST_ROOM_INVITE, data: { roomId: room.id } })}>{t('context_menu.public_link', { room: roomName })}</a>);
        if (targetUserInRoom && room.available)
            items.push(
                <a
                    key='room-menu-open-close-door'
                    className='dropdown-item'
                    style={{ whiteSpace: 'break-spaces' }}
                    href={null}
                    role="button"
                    onClick={
                        (e) => {
                            e.stopPropagation();
                            room.doorIsOpen ? chat.closeRoom() : chat.openRoom();
                        }}
                >
                    {room.doorIsOpen
                        ? t('context_menu.door_close')
                        : t('context_menu.door_open')
                    }
                </a>
            );
        if (!targetUserInRoom && room.available)
            items.push(<a
                key='room-menu-knock-door'
                className='dropdown-item'
                style={{ whiteSpace: 'break-spaces' }}
                href={null}
                role="button"
                onClick={
                    (e) => {
                        e.stopPropagation();
                        chat.knockToRoom(room.id, "");
                    }}
            >
                {t('context_menu.door_knock')}
            </a>);
        if (room.available)
            items.push(<a
                key='room-menu-open-room-chat'
                className='dropdown-item'
                style={{ whiteSpace: 'break-spaces' }}
                href={null}
                role="button"
                onClick={() => {
                    dispatch(setRoomChat({ roomId: room.id }));
                    dispatch(toggleRoomChat({ isOpen: true }));
                }}
            >
                {t('context_menu.room_chat')}
            </a>);
        return items;
    }
    const menu = getMenu();
    return (
        <>
            {menu.length > 0 && (
                <div className="dropdown-menu dropdown-menu-sm" id="user-list-context-menu" style={{ top: yPos, left: xPos, position: "fixed", display: "block", width: 180 }}>
                    {menu}
                </div>
            )}
        </>
    );
}