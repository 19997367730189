import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AgoraRTC from 'agora-rtc-sdk-ng';
import { DEFAULT_MIC_LEVEL, DEFAULT_SOUND_LEVEL, ModalType, receiveAgoraCallState, receiveAgoraDevices, receiveAgoraTracks, toggleModal } from '../data/store/actions/AppActions';
import { agoraDevicesSelector, agoraJoinStateSelector, agoraRemoteUsersSelector, agoraTracksSelector, appSettingsSelector } from '../data/store/selectors/appSelectors';

let onCallEnded = null;

//use deviceLabel because deviceId can be changed
export const checkDevice = async (deviceLabel) => {
  const devices = await AgoraRTC.getDevices(true);
  return devices.find(d => d.label === deviceLabel);
}

export default function useAgora(client) {
  const dispatch = useDispatch();

  const appSettings = useSelector(appSettingsSelector);
  const tracks = useSelector(agoraTracksSelector);
  const devices = useSelector(agoraDevicesSelector);
  const joinState = useSelector(agoraJoinStateSelector);
  const remoteUsers = useSelector(agoraRemoteUsersSelector);

  useEffect(() => {
    if (!joinState)
      return;

    if (tracks.audio)
      tracks.audio.setEnabled(appSettings.mic);

    if (!tracks.audio && appSettings.mic)
      startMicTrack();

    if (tracks.video)
      tracks.video.setEnabled(appSettings.cam);

    if (!tracks.video && !tracks.screen && appSettings.cam) {
      startCameraTrack();
    }

    remoteUsers.forEach(u => {
      u.audioTrack && u.audioTrack.setVolume(appSettings.audio ? DEFAULT_SOUND_LEVEL : 0);
    });
  }, [appSettings]);

  async function createLocalCameraTrack() {
    try {
      const track = await AgoraRTC.createCameraVideoTrack({
        encoderConfig: '240p',//720p_1
      });

      if (devices.videoDeviceId) {
        const hasDevice = await checkDevice(devices.video);

        if (hasDevice)
          track.setDevice(devices.videoDeviceId);
        else
          dispatch(receiveAgoraDevices({ video: null, videoDeviceId: null }));
      }

      return track;
    } catch (e) {
      console.warn("Error while creating tracks", e);
      return null;
    }
  }

  async function createLocalAudioTrack() {
    try {
      const microphoneTrack = await AgoraRTC.createMicrophoneAudioTrack();

      if (devices.audioDeviceId) {
        const hasDevice = await checkDevice(devices.audio);

        if (hasDevice)
          microphoneTrack.setDevice(devices.audioDeviceId);
        else
          dispatch(receiveAgoraDevices({ audio: null, audioDeviceId: null }));
      }

      microphoneTrack.setVolume(DEFAULT_MIC_LEVEL);
      microphoneTrack.setEnabled(appSettings.mic);

      return microphoneTrack;
    } catch (e) {
      return null;
    }
  }

  async function createLocalTracks(settings, audioConfig, videoConfig) {
    try {
      if (appSettings.cam && ((settings.cam && settings.mic) || settings.cam)) {
        const cameraTrack = await createLocalCameraTrack();
        const microphoneTrack = appSettings.mic ? await createLocalAudioTrack() : null;

        return [microphoneTrack, cameraTrack];
      } else if (settings.mic) {
        const microphoneTrack = appSettings.mic ? await createLocalAudioTrack() : null;

        return [microphoneTrack, null];
      }
    } catch (e) {
      console.warn("Error while creating tracks", e);
    }

    return [];
  }

  async function shareScreen() {
    try {
      clearVideoTrack();
      clearScreenTrack();

      const track = await AgoraRTC.createScreenVideoTrack({
        encoderConfig: {
          width: 1920,
          height: 1080,
          frameRate: { ideal: 5, max: 5 },
          bitrateMin: 832,
          bitrateMax: 2080,
          // bitrateMin: 1000,
          // bitrateMax: 2000,
        },
        optimizationMode: 'detail',
      });

      track.on("track-ended", async () => {
        track.removeAllListeners();
        await track.stop();
        await track.close();
        await client.unpublish(track);

        dispatch(receiveAgoraTracks({ screen: null }));

        if (joinState)
          startCameraTrack();
      });

      dispatch(receiveAgoraTracks({ screen: track }));

      await client.publish(track);
    }
    catch (e) {
      console.warn("Error while creating share screen track", e);
    }
  }

  async function join(settings, appid, channel, token, uid) {
    if (!client) return;

    const [microphoneTrack, cameraTrack] = await createLocalTracks(settings);
    const callUid = await client.join(appid, channel, token, uid);

    client.setLowStreamParameter({
      width: 240,
      height: 180,
      framerate: 15,
      bitrate: 120
    });

    //client.enableDualStream().catch(e => { });

    try {
      if (microphoneTrack) {
        await client.publish(microphoneTrack);
        dispatch(receiveAgoraTracks({ audio: microphoneTrack }));
      }
    } catch (e) {
      microphoneTrack.stop();
      microphoneTrack.close();
    }

    try {
      if (cameraTrack) {
        await client.publish(cameraTrack);
        dispatch(receiveAgoraTracks({ video: cameraTrack }));
      }
    } catch (e) {
      cameraTrack.stop();
      cameraTrack.close();
    }

    dispatch(receiveAgoraCallState({ joinState: true }));

    return callUid;
  }

  async function startCameraTrack() {
    if (!appSettings.cam)
      return;

    let cameraTrack = null;

    try {
      cameraTrack = await createLocalCameraTrack();
      await client.publish(cameraTrack);
      dispatch(receiveAgoraTracks({ video: cameraTrack }));
    } catch (e) {
      console.warn("Error while creating video track", e);
      if (cameraTrack) {
        try {
          cameraTrack.stop();
          cameraTrack.close();
        } catch (_) { };
      }
    }
  }

  async function startMicTrack() {
    if (!appSettings.mic)
      return;

    let audioTrack = null;

    try {
      audioTrack = await createLocalAudioTrack();
      await client.publish(audioTrack);

      dispatch(receiveAgoraTracks({ audio: audioTrack }));
    } catch (e) {
      console.warn("Error while creating video track", e);
      if (audioTrack) {
        try {
          audioTrack.stop();
          audioTrack.close();
        } catch (_) { };
      }
    }
  }

  async function clearScreenTrack() {
    if (tracks.screen) {
      try {
        tracks.screen.removeAllListeners();
        tracks.screen.stop();
        tracks.screen.close();
        await client.unpublish(tracks.screen);
      } catch (e) { }

      dispatch(receiveAgoraTracks({ screen: null }));
    }
  }

  async function clearAudioTrack() {
    if (tracks.audio) {
      try {
        tracks.audio.stop();
        tracks.audio.close();
        await client.unpublish(tracks.audio);
      } catch (e) { }

      dispatch(receiveAgoraTracks({ audio: null }));
    }
  }

  async function clearVideoTrack() {
    if (tracks.video) {
      try {
        tracks.video.stop();
        tracks.video.close();
        await client.unpublish(tracks.video);
      } catch (e) { }

      dispatch(receiveAgoraTracks({ video: null }));
    }
  }

  useEffect(() => {
    if (remoteUsers.length < 1 && joinState)
      leave();
  }, [remoteUsers.length]);

  const setCallEndedHandler = (handler) => { onCallEnded = handler; };

  async function leave() {
    if (!joinState)
      return;

    dispatch(receiveAgoraCallState({ joinState: false, remoteUsers: [] }));
    dispatch(toggleModal({ type: ModalType.devices, isOpen: false }));

    onCallEnded && onCallEnded();
    await clear();
  }

  async function clear() {
    try {
      await clearScreenTrack();
      await clearAudioTrack();
      await clearVideoTrack();
    } catch (_) { }

    try {
      await client.unpublish();
    } catch (_) { }

    try {
      await client.leave();
    } catch (_) { }
  }

  //streamType - 0 hight 1 low
  async function setEncoder(uid, streamType) {
    try {
      //await client.setStreamFallbackOption(uid, 0);
      //await client.setRemoteVideoStreamType(uid, streamType);
    } catch (e) { console.warn(e) };
  }

  // const latestProps = useRef(null);
  // latestProps.current = {
  //   joinState,
  //   tracks,
  // };
  const onMicrophoneChanged = async (changedDevice) => {
    if (!tracks.audio)
      return;

    if (changedDevice.state === "ACTIVE") {
      tracks.audio.setDevice(changedDevice.device.deviceId);
    } else if (changedDevice.device.label === tracks.audio.getTrackLabel()) {
      const oldMicrophones = await AgoraRTC.getMicrophones(true);
      oldMicrophones[0] && tracks.audio.setDevice(oldMicrophones[0].deviceId);
    }
  }

  const onPlaybackDeviceChanged = async (info) => {
    console.log('playback changed:', info.state, info.device);
    let deviceId = null;
    let label = null;

    if (info.state === "ACTIVE") {
      deviceId = info.device.deviceId;
      label = info.device.label;
    } else {
      const ouputs = await AgoraRTC.getPlaybackDevices(true);
      if (ouputs[0]) {
        deviceId = ouputs[0].deviceId;
        label = ouputs[0].label;
      }
    }

    if (!deviceId) return;
    dispatch(receiveAgoraDevices({ output: label, outputDeviceId: deviceId }));
  }

  useEffect(() => {
    AgoraRTC.onMicrophoneChanged = onMicrophoneChanged;
    AgoraRTC.onPlaybackDeviceChanged = onPlaybackDeviceChanged;
  }, [tracks]);

  useEffect(() => {
    if (!client) return;

    if (window.Notification && Notification.permission !== "denied") {
      Notification.requestPermission();
    }

    AgoraRTC.getDevices()
      .then((devices) => {
        console.log('DEVICES:', devices);

        const out = devices.filter(d => d.kind === "audiooutput")[0];
        const audio = devices.filter(d => d.kind === "audioinput")[0];
        const video = devices.filter(d => d.kind === "videoinput")[0];

        dispatch(receiveAgoraDevices({
          audio: audio ? audio.label : null,
          audioDeviceId: audio ? audio.deviceId : null,
          video: video ? video.label : null,
          videoDeviceId: video ? video.deviceId : null,
          output: out ? out.label : null,
          outputDeviceId: out ? out.deviceId : null,
        }));
      }).catch(() => { });

    //var id = devices[0].deviceId;
    //AgoraRTC.onMicrophoneChanged = onMicrophoneChanged;

    dispatch(receiveAgoraCallState({ remoteUsers: client.remoteUsers }));

    const handleUserPublished = async (user, mediaType) => {
      await client.subscribe(user, mediaType);
      //await setEncoder(user.uid, 1);

      dispatch(receiveAgoraCallState({ remoteUsers: Array.from(client.remoteUsers) }));
    }
    const handleUserUnpublished = (user) => {
      dispatch(receiveAgoraCallState({ remoteUsers: Array.from(client.remoteUsers) }));
    }
    const handleUserJoined = (user, mediaType) => {
      dispatch(receiveAgoraCallState({ remoteUsers: Array.from(client.remoteUsers) }));
    }
    const handleUserLeft = (user) => {
      dispatch(receiveAgoraCallState({ remoteUsers: Array.from(client.remoteUsers) }));
    }
    const handleNetworkQuality = (stats) => {
      console.log('stats=', stats);
    }
    const handleStreamFallback = (uid, isFallbackOrRecover) => {
      console.log(`stream fallback ${uid} ${isFallbackOrRecover}`);
    }
    const handleStreamTypeChanged = (uid, type) => {
      console.log(`stream type changed ${uid} ${type}`);
    }

    client.on('user-published', handleUserPublished);
    client.on('user-unpublished', handleUserUnpublished);
    client.on('user-joined', handleUserJoined);
    client.on('user-left', handleUserLeft);
    //client.on('network-quality', handleNetworkQuality);
    client.on('stream-fallback', handleStreamFallback);
    client.on('stream-type-changed', handleStreamTypeChanged);

    return () => {
      client.off('user-published', handleUserPublished);
      client.off('user-unpublished', handleUserUnpublished);
      client.off('user-joined', handleUserJoined);
      client.off('user-left', handleUserLeft);
      client.off('stream-fallback', handleStreamFallback);
      client.off('stream-type-changed', handleStreamTypeChanged);
    };
  }, [client]);

  return {
    leave,
    join,
    shareScreen,
    clearScreenTrack,
    startCameraTrack,
    setEncoder,
    setCallEndedHandler,
    clear,
  };
}