import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import DataTable from 'react-data-table-component';
import { Media } from 'reactstrap';

import { getCurrentPositionInTeam, receiveCurrentTeam } from './../../data/store/actions/TeamActions';
import { getTeamMembersApi, removeTeamMemberApi } from './../../data/api/team';

function UserManagement() {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const { currentTeamId } = useSelector(state => state.team);
    const userId = useSelector(state => state.user.id);
    const [data, setData] = useState([]);

    const tableColumns = [
        {
            name: t('user_management.table.row_user'),
            selector: 'name',
            sortable: true,
            cell: row => (
                <div className="d-flex align-items-baseline pt-3 pb-3">
                    <div className="mr-3">
                        <Media body className="rounded-circle" style={{ width: 50, height: 50 }} src={row.picture} alt={row.name} />
                    </div>
                    <span className="mr-3" style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>{row.name}</span>
                    {row.isTeamAdmin && <span className="badge badge-danger">{t('common.badge.admin')}</span>}
                    {row.isGuest && <span className="badge badge-primary">{t('common.badge.guest')}</span>}
                    {userId === row.id && <span className="badge badge-success">{t('common.badge.me')}</span>}
                </div>
            ),
        },
        {
            name: t('user_management.table.row_email'),
            selector: 'email',
            wrap: true,
        },
        {
            name: t('common.table.row_actions'),
            center: true,
            //grow: 0,
            button: true,
            cell: row => <div>{userId !== row.id && <a href={null} role='button' onClick={() => removeUser(row)}>{t('common.btn.delete')}</a>}</div>,
        },
    ];

    const removeUser = async (user) => {
        try {
            await removeTeamMemberApi(currentTeamId, user.id);
            setData(data.filter(d => d.id !== user.id));
        } catch (e) { console.log(e) }
    }

    useEffect(() => {
        (async () => {
            try {
                const teamId = await dispatch(getCurrentPositionInTeam());

                if (teamId)
                    await dispatch(receiveCurrentTeam(teamId));
            } catch (e) { }
        })();
    }, []);

    useEffect(() => {
        (async () => {
            if (!currentTeamId)
                return;

            try {
                var { data } = await getTeamMembersApi(currentTeamId);

                setData(data);
            } catch (e) { }
        })();
    }, [currentTeamId]);

    return (
        <div>
            <DataTable
                data={data}
                columns={tableColumns}
                striped={true}
                center={true}
                pagination={data && data.length > 10}
                highlightOnHover
                noHeader
            />
        </div>
    );
}

export default UserManagement;
