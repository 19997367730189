import { PermissionEnum } from "../data/contants";

export const hasPermissions = (permissions, toCheck) => {
    if (!permissions || permissions.length === 0)
        return false;

    return toCheck.every(x => permissions.find(p => PermissionEnum[p] === x));
}

export const getUserName = (user) => {
    const name = [user.firstName, user.lastName].join(' ').trim();

    return name.length < 2 ? user.email : name;
}

export const dataURLtoBlob = (dataurl) => {
    var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
}