export const AgoraHelper = (agora) => {
    let _agora = agora;
    let timeout;
    return {
        setAgora: (a) => _agora = a,
        forceClearDelay: () => {
            if (timeout) {
                clearTimeout(timeout);
                timeout = null;
            }

            timeout = setTimeout(() => {
                if (_agora) _agora.clear();
            }, 1000 * 60 * 60 * 2);
        },
        clear: () => _agora.clear(),
    };
}