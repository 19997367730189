import React, { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';

import { DictionariesContext } from '../../data/context/Dictionaries';
import { getUserStatus, postUserStatus } from "../../data/store/actions/UserActions";
import { userStatusSelector } from '../../data/store/selectors/userSelectors';

function StatusPicker() {
    const ctx = useContext(DictionariesContext);
    const dispatch = useDispatch();
    const status = useSelector(userStatusSelector);
    const { t } = useTranslation();

    useEffect(() => {
        dispatch(getUserStatus());
    }, []);

    const handleChange = (selectedOption) => {
        dispatch(postUserStatus({ availabilityStatus: selectedOption.value }));
    };

    let statuses = ctx.userAvailabilityStatuses || [];
    const options = statuses.map(s => ({ value: s.statusCode, label: t(`status_dictionary.select_statusCode_${s.statusCode}`) }));

    return (
        <Select
            value={options.find(t => t.value === status.statusCode)}
            onChange={handleChange}
            options={options}
        />
    );
}

export default StatusPicker;
