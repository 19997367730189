import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import { Button, Card, CardHeader, CardBody, CardFooter, Row, Col } from 'reactstrap';
import { MessageSquare } from 'react-feather';

import { HubConnectorContext } from '../../data/context/HubConnector';
import UsersList from '../UsersList';
import { userSelector } from '../../data/store/selectors/userSelectors';
import { chatRoomsSelector, roomChatIsOpenSelector } from '../../data/store/selectors/chatSelectors';

//SVG 
import LobbySvg from './../../assets/rooms/lobby.svg';
import KitchenSvg from './../../assets/rooms/kitchen.svg';
import DevSvg from './../../assets/rooms/dev.svg';
import ExecutivesSvg from './../../assets/rooms/executives.svg';
import GuestSvg from './../../assets/rooms/guest.svg';
import ManagementSvg from './../../assets/rooms/management.svg';
import MarketingSvg from './../../assets/rooms/marketing.svg';
import MeetingSvg from './../../assets/rooms/meeting.svg';
import SalesSvg from './../../assets/rooms/sales.svg';
import SupportSvg from './../../assets/rooms/support.svg';
import TeamSvg from './../../assets/rooms/team.svg';
import JungleSvg from './../../assets/rooms/garden.svg';
import MountEverestSvg from './../../assets/rooms/mount_everest.svg';
import OceanBeachSvg from './../../assets/rooms/ocean_beach.svg';
import SailboatSvg from './../../assets/rooms/sailboat.svg';
import GardenSvg from './../../assets/rooms/garden_.svg';

import openDoorIco from './../../assets/rooms/door_open.png';
import closeDoorIco from './../../assets/rooms/door_close.png';
import roomCallIco from './../../assets/rooms/call.png';
import roomChatIco from './../../assets/rooms/chat.png';
import { setRoomChat, startPrivateChat, toggleRoomChat } from '../../data/store/actions/ChatActions';
import { PermissionEnum } from '../../data/contants';
import { hasPermissions } from '../../utils/UserHelper';
import { useResizeDetector } from 'react-resize-detector';
import css from './Room.module.scss';
import { Sounds } from '../../utils/SoundHelper';
import { RoomMenu } from '../RoomMenu';

const RoomTheme = {
    'lobby_style': { bg: LobbySvg },
    'kitchen_style': { bg: KitchenSvg },
    'sales_style': { bg: SalesSvg },
    'support_style': { bg: SupportSvg },
    'marketing_style': { bg: MarketingSvg },
    'meeting_room_style': { bg: MeetingSvg },
    'management_style': { bg: ManagementSvg },
    'guest_room_style': { bg: GuestSvg, color: '#ccebefff' },
    'sailboat_style': { bg: SailboatSvg },
    'mount_everest_style': { bg: MountEverestSvg, color: '#ccebefff' },
    'ocean_beach_style': { bg: OceanBeachSvg },
    'r_d_style': { bg: DevSvg },
    'webinars_style': { bg: ExecutivesSvg },
    'team_room_style': { bg: TeamSvg },
    'jungle_style': { bg: JungleSvg },
    'garden_style': { bg: GardenSvg },
}

const roomMenuStyle = { position: 'absolute', top: 26, right: 16, padding: 4 };
const roomNameTextStyle = { fontFamily: 'Axiforma', pointerEvents: 'none', overflow: 'auto' };
const roomHeaderWrapperStyle = { padding: '20px 12px 25px', backgroundColor: 'initial' };
const doorWrapperStyle = { width: 24, height: 36, display: 'inherit' };
const footerButtonsWrapperStyle = { paddingLeft: -6, paddingRight: -6, paddingTop: -11, paddingBottom: -11 };
const footerDoorWrapperStyle = { paddingLeft: 6, paddingRight: 6, paddingTop: 6, paddingBottom: 6 };
const footerDoorBtnStyle = { width: 50, padding: '0.375rem' };
const footerBtnWrapperStyle = { padding: 6 };
const talkBtnStyle = { height: 50, borderRadius: 8, padding: '0.375rem' };
const talkBtnIcoStyle = { width: '76%' };
const videoBtnStyle = { height: 50, padding: '0.375rem' };
const videoBtnIcoStyle = { width: '80%' };
const btnIcoWrapperStyle = { display: 'inherit' };
const userListWrapper = { backgroundColor: 'initial' };

function Room({ room, onGroupVoiceCallHandler, onGroupVideoCallHandler, selectRoomHandler, onLeave, onMenuClick, callInProgress }) {
    //const rooms = useSelector(roomsSelector);
    const chatRooms = useSelector(chatRoomsSelector);
    const roomChat = chatRooms.find(cr => cr.roomId === room?.id);
    const hasNewMsg = (roomChat && roomChat.hasNewMsg) || false;
    const roomChatIsOpen = useSelector(roomChatIsOpenSelector);
    const currentUser = useSelector(userSelector);
    const { chat } = useContext(HubConnectorContext);
    const dispatch = useDispatch();
    const roomMenuNode = useRef();
    const [roomMenuIsOpen, toggleRoomMenu] = useState({ open: false });
    const { t } = useTranslation();

    const handleOutsideClick = (e) => {
        if (roomMenuNode.current && roomMenuNode.current.contains(e.target))
            return;

        hideMenu();
    }

    const hideMenu = () => {
        toggleRoomMenu({ open: false });
    }

    useEffect(() => {
        document.addEventListener("click", handleOutsideClick);
        document.addEventListener("contextmenu", hideMenu);

        return () => {
            document.removeEventListener("click", handleOutsideClick);
            document.removeEventListener("contextmenu", hideMenu);
        };
    }, []);

    let userList = room.participants.filter(u => u && u.online) || [];

    if (!room.showAvatars) {
        userList = userList.filter(u => u.id === currentUser.id);
    }

    const meInRoom = userList.find(u => u.id === currentUser.id);
    const roomTheme = RoomTheme[room.appearance] || RoomTheme['lobby_style'];
    const roomThemeStyle = { backgroundColor: roomTheme.color ? roomTheme.color : '#f2f2f2', minHeight: 406 };
    const roomFooterWrapperStyle = { padding: 5, backgroundColor: (room.callInProgress && !callInProgress) ? '#ff5327' : '#cccccc', borderTop: 0 };
    const RoomBg = roomTheme.bg;
    const isLobby = room.appearance === 'lobby_style';

    const renderFooterBtns = () => {
        if (callInProgress)
            return (
                <Col style={footerBtnWrapperStyle} >
                    <Button color="secondary" className='w-100' style={{ height: 50, borderRadius: 8 }} onClick={onLeave}>{t('room.call.btn.leave')}</Button>
                </Col>
            );

        if (room.callInProgress)
            return (
                <>
                    <Col style={footerBtnWrapperStyle}>
                        <Button className='btn-danger border-bottom text-truncate w-100 active' style={{ height: 50, borderRadius: 8, padding: '0.375rem' }}
                            onClick={(e) => {
                                e.stopPropagation();
                                if (meInRoom) {
                                    room.call.type.includes('video') ? onGroupVideoCallHandler(room) : onGroupVoiceCallHandler(room);
                                }
                            }}
                        >
                            {t('room.call.btn.callInProgress')}
                        </Button>
                    </Col>
                </>
            );

        return (
            <>
                {room.voiceCallEnabled && (room.doorIsOpen || meInRoom) &&
                    <Col style={footerBtnWrapperStyle}>
                        <Button
                            className='btn-room-primary border-bottom text-truncate w-100'
                            style={talkBtnStyle}
                            onClick={(e) => { e.stopPropagation(); userList.length > 0 && onGroupVoiceCallHandler(room); }}
                            disabled={meInRoom && userList.length <= 1}
                        >
                            <div style={btnIcoWrapperStyle}>
                                <img className='img-fluid crisp-edges' style={talkBtnIcoStyle} src={roomChatIco} alt='Start chat' />
                            </div>
                            {!isCompactMode &&
                                <div className='room-btn-label text-truncate ml-2 d-inline'>
                                    <span>{t('room.call.btn.talk_all').toUpperCase()}</span>
                                </div>
                            }
                        </Button>
                    </Col>
                }
                {/* {room.videoCallEnabled && (room.doorIsOpen || meInRoom) &&
                    <Col style={footerBtnWrapperStyle}>
                        <Button
                            className='btn-room-primary text-truncate w-100'
                            style={videoBtnStyle}
                            onClick={(e) => { e.stopPropagation(); userList.length > 0 && onGroupVideoCallHandler(room); }}
                            disabled={meInRoom && userList.length <= 1}
                        >
                            <div style={btnIcoWrapperStyle}>
                                <img className='img-fluid crisp-edges' style={videoBtnIcoStyle} src={roomCallIco} alt='Start call' />
                            </div>
                            {!isCompactMode &&
                                <div className='room-btn-label text-truncate ml-2 d-inline'>
                                    <span>{t('room.call.btn.video_all').toUpperCase()}</span>
                                </div>
                            }
                        </Button>
                    </Col>
                } */}
            </>

        )
    }

    const hasRoomMenuItem = () => {
        return currentUser.role && hasPermissions(currentUser.role.permissions, [PermissionEnum.SendInviteToRoom]);
    }

    const onResize = useCallback((width) => {
        toggleCompactMode(width <= 240);
    }, []);
    const [isCompactMode, toggleCompactMode] = useState(false);
    const { ref: rd_ref } = useResizeDetector({
        handleHeight: false,
        refreshMode: 'debounce',
        refreshRate: 50,
        onResize
    });

    return (
        <div ref={rd_ref} className={cn(css.roomWrapper, (callInProgress || room.callInProgress) && css.roomCallInProgress)}>
            {/* {(room.callInProgress && callInProgress) &&
                <div style={{ position: 'absolute', top: '5%', right: '5%', zIndex: 1000 }}>
                    <img src={callInProgressIco} alt="Call In Progress" />
                </div>
            } */}
            {!room.available &&
                <div className={css.roomDisabled}></div>
            }

            <Card style={roomThemeStyle} data-roomid={room.id} onDoubleClick={() => { if (selectRoomHandler) selectRoomHandler(room); }}>
                {roomMenuIsOpen.open && <RoomMenu xPos={roomMenuIsOpen.xpos} yPos={roomMenuIsOpen.ypos} roomId={room.id} onMenuClick={onMenuClick}/>}
                <CardHeader className='border-bottom-0' style={roomHeaderWrapperStyle} data-roomid={room.id}>
                    <div className='d-flex justify-content-between align-items-start'>
                        <h5 data-roomid={room.id} style={roomNameTextStyle}>
                            {room.name.toUpperCase()}
                        </h5>
                        <div className='d-flex ml-2 align-items-start'>
                            <div
                                className={`cursor-pointer ${css.messageSquareBox}`}
                                onClick={() => {
                                    dispatch(setRoomChat({ roomId: room.id }));
                                    dispatch(toggleRoomChat({ isOpen: true }));
                                }}
                            >
                                <MessageSquare/>
                                {hasNewMsg
                                    && meInRoom
                                    && !roomChatIsOpen
                                    && <div className={css.notifyUnread}><span>!</span></div>}
                                </div>
                            {!isLobby && room.available && hasRoomMenuItem() &&
                                <div ref={roomMenuNode} className='room-menu ml-2' data-roomid={room.id}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        var xx = e.pageX;
                                        var targetWidth = 180;

                                        if (e.pageX + targetWidth > window.innerWidth)
                                            xx = window.innerWidth - targetWidth;

                                        toggleRoomMenu({ open: true, xpos: xx, ypos: e.pageY - window.pageYOffset });
                                    }}
                                >
                                    <div className='room-menu-dot pointer-events-none'></div>
                                    <div className='room-menu-dot pointer-events-none'></div>
                                    <div className='room-menu-dot pointer-events-none'></div>
                                </div>
                            }
                        </div>
                    </div>
                </CardHeader>
                <CardBody className='p-0' style={userListWrapper} data-roomid={room.id}>
                    {userList.length > 0
                        ? <UsersList
                            roomId={room.id}
                            users={userList}
                            onClick={(userId, e) => { e.stopPropagation(); if (userId !== currentUser.id) dispatch(startPrivateChat(userId)); }}
                        // onVoiceCall={onVoiceCallHandler}
                        // onVideoCall={onVideoCallHandler}
                        // selectRoomHandler={() => { if (selectRoomHandler) selectRoomHandler(room); }}
                        />
                        : <div className='h-100' />
                    }
                </CardBody>
                <div className='d-flex' data-roomid={room.id}>
                    <img src={RoomBg} className='pointer-events-none w-100 gu-unselectable' alt={room.name} />
                </div>
                {!room.chatOnly && !isLobby &&
                    <CardFooter className='room-footer' style={roomFooterWrapperStyle}>
                        <div className="container-fluid">
                            <Row data-roomid={room.id} className='h-100 justify-content-end' style={footerButtonsWrapperStyle}>
                                {renderFooterBtns()}
                                <Col className='col-auto' style={footerDoorWrapperStyle}>
                                    <Button className={cn({ 'btn-room-secondary': room.doorIsOpen, 'btn-room-closed': !room.doorIsOpen }, 'mh-100 mr-0')} style={footerDoorBtnStyle}
                                        onClick={(e) => {
                                            e.stopPropagation();

                                            if (!room.available)
                                                return;

                                            if (!meInRoom) {
                                                chat.knockToRoom(room.id, "");
                                                Sounds.snd_door_knock.play();
                                                return;
                                            }

                                            if (room.doorIsOpen) {
                                                chat.closeRoom();
                                                Sounds.snd_door_close.play();
                                            } else {
                                                chat.openRoom();
                                                Sounds.snd_door_open.play();
                                            }
                                        }}
                                    >
                                        <div style={doorWrapperStyle}>
                                            <img className='img-fluid crisp-edges' src={room.doorIsOpen ? openDoorIco : closeDoorIco} alt='Door' />
                                        </div>
                                    </Button>
                                </Col>
                            </Row>
                        </div>
                    </CardFooter>
                }
            </Card>
        </div >
    );
}

export default Room;
