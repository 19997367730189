import React, { useRef, useEffect } from 'react';
import { DEFAULT_SOUND_LEVEL } from '../data/store/actions/AppActions';

const MediaPlayer = (props) => {
  const container = useRef(null);

  useEffect(() => {
    if (!container.current) return;

    if (props.videoTrack) props.videoTrack.play(container.current);

    return () => {
      if (props.videoTrack) props.videoTrack.stop();
    };
  }, [container, props.videoTrack]);
  useEffect(() => {
    if (props.audioTrack) {
      props.audioTrack.play();
      props.audioTrack.setVolume(props.mute ? 0 : DEFAULT_SOUND_LEVEL);
    }
    return () => {
      if (props.audioTrack) props.audioTrack.stop();
    };
  }, [props.audioTrack]);
  return (
    <div ref={container} className='mp-agora-contrainer' style={{
      color: "#fff",
      //backgroundColor: "#000",
      width: 320,
      height: 240,
      ...props.style
    }}>
      {/* {!props.videoTrack && <p>Voice call</p>} */}
      { props.children}
    </div >
  );
}

export default MediaPlayer;