import React from 'react';
import DeviceCheckModal from './DeviceCheckModal';
import MediaMenuModal from './MediaMenuModal';

const AppModals = () => {
    return (
        <>
            <DeviceCheckModal />
            <MediaMenuModal />
        </>
    );
}
export default AppModals;