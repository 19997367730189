import { Home, User, Users } from 'react-feather';
import { PermissionEnum, RoutePaths } from '../../data/contants';

export const MENUITEMS = [
    {
        menutitle: "",
        menucontent: "",
        Items: [
            { path: `/`, icon: Home, type: 'link', active: false, title: 'menu.team' },
            { path: `/user`, icon: User, type: 'link', active: false, title: 'menu.myprofile' },
            { id: 'currentTeam', icon: Users, type: 'sub', active: false, title: 'menu.team_management', permissions: [PermissionEnum.SendInviteToTeam],
                children: [
                    { path: RoutePaths.MembersManagement, type: 'link', title: 'menu.team_management_members' },
                    // { path: RoutePaths.MemberInvite, type: 'link', title: 'menu.team_management_invite' },
                    { path: RoutePaths.PublicSpace, type: 'link', title: 'menu.team_management_public_space' }
                ]
            },
        ]

    },
]