import { HubConnection, HubConnectionState, HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import { toast } from 'react-toastify';
import { Configuration } from './../../api/config';

export const getRoomHubConnection = (token) => {
    const BASE_PATH = `${Configuration.basePath}/chat`;

    const connection = new HubConnectionBuilder()
        .withUrl(BASE_PATH, { accessTokenFactory: () => token })
        .withAutomaticReconnect()
        .configureLogging(LogLevel.Information)
        .build();
    
    window.__connection = connection;
    return connection;
};

const startHub = (connection) => connection.start();

export const RoomHubAPI = (connection) => ({
    connect: async () => {
        if (connection.state !== HubConnectionState.Connected
            && connection.state !== HubConnectionState.Connecting
            && connection.state !== HubConnectionState.Disconnecting
            && connection.state !== HubConnectionState.Reconnecting
        ) {
            try {
                await startHub(connection);
            } catch {
                toast.error('Connection error. Please, try refresh the page.', { autoClose: false });
            }
        }
    },
    disconnect: () => {
        if (connection.state === HubConnectionState.Connected)
            connection.stop();
    },
    isDisconnected: () => {
        return connection.state === HubConnectionState.Disconnected;
    },
    enterRoom: (roomId) => {
        if (connection.state !== HubConnectionState.Connected) {
            console.warn('Chat hub is not connected');
            return;
        }

        connection.invoke('EnterRoom', roomId)
    },
    knockToRoom: (roomId, message) => {
        connection.invoke('KnockRoom', roomId, message)
    },
    knockAnswer: (message, allowInUsers) => {
        connection.invoke('KnockAnswer', message, allowInUsers)
    },
    openRoom: (message) => {
        connection.invoke('OpenRoom', message)
    },
    closeRoom: (message) => {
        connection.invoke('CloseRoom', message)
    },
    startCall: (callType, callId) => {
        connection.invoke('StartCall', callType, callId)
    },
    stopCall: (callId) => {
        connection.invoke('StopCall', callId);
    },
    /// <param name="userId">member user id</param>
    /// <param name="message">message from awaiter</param>
    inviteTeammate: (userId, message) => {
        connection.invoke('InviteTeammate', userId, message);
    },
    /// <param name="roomId">Room id you was invited to</param>
    /// <param name="senderUserId">User id that invite you</param>
    /// <param name="message">answer message (decline)</param>
    /// <param name="accept">accept/decline flag</param>
    roomInviteAnswer: (roomId, senderUserId, message, accept) => {
        connection.invoke('RoomInviteAnswer', roomId, senderUserId, message, accept);
    },
    sendRoomMessage: (message, roomId) => {
        connection.invoke('SendRoomMessage', { message }, roomId);
    },
    sendPrivateMessage: (participantId, message) => {
        connection.invoke('SendPrivateMessage', participantId, { message });
    },
    sendSetRead: (messageId) => {
        connection.invoke('SetRead', messageId);
    },
    onDisconnect: (listener) => {
        connection.onclose((err) => {
            console.error('Room SignalR connection was closed from server', err);
            connection.off('UserIsOnline');
            connection.off('UserIsOffline');
            connection.off('UserRoomState');
            connection.off('RoomCallState');
            connection.off('NewMessages');
            connection.off('DoorState');
            connection.off('PopupMessage');
            connection.off('PopupNotification');
            connection.off('UserIsKnocking');
            connection.off('OnKnockAnswer');
            connection.off('UserStatusUpdated');
            connection.off('RoomInvite');
            connection.off('UserProfileUpdated');
            connection.off('OnKnockAnswerForward');

            listener();
        });
    },
    /// <param name="userName">User name</param>
    /// <param name="userId">User id</param>
    /// <param name="userPic">User avatar</param>
    /// <param name="roomIds">User's room ids</param>
    onUserIsOnline: (listener) => connection.on(
        'UserIsOnline',
        (...data) => {
            let [userName, userId, roomIds, picture] = data;
            listener({ userName, userId, picture, roomIds });
        },
    ),
    onUserIsOffline: (listener) => connection.on(
        'UserIsOffline',
        (...data) => {
            let [userName, userId, roomIds, picture] = data;
            listener({ userName, userId, picture, roomIds });
        },
    ),
    /// <param name="userName">user name</param>
    /// <param name="userId">user id</param>
    /// <param name="roomId">room id</param>
    /// <param name="isInRoom">"is in room" 
    onUserRoomState: (listener) => connection.on(
        'UserRoomState',
        (...data) => {
            let [userName, userId, picture, roomId, isInRoom] = data;
            listener({ userName, userId, picture, roomId, isInRoom });
        },
    ),
    onRoomCallState: (listener) => connection.on(
        'RoomCallState',
        (...data) => {
            let [userName, userId, roomId, callId, callType, isCalling, count] = data;
            listener({ userName, userId, roomId, callId, callType, isCalling, count });
        },
    ),
    onNewMessages: (listener) => connection.on(
        'NewMessages',
        (...data) => {
            let [message, isTeamRoomMessage, teamRoomName] = data;
            listener({ message, isTeamRoomMessage, teamRoomName })
        },
    ),
    /// <param name="userName"></param>
    /// <param name="userId"></param>
    /// <param name="roomId"></param>
    /// <param name="isOpened"></param>
    onDoorState: (listener) => connection.on(
        'DoorState',
        (...data) => {
            let [userName, userId, roomId, isOpened, userPic] = data;
            listener({ userName, userId, roomId, isOpened, userPic });
        },
    ),
    /// <param name="message">message</param>
    /// <param name="userId">senderId</param>
    /// <param name="userName">senderUserName</param>
    /// <param name="userPic">User avatar</param>
    /// <param name="type">type: info/warning/error</param>
    onPopupMessage: (listener) => connection.on(
        'PopupMessage',
        (...data) => {
            let [code, type, payload] = data;
            listener({ code, type, payload });
        },
    ),
    /// <param name="message">message</param>
    /// <param name="type">type: info/warning/error</param>
    onPopupNotification: (listener) => connection.on(
        'PopupNotification',
        (...data) => {
            let [message, type] = data;
            listener({ message, type });
        },
    ),
    /// <param name="userName">user name</param>
    /// <param name="userId">user id</param>
    /// <param name="userPic">User avatar</param>
    /// <param name="roomId">room id</param>
    /// <param name="message"></param>
    onUserIsKnocking: (listener) => connection.on(
        'UserIsKnocking',
        (...data) => {
            let [userName, userId, userPic, roomId, message, type] = data;
            listener({ userName, userId, userPic, roomId, message, type });
        },
    ),
    /// <param name="userName">user name</param>
    /// <param name="userId">user id</param>
    /// <param name="userPic">User avatar</param>
    /// <param name="roomId">room id</param>
    /// <param name="message"></param>
    /// <param name="invited"></param>
    OnKnockAnswer: (listener) => connection.on(
        'OnKnockAnswer',
        (...data) => {
            let [userName, userId, userPic, roomId, message, invited] = data;
            listener({ userName, userId, userPic, roomId, message, invited });
        },
    ),
    /// <param name="userId">user id</param>
    /// <param name="roomId">room id</param>
    /// <param name="invited"></param>
    OnKnockAnswerForward: (listener) => connection.on(
        'OnKnockAnswerForward',
        (...data) => {
            let [userId, roomId, invited] = data;
            listener({ userId, roomId, invited });
        },
    ),
    /// <param name="userId">User Id</param>
    /// <param name="userName">User Name</param>
    /// <param name="status">User status details</param>
    onUserStatusUpdated: (listener) => connection.on(
        'UserStatusUpdated',
        (...data) => {
            let [userId, userName, status, silent] = data;
            listener({ userName, userId, status, silent });
        },
    ),
    /// <param name="callerUserId">caller user id</param>
    /// <param name="callerUserName">caller user name</param>
    /// <param name="roomName">room name</param>
    /// <param name="roomId">room id</param>
    /// <param name="message">message. optional</param>
    onRoomInvite: (listener) => connection.on(
        'RoomInvite',
        (...data) => {
            let [callerUserId, callerUserName, roomName, roomId, message, callerUserPic] = data;
            listener({ callerUserId, callerUserName, roomName, roomId, message, callerUserPic });
        },
    ),
    onUserProfileUpdated: (listener) => connection.on(
        'UserProfileUpdated',
        (...data) => {
            let [profile] = data;
            listener({ profile });
        },
    ),
});
