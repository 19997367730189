import { createSelector } from "reselect";
import { AsyncRequestType } from "../../data/store/actions/AppActions";

export const userSelector = state => state.user;
export const teamSelector = state => state.team;
export const inProgressSelector = state => state.app.inProgress;

export const callInProgressSelector = createSelector(inProgressSelector, p => p[AsyncRequestType.initCall]);
export const roomsSelector = createSelector(teamSelector, x => x.rooms);
export const teamsSelector = createSelector(teamSelector, x => x.teams);
export const currentTeamIdSelector = createSelector(teamSelector, x => x.currentTeamId);
export const knockRequestsSelector = createSelector(teamSelector, x => x.knockRequests);
export const inviteRequestsSelector = createSelector(teamSelector, x => x.inviteRequests);

export const myCurrentRoomSelector = createSelector(
    userSelector,
    roomsSelector,
    (user, rooms) => rooms.find(r => r.participants.find(p => p.id === user.id && p.online)));

export const roomCallSelector = createSelector(
    myCurrentRoomSelector,
    room => room?.call
        ? { id: room.call.id, type: room.call.type, roomId: room.call.roomId, count: room.call.count, isNew: room.call.count > 0 }
        : { count: 0, isNew: false }
);
