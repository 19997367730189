import React, { useState, useEffect, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import get from 'lodash/get';

import { AuthContext } from '../../../components/AuthProvider';
import Loader from '../../../components/common/Loader';
import { getRoomHubConnection, RoomHubAPI } from '../../signalr/RoomHub';

import { receiveUserOnlineStatus, receiveUserRoomState, receiveRoomCallState, receiveRoomDoorState, receiveRoomEnterRequest, receiveRoomInviteRequest, receiveRoomEnterRequestProcessed, receiveUserStatus } from './../../store/actions/TeamActions';
import { receiveRoomChatMessage } from '../../store/actions/ChatActions';
import { receiveUserProfileUpdate } from '../../store/actions/UserActions';
import { Sounds } from '../../../utils/SoundHelper';
import { isSystemMessage } from '../../../utils/ChatHelper';
import { asyncRequestCompleted, AsyncRequestType } from '../../store/actions/AppActions';
import { createSelector } from 'reselect';

import logo from '../../../assets/images/logo/Icon-150_150.png';
import { currentRoomIdSelector } from '../../store/selectors/chatSelectors';

export const HubConnectorContext = React.createContext();
const { Consumer, Provider } = HubConnectorContext;

let _rooms = [];
let _currentRoomId = null;

const getPopup = (type) => {
    switch (type) {
        case 'warning': return toast.warn;
        case 'error': return toast.error;
        case 'info': return toast.success;
        default: return toast.success;
    }
}

const playPopupSound = (type, code) => {
    const ignore = [
        'notification.user_is_online',
        'notification.user_is_offline',
        //'notification.user_enter_room',
        'notification.user_left_room',
        'notification.user_status_changed',
    ];

    if (ignore.includes(code))
        return;

    if (type === 'info')
        Sounds.snd_success.play();
    else
        Sounds.snd_warn.play();
}

const currentUserSelector = state => state.user;
const teamSelector = state => state.team;
const roomsSelector = createSelector(teamSelector, x => x.rooms);

const onlineStatus = {};
const trackLeftRoom = {};
let tabIsActive = true;

export const HubConnector = ({ children }) => {
    const ctx = useContext(AuthContext);
    const user = useSelector(currentUserSelector);
    const rooms = useSelector(roomsSelector);
    const currentRoomId = useSelector(currentRoomIdSelector);

    const [hubs, setHubs] = useState({});
    const dispatch = useDispatch();
    const { t } = useTranslation();

    useEffect(() => {
        _rooms = rooms;
    }, [rooms]);

    useEffect(() => {
        _currentRoomId = currentRoomId;
    }, [currentRoomId]);

    useEffect(() => {
        (async () => {
            const token = await ctx.getToken();
            const chatHub = RoomHubAPI(getRoomHubConnection(token));

            chatHub.onDisconnect(() => { });
            chatHub.onUserIsOnline((data) => {
                dispatch(receiveUserOnlineStatus({ ...data, online: true }));
            });
            chatHub.onUserIsOffline((data) => {
                dispatch(receiveUserOnlineStatus({ ...data, online: false }));
            });
            chatHub.onUserRoomState((data) => {
                if (data.userId === user.id && data.isInRoom)
                    data.available = true; //temp solution

                const timeout = trackLeftRoom[data.userId];
                if (timeout && timeout.data.payload.room.id !== data.roomId) {
                    clearTimeout(timeout);
                    showPopup(timeout.data);
                    trackLeftRoom[data.userId] = null;
                }

                dispatch(receiveUserRoomState(data));
            });
            chatHub.onUserStatusUpdated((data) => {
                if (user.id !== data.userId && !data.silent) {
                    toast.success(t(`notification.user_status_changed`, {
                        userName: data.userName,
                        status: t(`status_dictionary.select_statusCode_${data.status.availabilityStatus}`)
                    }), { toastId: `user_status_changed-${data.userName}-${data.status.availabilityStatus}` });
                }

                dispatch(receiveUserStatus(data));
            });
            chatHub.onRoomCallState((data) => {
                if (data.isCalling && user.id !== data.userId) {
                    const room = _rooms.find(r => r.id === data.roomId);

                    if (room && room.participants.find(p => p.online && p.id === user.id)) {
                        const key = data.callType === 'video' ? 'video_call_started' : "call_started";

                        Sounds.snd_success.play();
                        toast.success(t(`notification.${key}`, { userName: data.userName }), { toastId: `${key}-${data.userName}-${room.id}` });
                    }
                }

                dispatch(receiveRoomCallState(data));
            });
            chatHub.onDoorState((data) => {
                if (user.id !== data.userId) {
                    const room = _rooms.find(r => r.id === data.roomId);
                    const key = data.isOpened ? 'room_door_opened' : "room_door_closed";

                    if (room) {
                        const toastId = `${key}-${data.userName}-${room.id}`;
                        const message = t(`notification.${key}`, { userName: data.userName, roomName: room.name });

                        (data.isOpened ? Sounds.snd_door_open : Sounds.snd_door_close).play();
                        toast.success(message, { toastId });
                        showBrowserNotify(toastId, message, data.userPic);
                    }
                }

                dispatch(receiveRoomDoorState(data));
            });
            chatHub.onRoomInvite((data) => {
                Sounds.snd_warn.play();
                dispatch(receiveRoomInviteRequest(data));

                showBrowserNotify(`invite-${data.callerUserId}-${data.roomId}`, `Invite from ${data.callerUserName}`, data.callerUserPic);
            });
            chatHub.onUserIsKnocking((data) => {
                dispatch(receiveRoomEnterRequest(data));

                showBrowserNotify(`knock-${data.userId}-${data.roomId}`, `${data.userName} is knocking`, data.userPic);
            });
            chatHub.OnKnockAnswer((data) => {
                dispatch(asyncRequestCompleted({ type: AsyncRequestType.knock, data, complete: true }));
            });
            chatHub.OnKnockAnswerForward((data) => {
                const { userId, roomId } = data;
                dispatch(receiveRoomEnterRequestProcessed({ userId, roomId }));
                dispatch(asyncRequestCompleted({ type: AsyncRequestType.knock, data, complete: true }));
            });
            chatHub.onPopupMessage((data) => {
                if (data.code === 'notification.user_left_room') {
                    const timeout = trackLeftRoom[data.payload.user.id];
                    if (timeout)
                        clearTimeout(timeout);

                    trackLeftRoom[data.payload.user.id] = {
                        data,
                        timer: setTimeout(() => { showPopup(data); trackLeftRoom[data.payload.user.id] = null; }, leaveDealy)
                    };
                    return;
                }

                if (data.code === 'notification.user_enter_room') {
                    const timeout = trackLeftRoom[data.payload.user.id];

                    if (timeout && timeout.timer) {
                        clearTimeout(timeout.timer);

                        const roomId = timeout.data.payload.room.id;
                        trackLeftRoom[data.payload.user.id] = null;

                        if (roomId && roomId === data.payload.room.id)
                            return;
                    }
                }

                if (data.code === 'notification.user_is_online') {
                    const timeout = onlineStatus[data.payload.user.id];
                    if (timeout) {
                        onlineStatus[data.payload.user.id] = null;
                        clearTimeout(timeout);

                        return;
                    }
                }

                if (data.code === 'notification.user_is_offline') {
                    const timeout = onlineStatus[data.payload.user.id];
                    if (timeout)
                        clearTimeout(timeout);

                    onlineStatus[data.payload.user.id] = setTimeout(() => { showPopup(data); onlineStatus[data.payload.user.id] = null; }, leaveDealy);
                    return;
                }

                showPopup(data);
            });
            chatHub.onPopupNotification((data) => {
                const popup = getPopup(data.type);

                playPopupSound(data.type, data.message);

                if (data.message.startsWith('notification.')) {
                    popup(t(data.message), { toastId: data.message });
                    //showBrowserNotify(data.message, t(data.message));
                } else {
                    popup(data.message, { toastId: data.message });
                }
            });
            //chat
            chatHub.onNewMessages((data) => {
                if (!isSystemMessage(data.message) && user.id !== data.message.senderId) {
                    if (!data.isTeamRoomMessage || (_currentRoomId && _currentRoomId === data.message.roomId)) {
                        Sounds.snd_chat_new_msg.play();
                        showBrowserNotify(data.message.id, data.message.message, data.message.senderPic);
                    }
                }

                dispatch(receiveRoomChatMessage({ ...data, user }));
            });

            chatHub.onUserProfileUpdated((data) => {
                dispatch(receiveUserProfileUpdate(data));
            });

            setHubs({
                chat: chatHub
            });
        })();

        // navigator.mediaDevices.getUserMedia({ audio: true, video: true })
        //     .catch(err => { console.warn("Mic&Cam permissions not granted.", err.message) });

        window.addEventListener('visibilitychange', handleVisibilityChangeEvent);

        return async () => {
            window.removeEventListener('visibilitychange', handleVisibilityChangeEvent);
            hubs.chat && hubs.chat.disconnect();
        };
    }, []);

    const handleVisibilityChangeEvent = () => {
        if (document.visibilityState === 'visible') {
            tabIsActive = true;
            toast.clearWaitingQueue();

            while (notifications.length)
                notifications.pop().close();
        } else {
            tabIsActive = false;
        }
    }

    const showPopup = (data) => {
        const popup = getPopup(data.type);

        const userName = get(data, 'payload.user.name', '');

        if (
            data.code !== 'notification.enterRoom_doorClosed'
            && data.code !== 'notification.knock_answer'
            && userName.length === 0
        ) {
            return;
        }

        let message = t(data.code, { userName, roomName: get(data, 'payload.room.name'), teamName: get(data, 'payload.team.name') });

        const toastId = `${data.code}-${get(data, 'payload.user.name', '')}-${get(data, 'payload.room.name', '')}`;
        popup(message, { toastId });
        playPopupSound(data.type, data.code);
        //toast.update(toastId, { autoClose: 5000 });

        showBrowserNotify(toastId, message, get(data, 'payload.user.picture'));
    }

    if (!hubs.chat) {
        return <Loader />
    }

    return <Provider value={hubs}>{children}</Provider>;
};

const leaveDealy = 6 * 1000;

const notifications = [];
const showBrowserNotify = (id = 'tb', message, icon) => {
    if (tabIsActive)
        return;

    if (window.Notification && Notification.permission === "granted") {
        notifications.push(getBrowserNotify(id, message, icon));
    } else if (window.Notification && Notification.permission !== "denied") {
        Notification.requestPermission(function (permission) {
            if (permission === "granted")
                notifications.push(getBrowserNotify(id, message, icon));
        });
    }
}

const getBrowserNotify = (tag, message, icon = logo) => {
    return new Notification(
        "Teamblaze",
        {
            body: message,
            icon,
            tag,
        }
    );
}

export { Consumer as HubConnectorConsumer };