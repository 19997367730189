import React from 'react';
import { withAuth0 } from "@auth0/auth0-react";
import { withTranslation } from 'react-i18next';
import axios from "axios";

const AuthContext = React.createContext({});
const { Provider, Consumer } = AuthContext;

class AuthProvider extends React.Component {
    login = (data) => {
        this.props.auth0.loginWithRedirect();
    }

    logout = () => {
        localStorage.removeItem('auth-token');
        axios.defaults.headers.common['Authorization'] = '';

        this.props.auth0.logout({ returnTo: window.location.origin });
    }

    getToken = async (ignoreStore = false) => {
        const lastToken = localStorage.getItem('auth-token');

        if (!ignoreStore && lastToken && lastToken.length > 0) {
            axios.defaults.headers.common['Authorization'] = `Bearer ${lastToken}`;

            return lastToken;
        }

        const newToken = await this.props.auth0.getAccessTokenSilently();

        localStorage.setItem('auth-token', newToken);
        axios.defaults.headers.common['Authorization'] = `Bearer ${newToken}`;

        return newToken;
    }

    setToken = (type, token) => {
        localStorage.setItem('auth-token', token);
        axios.defaults.headers.common['Authorization'] = `${type} ${token}`;
    }

    clearToken = (type, token) => {
        localStorage.removeItem('auth-token');
        axios.defaults.headers.common['Authorization'] = '';
    }

    render() {
        return (
            <Provider value={{ logout: this.logout, getToken: this.getToken, setToken: this.setToken, clearToken: this.clearToken }}>
                {this.props.children}
            </Provider>
        );
    }
}

let authProvider = withTranslation()(withAuth0((AuthProvider)));

export {
    Consumer as AuthConsumer,
    authProvider as AuthProvider,
    AuthContext,
};
