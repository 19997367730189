import React, { useState, useEffect, useCallback } from 'react';

const useContextMenu = (target, onOpen, onClick) => {
    const [xPos, setXPos] = useState("0px");
    const [yPos, setYPos] = useState("0px");
    const [showMenu, setShowMenu] = useState(false);
    const [cmData, setData] = useState({});

    const handleContextMenu = useCallback(
        (e) => {
            let d = {};

            if (onOpen) {
                d = onOpen(e);

                if (!d.success)
                    return;
            }

            e.preventDefault();

            var xx = e.pageX;
            var targetWidth = 180;

            if(e.pageX + targetWidth > window.innerWidth)
                xx = window.innerWidth - targetWidth;

            setXPos(`${xx}px`);
            setYPos(`${e.pageY - window.pageYOffset}px`);
            setData(d.data);
            setShowMenu(true);
        },
        [setXPos, setYPos]
    );

    const handleClick = useCallback(() => {
        setShowMenu(false);
    }, [showMenu]);

    useEffect(() => {
        if (!target || !target.current)
            return;

        document.addEventListener("click", handleClick);
        target.current.addEventListener("contextmenu", handleContextMenu);

        return () => {
            document.removeEventListener("click", handleClick);
            target.current.removeEventListener("contextmenu", handleContextMenu);
        };
    }, [target]);

    return { xPos, yPos, showMenu, cmData };
};

export default useContextMenu;