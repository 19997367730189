import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { Configuration } from './../data/api/config';

import { Container, Row, Col, Button, Nav, NavItem, NavLink, TabContent, TabPane, Card, CardBody, Label, Input, Media } from 'reactstrap';
import { Formik, Form, Field } from 'formik';

import { getOrCreateUser, postProfileDetails } from "./../data/store/actions/UserActions";
import { postImageApi } from "./../data/api/files";
import { RFInput } from '../components/common/RFInput';
import AvatarCreatorModal from '../components/Modals/AvatarCreatorModal';
import { dataURLtoBlob } from '../utils/UserHelper';
import { RoutePaths } from '../data/contants';
import { useHistory } from 'react-router';

const selectUserInfo = state => state.user;

function UserDetailsForm({ toggleAvatarBuilder }) {
    const dispatch = useDispatch();
    const userInfo = useSelector(selectUserInfo);
    const history = useHistory();
    const { t } = useTranslation();

    useEffect(() => {
        dispatch(getOrCreateUser());
    }, []);

    const { firstName, lastName, email, picture } = userInfo

    return (
        <Formik
            initialValues={{
                firstName: firstName || '',
                lastName: lastName || '',
                //middleName: '',
                //nickName: '',
                //picture,
                email: email || '',
            }}
            validationSchema={Yup.object({
                email: Yup.string().email().required(),
            })}
            onSubmit={async (values, { setSubmitting }) => {
                setSubmitting(true);

                dispatch(postProfileDetails(values));

                setSubmitting(false);
            }}
        >
            {({ submitForm, isSubmitting, setFieldValue }) => (
                <Form>
                    <div>
                        <Field
                            component={RFInput}
                            name='firstName'
                            type='text'
                            label={t('common.form.first_name_label')}
                            variant='outlined'
                        />
                    </div>
                    <div>
                        <Field
                            component={RFInput}
                            name='lastName'
                            type='text'
                            label={t('common.form.last_name_label')}
                            variant='outlined'
                        />
                    </div>
                    <div>
                        <Field
                            component={RFInput}
                            name='email'
                            type='email'
                            label={t('common.form.email_label')}
                            variant='outlined'
                        />
                    </div>
                    <div className="form-group">
                        <Label>{t("edit_profile_form.change_avatar_label")}</Label>
                        <Row>
                            <Col className='col-auto'>
                                <Button className='text-nowrap btn-block' color='primary' style={{ minWidth: 120 }} onClick={() => { toggleAvatarBuilder(true); }}>{t('edit_profile_page.open_avatar_builder_btn')}</Button>
                            </Col>
                            <Col className='col-auto'>
                                <Button className='text-nowrap btn-block' color='primary' style={{ minWidth: 120 }} onClick={() => { document.getElementById('avatar_file').click(); }}>{t('edit_profile_page.avatar_from_file_btn')}</Button>
                            </Col>
                        </Row>
                        <Input id="avatar_file" name="picture" type="file" style={{ visibility: 'hidden', width: 0, height: 0, display: 'block' }} onChange={async (event) => {
                            try {
                                if (!event.currentTarget || !event.currentTarget.files[0])
                                    return;

                                const filerdr = new FileReader();
                                filerdr.onload = (e) => {
                                    const img = new Image();

                                    img.onload = async () => {
                                        try {
                                            var canvas = document.createElement('canvas');
                                            var ctx = canvas.getContext('2d');
                                            canvas.width = 130;
                                            canvas.height = 130;
                                            const iwidth = Math.min(130, img.width);
                                            const iheight = iwidth * (img.height / img.width);
                                            ctx.drawImage(img, canvas.width * 0.5 - iwidth * 0.5, canvas.height * 0.5 - iheight * 0.5, iwidth, iheight);

                                            const dataUrl = canvas.toDataURL('image/png');
                                            const resizedImage = dataURLtoBlob(dataUrl);

                                            const { data } = await postImageApi(resizedImage);
                                            const link = `${Configuration.basePath}/${data.link}`;

                                            await dispatch(postProfileDetails({
                                                ...userInfo,
                                                picture: link
                                            }));

                                            setFieldValue("picture", link);
                                        } catch (e) { console.warn(e) }
                                    }
                                    img.src = e.target.result;
                                }

                                filerdr.readAsDataURL(event.currentTarget.files[0]);
                            } catch (e) { }
                        }} className="form-control" />
                    </div>
                    <div>
                        <Row>
                            <Col className='col-auto'>
                                <Button
                                    variant='contained'
                                    color='success'
                                    disabled={isSubmitting}
                                    onClick={submitForm}
                                >
                                    {t('common.btn.submit')}
                                </Button>
                            </Col>
                            <Col className='col-auto'>
                                <Button
                                    variant='contained'
                                    color='secondary'
                                    disabled={isSubmitting}
                                    onClick={() => { history.push(RoutePaths.Root) }}
                                >
                                    {t('common.btn.gotoOffice')}
                                </Button>
                            </Col>
                        </Row>
                    </div>
                </Form>
            )}
        </Formik>
    );
}

function MyProfilePage() {
    const dispatch = useDispatch();
    const userInfo = useSelector(selectUserInfo);
    const { t } = useTranslation();
    //const user = useSelector(selectUserInfo);

    // const handleChange = (event, newValue) => {
    //     setValue(newValue);
    // };

    const [activeTab, setActiveTab] = React.useState('1');
    const [showAvatarBuilder, toggleAvatarBuilder] = React.useState(false);

    const toggle = tab => {
        if (activeTab !== tab) setActiveTab(tab);
    }

    return (
        <Container fluid>
            <div className="page-title">
                <h3>{t('edit_profile_page.header')}</h3>
            </div>
            <Row
                direction="column"
                justify="flex-start"
            >
                <Col xs={12}>
                    <Card>
                        <CardBody>
                            <Nav className="border-tab" tabs>
                                <NavItem>
                                    <NavLink href="#" className={activeTab === '1' ? 'active' : ''} onClick={() => toggle('1')}><i className="icofont icofont-ui-home"></i>{t('edit_profile_page.profile_tab')}</NavLink>
                                </NavItem>
                                {/* <NavItem>
                                    <NavLink href="#" className={activeTab === '2' ? 'active' : ''} onClick={() => toggle('2')}><i className="icofont icofont-man-in-glasses"></i>{`Notifications`}</NavLink>
                                </NavItem> */}
                            </Nav>
                            <TabContent activeTab={activeTab}>
                                <TabPane className="fade show" tabId="1">
                                    <Row className='align-items-center'>
                                        <Col className='col-6'>
                                            <div>
                                                <UserDetailsForm
                                                    toggleAvatarBuilder={toggleAvatarBuilder}
                                                />
                                            </div>
                                        </Col>
                                        <Col className='col-6'>
                                            <div className='p-b-50 text-center'>
                                                <span className="d-inline-block" tabIndex="0" data-toggle="tooltip" data-placement="bottom" title={t('edit_profile_page.open_avatar_builder_btn')}>
                                                    <Media body className="rounded-circle img-120" src={userInfo.picture} alt={userInfo.name} style={{ boxShadow: '0 0 21px 0 rgb(89 102 122 / 10%)', cursor: 'pointer' }} onClick={() => { toggleAvatarBuilder(true); }} />
                                                </span>
                                            </div>
                                            {/* <div style={{ margin: '0 auto', display: 'table' }}>
                                                <Button className='text-nowrap btn-block' color='primary' style={{ maxWidth: 120 }} onClick={() => { toggleAvatarBuilder(true); }}>{t('avatar_builder.create_btn')}</Button>
                                            </div> */}
                                        </Col>
                                    </Row>
                                </TabPane>
                                {/* <TabPane tabId="2">
                                    <Row>
                                        <Col sm="12">Notifications</Col>
                                    </Row>
                                </TabPane> */}
                            </TabContent>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            {showAvatarBuilder &&
                <AvatarCreatorModal
                    onComplete={async (avatar) => {
                        const { data } = await postImageApi(dataURLtoBlob(avatar));
                        const link = `${Configuration.basePath}/${data.link}`;

                        await dispatch(postProfileDetails({
                            ...userInfo,
                            picture: link
                        }));

                        toggleAvatarBuilder(false);
                    }}
                    onClose={() => { toggleAvatarBuilder(false); }}
                />
            }
        </Container>
    );
}

export default MyProfilePage;