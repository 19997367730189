import React, { useEffect, useState } from 'react';
import { Redirect, Route, BrowserRouter as Router, Switch, useHistory, useLocation } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { ToastContainer } from 'react-toastify';
import { toast } from 'react-toastify';
import axios from 'axios';

import IndexPage from '../pages/IndexPage';
import MyProfilePage from '../pages/MyProfilePage';
import AcceptInvitePage from '../pages/AcceptInvitePage';
import AppModals from '../components/Modals/AppModals'

import { DictionariesProvider } from './../data/context/Dictionaries';
import { HubConnector } from '../data/context/HubConnector';

import Header from '../layout/header';
import Sidebar from './../layout/sidebar';
import Loader from '../components/common/Loader';
import UserManagementPage from '../pages/TeamManagement/UserManagementPage';
import PublicSpacePage from '../pages/TeamManagement/PublicSpacePage';
import { RoutePaths } from '../data/contants';
import { getFromLinkApi } from '../data/api/invite';

const AxiosSetup = ({ children }) => {
  const history = useHistory();

  useEffect(() => {
    axios.interceptors.response.use((resp) => {
      localStorage.removeItem('retry-count');
      return resp;
    }, async function (err) {
      if (err.response.status === 401) {
        localStorage.removeItem('auth-token');
        const retry = localStorage.getItem('retry-count');

        if (!retry || retry < 3) {
          localStorage.setItem('retry-count', Number(retry || 0) + 1);
          history.push(RoutePaths.Auth);
        }
      } else {
        localStorage.removeItem('retry-count');
        toast.error('Sorry, something was wrong');
      }

      return Promise.reject(err);
    });
  }, []);

  return <>{children}</>
}

const AppRouter = () => (
  <Router>
    <Switch>
      <Route
        path={`${RoutePaths.Invite}/:id`}
        component={AcceptInvitePage}
        exact={true}
      />
      <Route
        path={RoutePaths.Auth}
        component={RedirectToAuth}
        exact={true}
      />
      <PrivateRoute
        path={RoutePaths.Root}
      //exact={true}
      >
        <AxiosSetup>
          <DictionariesProvider>
            <HubConnector>
              <Router>
                {/* <Loader /> */}
                <div className="page-wrapper compact-wrapper" id="pageWrapper">
                  <Header />
                  <div className="page-body-wrapper sidebar-icon">
                    <Sidebar />
                    <div className="page-body bg-white">
                      <Switch>
                        <Route
                          path={RoutePaths.Root}
                          exact={true}
                        >
                          <IndexPage />
                        </Route>
                        <Route
                          path={RoutePaths.UserProfile}
                          exact={true}
                        >
                          <MyProfilePage />
                        </Route>
                        <Route
                          path={`${RoutePaths.Team}/:teamId`}
                          exact={true}
                        >
                          <IndexPage />
                        </Route>
                        <Route
                          path={RoutePaths.PublicSpace}
                          exact={true}
                        >
                          <PublicSpacePage />
                        </Route>
                        <Route
                          path={RoutePaths.UsersManagement}
                          exact={true}
                        >
                          <UserManagementPage />
                        </Route>
                        {/* <Route path={RoutePaths.Forbidden} component={Forbidden} />
                        <Route component={NotFound} /> */}
                        <Route path="*" render={() => <Redirect to={RoutePaths.Root} />} />
                      </Switch>
                      <AppModals />
                    </div>
                    {/* <Footer /> */}
                  </div>
                </div>
                <ToastContainer limit={5} pauseOnFocusLoss={false} />
              </Router>
            </HubConnector>
          </DictionariesProvider>
        </AxiosSetup>
      </PrivateRoute>
    </Switch>
  </Router >
);

function PrivateRoute({ children, ...rest }) {
  const [isLoading, loading] = useState(true);
  const [publicSpace, setPublicSpace] = useState({});
  const { isAuthenticated } = useAuth0();
  const history = useHistory();
  const loc = useLocation();

  useEffect(() => {
    if (Object.values(RoutePaths).find(p => p === loc.pathname)) {
      loading(false);
      return;
    }

    (async () => {
      try {
        const { data } = await getFromLinkApi(loc.pathname);
        setPublicSpace(data);
      } catch {
        history.replace('/');
      } finally {
        loading(false);
      }
    })();
  }, []);

  if (isLoading)
    return <><Loader /></>

  if (publicSpace.id) {
    return <Redirect to={`${RoutePaths.Invite}/${publicSpace.id}`} />;
  }

  return (
    <Route
      {...rest}
      render={({ location }) => {
        if (isAuthenticated) {
          const invite = localStorage.getItem('invite-id');

          if (invite && invite.length > 0) {
            localStorage.removeItem('invite-id');

            return (
              <Redirect
                to={{
                  pathname: `${RoutePaths.Invite}/${invite}`,
                  state: { from: location }
                }}
              />
            )
          }

          return children;
        }

        const token = localStorage.getItem('auth-token');

        if (token)
          return children;

        return (
          <Redirect
            to={{
              pathname: "/auth",
              state: { from: location }
            }}
          />
        );
      }}
    />
  );
}

function RedirectToAuth() {
  const { isAuthenticated, isLoading, loginWithRedirect } = useAuth0();
  const history = useHistory();

  React.useEffect(() => {
    if (isLoading)
      return;

    if (isAuthenticated)
      history.replace('/');
    else
      loginWithRedirect();
  }, [isAuthenticated, isLoading]);

  return (
    <Loader />
  );
}

export default AppRouter;
