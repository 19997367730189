const female = {
    "faces": [
        { type: 'f_white', preview: 'assets/avatar/female/faces/1.png' },
        { type: 'f_darkerWhite', preview: 'assets/avatar/female/faces/2.png' },
        { type: 'f_black', preview: 'assets/avatar/female/faces/3.png' },
        { type: 'f_brown', preview: 'assets/avatar/female/faces/4.png' }
    ],
    "emotions": [
        //left: 46, top: 20 + 20
        { preview: 'assets/avatar/female/emotions/1.png', required: ['f_white', 'f_darkerWhite'] },
        { preview: 'assets/avatar/female/emotions/2.png', required: ['f_white', 'f_darkerWhite'], offset: { left: -3, top: 6 + 2 } },
        { preview: 'assets/avatar/female/emotions/3.png', required: ['f_white', 'f_darkerWhite'], offset: { left: -2, top: 6 + 2 } },
        { preview: 'assets/avatar/female/emotions/4.png', required: ['f_white', 'f_darkerWhite'], offset: { left: -4, top: 5 + 2 } },
        { preview: 'assets/avatar/female/emotions/5.png', required: ['f_white', 'f_darkerWhite'], offset: { left: -3, top: 4 + 2 } },
        { preview: 'assets/avatar/female/emotions/6.png', required: ['f_white', 'f_darkerWhite'], offset: { left: -2, top: 4 + 2 } },
        { preview: 'assets/avatar/female/emotions/7.png', required: ['f_white', 'f_darkerWhite'] },
        { preview: 'assets/avatar/female/emotions/8.png', required: ['f_white', 'f_darkerWhite'] },
        { preview: 'assets/avatar/female/emotions/9.png', required: ['f_white', 'f_darkerWhite'], offset: { left: -4, top: 6 + 2 } },
        { preview: 'assets/avatar/female/emotions/10.png', required: ['f_white', 'f_darkerWhite'], offset: { left: -4, top: 6 + 2 } },
        { preview: 'assets/avatar/female/emotions/11.png', required: ['f_white', 'f_darkerWhite'], offset: { left: -3, top: 4 + 2 } },
        { preview: 'assets/avatar/female/emotions/13.png', required: ['f_white', 'f_darkerWhite'], offset: { left: -2, top: 6 + 2 } },
        { preview: 'assets/avatar/female/emotions/14.png', required: ['f_white', 'f_darkerWhite'], offset: { left: -2, top: 6 + 2 } },
        { preview: 'assets/avatar/female/emotions/15.png', required: ['f_white', 'f_darkerWhite'], offset: { left: -2, top: 6 + 2 } },
        { preview: 'assets/avatar/female/emotions/16.png', required: ['f_white', 'f_darkerWhite'] },
        { preview: 'assets/avatar/female/emotions/17.png', required: ['f_white', 'f_darkerWhite'] },
        { preview: 'assets/avatar/female/emotions/black_1.png', required: ['f_black'] },
        { preview: 'assets/avatar/female/emotions/black_2.png', required: ['f_black'], offset: { left: -2, top: 6 + 2 } },
        { preview: 'assets/avatar/female/emotions/black_3.png', required: ['f_black'], offset: { left: -2, top: 6 + 2 } },
        { preview: 'assets/avatar/female/emotions/black_4.png', required: ['f_black'], offset: { left: -4, top: 5 + 2 } },
        { preview: 'assets/avatar/female/emotions/black_5.png', required: ['f_black'], offset: { left: -3, top: 4 + 2 } },
        { preview: 'assets/avatar/female/emotions/black_6.png', required: ['f_black'], offset: { left: -2, top: 4 + 2 } },
        { preview: 'assets/avatar/female/emotions/black_7.png', required: ['f_black'] },
        { preview: 'assets/avatar/female/emotions/black_8.png', required: ['f_black'] },
        { preview: 'assets/avatar/female/emotions/black_9.png', required: ['f_black'], offset: { left: -4, top: 6 + 2 } },
        { preview: 'assets/avatar/female/emotions/black_10.png', required: ['f_black'], offset: { left: -4, top: 5 + 2 } },
        { preview: 'assets/avatar/female/emotions/black_11.png', required: ['f_black'] },
        { preview: 'assets/avatar/female/emotions/black_12.png', required: ['f_black'] },
        { preview: 'assets/avatar/female/emotions/black_13.png', required: ['f_black'], offset: { left: -2, top: 6 + 2 } },
        { preview: 'assets/avatar/female/emotions/brown_1.png', required: ['f_brown'] },
        { preview: 'assets/avatar/female/emotions/brown_2.png', required: ['f_brown'], offset: { left: -2, top: 6 + 2 } },
        { preview: 'assets/avatar/female/emotions/brown_3.png', required: ['f_brown'], offset: { left: -2, top: 6 + 2 } },
        { preview: 'assets/avatar/female/emotions/brown_4.png', required: ['f_brown'], offset: { left: -4, top: 5 + 2 } },
        { preview: 'assets/avatar/female/emotions/brown_5.png', required: ['f_brown'], offset: { left: -3, top: 4 + 2 } },
        { preview: 'assets/avatar/female/emotions/brown_6.png', required: ['f_brown'], offset: { left: -2, top: 4 + 2 } },
        { preview: 'assets/avatar/female/emotions/brown_8.png', required: ['f_brown'] },
        { preview: 'assets/avatar/female/emotions/brown_10.png', required: ['f_brown'], offset: { left: -3, top: 5 + 2 } },
        { preview: 'assets/avatar/female/emotions/brown_11.png', required: ['f_brown'], offset: { left: -2, top: 4 + 2 } },
        { preview: 'assets/avatar/female/emotions/brown_12.png', required: ['f_brown'], offset: { left: -2, top: 4 + 2 } },
        { preview: 'assets/avatar/female/emotions/brown_13.png', required: ['f_brown'], offset: { left: -2, top: 6 + 2 } },
    ],
    "hairs": [
        //{ left: 31, top: 0 + 10 }
        { preview: 'assets/avatar/female/hairs/1.png', offset: { left: -4, top: -9 + 2 } },
        { preview: 'assets/avatar/female/hairs/black_1.png', offset: { left: -4, top: -9 + 2 } },
        { preview: 'assets/avatar/female/hairs/blond_1.png', offset: { left: -4, top: -9 + 2 } },
        { preview: 'assets/avatar/female/hairs/ginger_1.png', offset: { left: -4, top: -9 + 2 } },
        { preview: 'assets/avatar/female/hairs/2.png', offset: { left: 5, top: -25 + 2 } },
        { preview: 'assets/avatar/female/hairs/black_2.png', offset: { left: 5, top: -25 + 2 } },
        { preview: 'assets/avatar/female/hairs/blond_2.png', offset: { left: 5, top: -25 + 2 } },
        { preview: 'assets/avatar/female/hairs/ginger_2.png', offset: { left: 5, top: -25 + 2 } },
        { preview: 'assets/avatar/female/hairs/3.png', offset: { left: 5, top: -6 + 2 } },
        { preview: 'assets/avatar/female/hairs/black_3.png', offset: { left: 5, top: -6 + 2 } },
        { preview: 'assets/avatar/female/hairs/blond_3.png', offset: { left: 5, top: -6 + 2 } },
        { preview: 'assets/avatar/female/hairs/ginger_3.png', offset: { left: 5, top: -6 + 2 } },
        { preview: 'assets/avatar/female/hairs/4.png', offset: { left: -1, top: 4 + 2 } },
        { preview: 'assets/avatar/female/hairs/black_4.png', offset: { left: -1, top: 4 + 2 } },
        { preview: 'assets/avatar/female/hairs/blond_4.png', offset: { left: -1, top: 4 + 2 } },
        { preview: 'assets/avatar/female/hairs/ginger_4.png', offset: { left: -1, top: 4 + 2 } },
        { preview: 'assets/avatar/female/hairs/5.png', offset: { left: 1, top: 4 + 2 } },
        { preview: 'assets/avatar/female/hairs/black_5.png', offset: { left: 1, top: 4 + 2 } },
        { preview: 'assets/avatar/female/hairs/blond_5.png', offset: { left: 1, top: 4 + 2 } },
        { preview: 'assets/avatar/female/hairs/ginger_5.png', offset: { left: 1, top: 4 + 2 } },
        { preview: 'assets/avatar/female/hairs/6.png', offset: { left: -2, top: 4 + 2 } },
        { preview: 'assets/avatar/female/hairs/black_6.png', offset: { left: -2, top: 4 + 2 } },
        { preview: 'assets/avatar/female/hairs/blond_6.png', offset: { left: -2, top: 4 + 2 } },
        { preview: 'assets/avatar/female/hairs/ginger_6.png', offset: { left: -2, top: 4 + 2 } },
        { preview: 'assets/avatar/female/hairs/7.png', offset: { left: 0, top: 4 + 2 } },
        { preview: 'assets/avatar/female/hairs/black_7.png', offset: { left: 0, top: 4 + 2 } },
        { preview: 'assets/avatar/female/hairs/blond_7.png', offset: { left: 0, top: 4 + 2 } },
        { preview: 'assets/avatar/female/hairs/ginger_7.png', offset: { left: 0, top: 4 + 2 } },
        { preview: 'assets/avatar/female/hairs/8.png', offset: { left: -2, top: 11 + 2 } },
        { preview: 'assets/avatar/female/hairs/black_8.png', offset: { left: -2, top: 11 + 2 } },
        { preview: 'assets/avatar/female/hairs/blond_8.png', offset: { left: -2, top: 11 + 2 } },
        { preview: 'assets/avatar/female/hairs/ginger_8.png', offset: { left: -2, top: 11 + 2 } },
        { preview: 'assets/avatar/female/hairs/9.png', offset: { left: -2, top: 1 + 2 } },
        { preview: 'assets/avatar/female/hairs/black_9.png', offset: { left: -2, top: 1 + 2 } },
        { preview: 'assets/avatar/female/hairs/blond_9.png', offset: { left: -2, top: 1 + 2 } },
        { preview: 'assets/avatar/female/hairs/ginger_9.png', offset: { left: -2, top: 1 + 2 } },
        { preview: 'assets/avatar/female/hairs/10.png', offset: { left: 0, top: 11 + 2 } },
        { preview: 'assets/avatar/female/hairs/black_10.png', offset: { left: 0, top: 11 + 2 } },
        { preview: 'assets/avatar/female/hairs/blond_10.png', offset: { left: 0, top: 11 + 2 } },
        { preview: 'assets/avatar/female/hairs/ginger_10.png', offset: { left: 0, top: 11 + 2 } },
        { preview: 'assets/avatar/female/hairs/11.png', offset: { left: -13, top: 2 + 2 } },
        { preview: 'assets/avatar/female/hairs/black_11.png', offset: { left: -13, top: 2 + 2 } },
        { preview: 'assets/avatar/female/hairs/blond_11.png', offset: { left: -13, top: 2 + 2 } },
        { preview: 'assets/avatar/female/hairs/ginger_11.png', offset: { left: -13, top: 2 + 2 } },
        { preview: 'assets/avatar/female/hairs/12.png', offset: { left: -5, top: 5 + 2 } },
        { preview: 'assets/avatar/female/hairs/black_12.png', offset: { left: -5, top: 5 + 2 } },
        { preview: 'assets/avatar/female/hairs/blond_12.png', offset: { left: -5, top: 5 + 2 } },
        { preview: 'assets/avatar/female/hairs/ginger_12.png', offset: { left: -5, top: 5 + 2 } },
        { preview: 'assets/avatar/female/hairs/13.png', offset: { left: 0, top: 11 + 2 } },
        { preview: 'assets/avatar/female/hairs/black_13.png', offset: { left: 0, top: 11 + 2 } },
        { preview: 'assets/avatar/female/hairs/blond_13.png', offset: { left: 0, top: 11 + 2 } },
        { preview: 'assets/avatar/female/hairs/ginger_13.png', offset: { left: 0, top: 11 + 2 } },
        { preview: 'assets/avatar/female/hairs/14.png', offset: { left: 2, top: 5 + 2 } },
        { preview: 'assets/avatar/female/hairs/black_14.png', offset: { left: 2, top: 5 + 2 } },
        { preview: 'assets/avatar/female/hairs/blond_14.png', offset: { left: 2, top: 5 + 2 } },
        { preview: 'assets/avatar/female/hairs/ginger_14.png', offset: { left: 2, top: 5 + 2 } },
        { preview: 'assets/avatar/female/hairs/15.png', offset: { left: -2, top: 4 + 2 } },
        { preview: 'assets/avatar/female/hairs/black_15.png', offset: { left: -2, top: 4 + 2 } },
        { preview: 'assets/avatar/female/hairs/blond_15.png', offset: { left: -2, top: 4 + 2 } },
        { preview: 'assets/avatar/female/hairs/ginger_15.png', offset: { left: -2, top: 4 + 2 } },
    ],
    "accesories": [
        //{ left: 0, top: 0 }
        { preview: 'assets/avatar/female/accesories/1.png', type: 'top-head', offset: { top: 3, left: 35 } },
        //{ preview: 'assets/avatar/female/accesories/2.png', offset: { top: 46, left: 38 } },
        { preview: 'assets/avatar/female/accesories/3.png', type: 'glass', offset: { top: 53, left: 42 } },
        { preview: 'assets/avatar/female/accesories/4.png', type: 'glass', offset: { top: 53, left: 42 } },
        { preview: 'assets/avatar/female/accesories/5.png', type: 'glass', offset: { top: 53, left: 42 } },
        { preview: 'assets/avatar/female/accesories/6.png', type: 'glass', offset: { top: 53, left: 42 } },
        { preview: 'assets/avatar/female/accesories/7.png', type: 'glass', offset: { top: 50, left: 37 } },
        { preview: 'assets/avatar/female/accesories/8.png', type: 'glass', offset: { top: 50, left: 37 } },
        { preview: 'assets/avatar/female/accesories/9.png', type: 'glass', offset: { top: 54, left: 38 } },
        { preview: 'assets/avatar/female/accesories/10.png', type: 'top-head', offset: { top: 9, left: 23 } },
        { preview: 'assets/avatar/female/accesories/11.png', type: 'glass', offset: { top: 52, left: 43 } },
        { preview: 'assets/avatar/female/accesories/12.png', type: 'top-head', offset: { top: 18, left: 31 } },
        { preview: 'assets/avatar/female/accesories/13.png', type: 'mouth', offset: { top: 61, left: 70 } },
        { preview: 'assets/avatar/female/accesories/14.png', type: 'glass', offset: { top: 51, left: 39 } },
        { preview: 'assets/avatar/female/accesories/15.png', type: 'top-head', offset: { top: 15, left: 31 } },
        { preview: 'assets/avatar/female/accesories/16.png', type: 'neck', offset: { top: 66, left: 37 } },
        { preview: 'assets/avatar/female/accesories/17.png', type: 'top-head', offset: { top: 11, left: 32 } },
        { preview: 'assets/avatar/female/accesories/18.png', type: 'top-head', offset: { top: 19, left: 40 } },
    ],
    "clothes": [
        { preview: 'assets/avatar/female/clothes/1.png' },
        { preview: 'assets/avatar/female/clothes/2.png' },
        { preview: 'assets/avatar/female/clothes/3.png' },
        { preview: 'assets/avatar/female/clothes/4.png' },
        { preview: 'assets/avatar/female/clothes/5.png', offset: { left: 22, bottom: 1 } },
        { preview: 'assets/avatar/female/clothes/6.png', offset: { left: 22, bottom: 1 } },
        { preview: 'assets/avatar/female/clothes/7.png', offset: { left: 22, bottom: 1 } },
        { preview: 'assets/avatar/female/clothes/8.png', offset: { left: 22, bottom: 1 } },
        { preview: 'assets/avatar/female/clothes/9.png' },
        { preview: 'assets/avatar/female/clothes/10.png' },
        { preview: 'assets/avatar/female/clothes/11.png' },
        { preview: 'assets/avatar/female/clothes/12.png' },
        { preview: 'assets/avatar/female/clothes/13.png' },
        { preview: 'assets/avatar/female/clothes/14.png' },
        { preview: 'assets/avatar/female/clothes/15.png' },
        { preview: 'assets/avatar/female/clothes/16.png' },
        { preview: 'assets/avatar/female/clothes/17.png' },
        { preview: 'assets/avatar/female/clothes/18.png' },
        { preview: 'assets/avatar/female/clothes/19.png' },
        { preview: 'assets/avatar/female/clothes/20.png' },
        { preview: 'assets/avatar/female/clothes/21.png' },
        { preview: 'assets/avatar/female/clothes/22.png' },
    ],
};

const male = {
    "faces": [
        { type: 'white', preview: 'assets/avatar/face/1.png' }, { type: 'darkerWhite', preview: 'assets/avatar/face/2.png' }, { type: 'black', preview: 'assets/avatar/face/3.png' }, { type: 'brown', preview: 'assets/avatar/face/4.png' }
    ],
    'emotions': [
        { preview: 'assets/avatar/emotion/1.png', required: ['black'] },
        { preview: 'assets/avatar/emotion/2.png', required: ['black'] },
        { preview: 'assets/avatar/emotion/3.png', required: ['black'] },
        { preview: 'assets/avatar/emotion/4.png', required: ['black'] },
        { preview: 'assets/avatar/emotion/4.png?flip', required: ['black'], style: { transform: 'scaleX(-1)' } },
        { preview: 'assets/avatar/emotion/6.png', required: ['black'] },
        { preview: 'assets/avatar/emotion/6.png?flip', required: ['black'], style: { transform: 'scaleX(-1)' } },
        { preview: 'assets/avatar/emotion/8.png', required: ['black'] },
        { preview: 'assets/avatar/emotion/8.png?flip', required: ['black'], style: { transform: 'scaleX(-1)' } },
        { preview: 'assets/avatar/emotion/10.png', required: ['black'] },
        { preview: 'assets/avatar/emotion/10.png?flip', required: ['black'], style: { transform: 'scaleX(-1)' } },
        { preview: 'assets/avatar/emotion/brown_1.png', required: ['brown'] },
        { preview: 'assets/avatar/emotion/brown_2.png', required: ['brown'] },
        { preview: 'assets/avatar/emotion/brown_3.png', required: ['brown'] },
        { preview: 'assets/avatar/emotion/brown_4.png', required: ['brown'] },
        { preview: 'assets/avatar/emotion/brown_4.png?flip', required: ['brown'], style: { transform: 'scaleX(-1)' } },
        { preview: 'assets/avatar/emotion/brown_6.png', required: ['brown'] },
        { preview: 'assets/avatar/emotion/brown_6.png?flip', required: ['brown'], style: { transform: 'scaleX(-1)' } },
        { preview: 'assets/avatar/emotion/brown_8.png', required: ['brown'] },
        { preview: 'assets/avatar/emotion/brown_8.png?flip', required: ['brown'], style: { transform: 'scaleX(-1)' } },
        { preview: 'assets/avatar/emotion/brown_10.png', required: ['brown'] },
        { preview: 'assets/avatar/emotion/brown_10.png?flip', required: ['brown'], style: { transform: 'scaleX(-1)' } },
        { preview: 'assets/avatar/emotion/white_1.png', required: ['white', 'darkerWhite'] },
        { preview: 'assets/avatar/emotion/white_2.png', required: ['white', 'darkerWhite'] },
        { preview: 'assets/avatar/emotion/white_3.png', required: ['white', 'darkerWhite'] },
        { preview: 'assets/avatar/emotion/white_4.png', required: ['white', 'darkerWhite'] },
        { preview: 'assets/avatar/emotion/white_4.png?flip', required: ['white', 'darkerWhite'], style: { transform: 'scaleX(-1)' } },
        { preview: 'assets/avatar/emotion/white_6.png', required: ['white', 'darkerWhite'] },
        { preview: 'assets/avatar/emotion/white_6.png?flip', required: ['white', 'darkerWhite'], style: { transform: 'scaleX(-1)' } },
        { preview: 'assets/avatar/emotion/white_8.png', required: ['white', 'darkerWhite'] },
        { preview: 'assets/avatar/emotion/white_8.png?flip', required: ['white', 'darkerWhite'], style: { transform: 'scaleX(-1)' } },
        { preview: 'assets/avatar/emotion/white_10.png', required: ['white', 'darkerWhite'] },
        { preview: 'assets/avatar/emotion/white_10.png?flip', required: ['white', 'darkerWhite'], style: { transform: 'scaleX(-1)' } },
        { preview: 'assets/avatar/emotion/white_11.png', required: ['white', 'darkerWhite'] },
        { preview: 'assets/avatar/emotion/white_12.png', required: ['white', 'darkerWhite'] },
        { preview: 'assets/avatar/emotion/white_13.png', required: ['white', 'darkerWhite'] },
        { preview: 'assets/avatar/emotion/white_14.png', required: ['white', 'darkerWhite'] },
        { preview: 'assets/avatar/emotion/white_15.png', required: ['white', 'darkerWhite'] },
        { preview: 'assets/avatar/emotion/white_16.png', required: ['white', 'darkerWhite'] },
    ],
    "hairs": [
        { preview: 'assets/avatar/hair/1.png' },
        { preview: 'assets/avatar/hair/black_1.png' },
        { preview: 'assets/avatar/hair/blond_1.png' },
        { preview: 'assets/avatar/hair/ginger_2.png' },
        { preview: 'assets/avatar/hair/2.png', offset: { top: 2 } },
        { preview: 'assets/avatar/hair/black_2.png', offset: { top: 2 } },
        { preview: 'assets/avatar/hair/blond_2.png', offset: { top: 2 } },
        { preview: 'assets/avatar/hair/ginger_3.png', offset: { top: 2 } },
        { preview: 'assets/avatar/hair/3.png', offset: { left: 11 } },
        { preview: 'assets/avatar/hair/black_3.png', offset: { left: 11 } },
        { preview: 'assets/avatar/hair/blond_3.png', offset: { left: 11 } },
        { preview: 'assets/avatar/hair/ginger_4.png', offset: { left: 11 } },
        { preview: 'assets/avatar/hair/4.png', offset: { left: 11 } },
        { preview: 'assets/avatar/hair/black_4.png', offset: { left: 11 } },
        { preview: 'assets/avatar/hair/blond_4.png', offset: { left: 11 } },
        { preview: 'assets/avatar/hair/ginger_5.png', offset: { left: 11 } },
        { preview: 'assets/avatar/hair/5.png' },
        { preview: 'assets/avatar/hair/black_5.png' },
        { preview: 'assets/avatar/hair/blond_5.png' },
        { preview: 'assets/avatar/hair/ginger_6.png' },
        { preview: 'assets/avatar/hair/6.png', offset: { left: 11, top: 6 } },
        { preview: 'assets/avatar/hair/black_6.png', offset: { left: 11, top: 6 } },
        { preview: 'assets/avatar/hair/blond_6.png', offset: { left: 11, top: 6 } },
        { preview: 'assets/avatar/hair/ginger_7.png', offset: { left: 11, top: 6 } },
        { preview: 'assets/avatar/hair/7.png', offset: { left: 6 } },
        { preview: 'assets/avatar/hair/black_7.png', offset: { left: 6 } },
        { preview: 'assets/avatar/hair/blond_7.png', offset: { left: 6 } },
        { preview: 'assets/avatar/hair/ginger_8.png', offset: { left: 6 } },
        { preview: 'assets/avatar/hair/8.png', offset: { left: 9 } },
        { preview: 'assets/avatar/hair/black_8.png', offset: { left: 9 } },
        { preview: 'assets/avatar/hair/blond_8.png', offset: { left: 9 } },
        { preview: 'assets/avatar/hair/ginger_9.png', offset: { left: 9 } },
        { preview: 'assets/avatar/hair/9.png', offset: { left: 6, top: -6 } },
        { preview: 'assets/avatar/hair/black_9.png', offset: { left: 6, top: -6 } },
        { preview: 'assets/avatar/hair/blond_9.png', offset: { left: 6, top: -6 } },
        { preview: 'assets/avatar/hair/ginger_10.png', offset: { left: 6, top: -6 } },
        { preview: 'assets/avatar/hair/black_0.png', offset: { top: 14, left: 11 } },
        { preview: 'assets/avatar/hair/blond_0.png', offset: { top: 14, left: 11 } },
        { preview: 'assets/avatar/hair/ginger_1.png', offset: { top: 14, left: 11 } },
        { preview: 'assets/avatar/hair/10.png', offset: { left: 26, top: -12 } },
        { preview: 'assets/avatar/hair/11.png', offset: { left: 26, top: -12 } },
        { preview: 'assets/avatar/hair/black_10.png', offset: { left: 26, top: -12 } },
        { preview: 'assets/avatar/hair/blond_10.png', offset: { left: 26, top: -12 } },
        { preview: 'assets/avatar/hair/ginger_11.png', offset: { left: 26, top: -12 } },
    ],
    "accesories": [
        { preview: 'assets/avatar/accesories/1.png', type: 'top-head', offset: { top: 10, left: 31 } },
        { preview: 'assets/avatar/accesories/2.png', type: 'glass', offset: { top: 46, left: 38 } },
        { preview: 'assets/avatar/accesories/3.png', type: 'glass', offset: { top: 46, left: 40 } },
        { preview: 'assets/avatar/accesories/4.png', type: 'glass', offset: { top: 46, left: 41 } },
        { preview: 'assets/avatar/accesories/5.png', type: 'glass', offset: { top: 46, left: 41 } },
        { preview: 'assets/avatar/accesories/6.png', type: 'glass', offset: { top: 46, left: 41 } },
        { preview: 'assets/avatar/accesories/7.png', type: 'glass', offset: { top: 46, left: 41 } },
        { preview: 'assets/avatar/accesories/8.png', type: 'glass', offset: { top: 46, left: 41 } },
        { preview: 'assets/avatar/accesories/9.png', type: 'glass', offset: { top: 46, left: 43 } },
        { preview: 'assets/avatar/accesories/10.png', type: 'glass', offset: { top: 46, left: 43 } },
        { preview: 'assets/avatar/accesories/11.png', type: 'top-head', offset: { top: 10, left: 24 } },
        { preview: 'assets/avatar/accesories/12.png', type: 'mouth', offset: { top: 56, left: 68 } },
        { preview: 'assets/avatar/accesories/13.png', type: 'top-head', offset: { top: 4, left: 32 } },
        { preview: 'assets/avatar/accesories/14.png', type: 'top-head', offset: { top: 2, left: 29 } },
        { preview: 'assets/avatar/accesories/15.png', type: 'top-head', offset: { top: 12, left: 40 } },
    ],
    "beards": [
        { preview: 'assets/avatar/beard/1.png' },
        { preview: 'assets/avatar/beard/black_1.png' },
        { preview: 'assets/avatar/beard/blond_1.png' },
        { preview: 'assets/avatar/beard/ginger_1.png' },
        { preview: 'assets/avatar/beard/2.png' },
        { preview: 'assets/avatar/beard/black_2.png' },
        { preview: 'assets/avatar/beard/blond_2.png' },
        { preview: 'assets/avatar/beard/ginger_2.png' },
        { preview: 'assets/avatar/beard/3.png', offset: { left: -2 } },
        { preview: 'assets/avatar/beard/black_3.png', offset: { left: -2 } },
        { preview: 'assets/avatar/beard/blond_3.png', offset: { left: -2 } },
        { preview: 'assets/avatar/beard/ginger_3.png', offset: { left: -2 } },
        { preview: 'assets/avatar/beard/4.png', offset: { top: 16, left: 5 } },
        { preview: 'assets/avatar/beard/black_4.png', offset: { top: 16, left: 5 } },
        { preview: 'assets/avatar/beard/blond_4.png', offset: { top: 16, left: 5 } },
        { preview: 'assets/avatar/beard/ginger_4.png', offset: { top: 16, left: 5 } },
        { preview: 'assets/avatar/beard/5.png', offset: { top: 15, left: 6 } },
        { preview: 'assets/avatar/beard/black_5.png', offset: { top: 15, left: 6 } },
        { preview: 'assets/avatar/beard/blond_5.png', offset: { top: 15, left: 6 } },
        { preview: 'assets/avatar/beard/ginger_5.png', offset: { top: 15, left: 6 } },
        { preview: 'assets/avatar/beard/6.png', offset: { top: 1, left: -3 } },
        { preview: 'assets/avatar/beard/black_6.png', offset: { top: 1, left: -3 } },
        { preview: 'assets/avatar/beard/blond_6.png', offset: { top: 1, left: -3 } },
        { preview: 'assets/avatar/beard/ginger_6.png', offset: { top: 1, left: -3 } },
        { preview: 'assets/avatar/beard/7.png', offset: { top: 16, left: 12 } },
        { preview: 'assets/avatar/beard/black_7.png', offset: { top: 16, left: 12 } },
        { preview: 'assets/avatar/beard/blond_7.png', offset: { top: 16, left: 12 } },
        { preview: 'assets/avatar/beard/ginger_7.png', offset: { top: 16, left: 12 } },
        { preview: 'assets/avatar/beard/8.png', offset: { top: 15, left: 2 } },
        { preview: 'assets/avatar/beard/black_8.png', offset: { top: 15, left: 2 } },
        { preview: 'assets/avatar/beard/blond_8.png', offset: { top: 15, left: 2 } },
        { preview: 'assets/avatar/beard/ginger_8.png', offset: { top: 15, left: 2 } },
        { preview: 'assets/avatar/beard/9.png' },
        { preview: 'assets/avatar/beard/black_9.png' },
        { preview: 'assets/avatar/beard/blond_9.png' },
        { preview: 'assets/avatar/beard/ginger_9.png' },
    ],
    "clothes": [
        { preview: 'assets/avatar/clothes/1.png' },
        { preview: 'assets/avatar/clothes/2.png' },
        { preview: 'assets/avatar/clothes/3.png' },
        { preview: 'assets/avatar/clothes/4.png' },
        { preview: 'assets/avatar/clothes/5.png' },
        { preview: 'assets/avatar/clothes/6.png' },
        { preview: 'assets/avatar/clothes/7.png' },
        { preview: 'assets/avatar/clothes/8.png' },
        { preview: 'assets/avatar/clothes/9.png' },
        { preview: 'assets/avatar/clothes/10.png' },
        { preview: 'assets/avatar/clothes/11.png' },
        { preview: 'assets/avatar/clothes/12.png' },
        { preview: 'assets/avatar/clothes/13.png' },
    ],
}

export const data = (() => {
    female.faces.forEach(x => x.offset = { left: 13, top: 8 });
    female.emotions.forEach(x => { if (!x.offset) x.offset = { left: -2, top: 3 + 2 }; });
    female.clothes.forEach(x => { if (!x.required) x.required = ['f_white', 'f_darkerWhite', 'f_black', 'f_brown']; if (!x.offset) x.offset = { left: 13, bottom: 1 }; });
    female.accesories.forEach(x => x.required ? null : x.required = ['f_white', 'f_darkerWhite', 'f_black', 'f_brown']);
    female.hairs.forEach(x => x.required ? null : x.required = ['f_white', 'f_darkerWhite', 'f_black', 'f_brown']);

    male.beards.forEach(x => x.required ? null : x.required = ['white', 'darkerWhite', 'black', 'brown']);
    male.clothes.forEach(x => x.required ? null : x.required = ['white', 'darkerWhite', 'black', 'brown']);
    male.accesories.forEach(x => x.required ? null : x.required = ['white', 'darkerWhite', 'black', 'brown']);
    male.hairs.forEach(x => x.required ? null : x.required = ['white', 'darkerWhite', 'black', 'brown']);

    return {
        header: 'avatar_builder.header',
        categories: [
            {
                id: 'faces', label: 'avatar_builder.faces', zIndex: 0, offset: { left: 15, top: 0 + 20 }, items: [
                    ...male.faces
                    , ...female.faces
                ]
            },
            {
                id: 'emotions', label: 'avatar_builder.emotions', zIndex: 2, offset: { left: 46, top: 20 + 20 }, items: [
                    ...male.emotions,
                    ...female.emotions
                ]
            },
            {
                id: 'hairs', label: 'avatar_builder.hairs', zIndex: 4, offset: { left: 31, top: 0 + 10 }, items: [
                    ...male.hairs,
                    ...female.hairs
                ]
            },
            {
                id: 'accesories', label: 'avatar_builder.accesories', zIndex: 5, offset: { left: 0, top: 0 }, multiple: true, items: [
                    ...male.accesories,
                    ...female.accesories
                ]
            },
            {
                id: 'beards', label: 'avatar_builder.beards', zIndex: 3, offset: { left: 42, top: 32 + 20 }, required: ['white', 'darkerWhite', 'black', 'brown'], items: [
                    ...male.beards
                ]
            },
            {
                id: 'clothes', label: 'avatar_builder.clothes', zIndex: 1, offset: { left: 15, bottom: 0 }, items: [
                    ...male.clothes,
                    ...female.clothes
                ]
            },
        ]
    };
})();