import React, { useEffect, useState } from 'react';
import { Provider } from 'react-redux';
import { Auth0Provider } from "@auth0/auth0-react";

import AppRouter from './routes/AppRouter';
import { AuthProvider } from './components/AuthProvider';
import { getConfig } from './data/api/auth';
import { Configuration } from './data/api/config';
import getStore from './data/store';
import { AgoraHelper } from './utils/AgoraHelper';

const store = getStore();
window._agoraHelper = AgoraHelper();

function App() {
  const [isLoaded, setLoaded] = useState(false);

  useEffect(() => {
    getConfig()
      .then(({ data }) => {
        Configuration.domain = data.domain;
        Configuration.clientId = data.clientId;
        Configuration.audience = data.audience;
        setLoaded(true);
      })
      .catch((e) => {
        console.warn(e);
      });
  }, []);

  return (
    <Provider store={store}>
      {isLoaded &&
        <>
          <Auth0Provider
            domain={Configuration.domain}
            clientId={Configuration.clientId}
            audience={Configuration.audience}
            redirectUri={window.location.origin}
            responseType="id_token"
          >
            <React.Suspense fallback="">
              <AuthProvider>
                <AppRouter />
              </AuthProvider>
            </React.Suspense>
          </Auth0Provider>
        </>
      }
    </Provider>
  );
}

export default App;
