import React from 'react';
import { FormFeedback, FormGroup, Input, Label } from 'reactstrap';
import Select from 'react-select';
import cn from 'classnames';

const feedbackStyle = {display: "block"};

const RFInput = (
    {
        field: { ...fields },
        form: { touched, errors, ...rest },
        ...props
    }) => (
        <FormGroup>
            {props.label && <Label for={props.id} className={cn(props.labelstyle, 'label-color')}>{props.label}</Label>}
            <Input {...props} {...fields} invalid={Boolean(touched[fields.name] && errors[fields.name])}/>
            {touched[fields.name] && errors[fields.name] ? <FormFeedback style={feedbackStyle}>{errors[fields.name]}</FormFeedback> : ''}
        </FormGroup>
    );

const RFCheckbox = (
    {
        field: { ...fields },
        form: { touched, errors, ...rest },
        ...props
    }) => (
        <div className="checkbox checkbox-dark" style={{ marginLeft: '1.25rem' }}>
            <Input {...props} {...fields} />
            <Label for={props.id} className={'label-color'}>{props.label}</Label>
            {touched[fields.name] && errors[fields.name] ? <FormFeedback style={feedbackStyle}>{errors[fields.name]}</FormFeedback> : ''}
        </div>
    );

const RFMultiCheckbox = (
    {
        field,
        form,
        options,
        ...props
    }) => {
    const onChange = (option) => {
        form.setFieldValue(
            field.name,
            (option || []).map(item => item.value)
        );
    };

    const getValue = () => {
        if (options) {
            return options.filter(option => field.value.indexOf(option.value) >= 0)
        } else {
            return [];
        }
    };

    return (
        <div>
            <Label for={props.id} className={'label-color'}>{props.label}</Label>
            <Select
                name={field.name}
                value={getValue()}
                onChange={onChange}
                options={options}
                isMulti={true}
            />
            {form.touched[field.name] && form.errors[field.name] ? <FormFeedback style={feedbackStyle}>{form.errors[field.name]}</FormFeedback> : ''}
        </div>
    )
};

export { RFInput, RFCheckbox, RFMultiCheckbox };