export const EventType = {
    USER: {
        ONLINE: "user-online",
        OFFLINE: "user-offline",
        STATE: "user-state"
    },
    ROOM: {
        CALL_STARTED: "room-call-started",
        CALL_ENDED: "room-call-ended"
    },
    PING_USERS: "ping_users"
}

export const PermissionEnum = {
    AdministerServer: 0b1000000000,
    DeleteOrganization: 0b0100000000,
    ManageOrganization: 0b0010000000,
    ManageTeams: 0b0001000000,
    DeleteTeam: 0b0000100000,
    ManageTeamRooms: 0b0000010000,
    ManageTeamUsers: 0b0000001000,
    SendInviteToTeam: 0b0000000100,
    SendInviteToRoom: 0b0000000010,
    Participate: 0b0000000001,
    Guest: 0b0000000000
}

export const RoutePaths = {
    Root: '/',
    UserProfile: '/user',
    PublicSpace: '/management/public-space',
    MembersManagement: '/management/team-members',
    MemberInvite: '/management/invite',
    Invite: '/invite',
    Auth: '/auth',
    Team: '/team',
  }

  
export const RoomMenuTypes = {
    MEMBER_ROOM_INVITE: 1,
    GUEST_ROOM_INVITE: 2,
    PRIVATE_CHAT: 3,
};