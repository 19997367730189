import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Col, Row, Button, Modal, ModalBody, ModalFooter, Media, ModalHeader } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { HubConnectorContext } from '../../data/context/HubConnector';
import css from './modals.module.scss';
import cn from 'classnames';

const autoRejectTimeout = 30;
let _rejectTimeout = autoRejectTimeout;

function KnockRequestModal({ request, onComplete }) {
    const { t } = useTranslation();
    const { chat } = useContext(HubConnectorContext);
    const user = useSelector(state => state.user);
    const isGuest = request.type === "invitation";
    const [rejectTimeout, setRejectTimeout] = useState(autoRejectTimeout);

    useEffect(() => {
        _rejectTimeout = autoRejectTimeout;

        const timeout = setInterval(() => {
            _rejectTimeout--;

            if (_rejectTimeout < 0) {
                clearInterval(timeout);
                onComplete(request);
                chat.knockAnswer(`${t('knock_modal.answer_from', { username: user.name })}: ${t('knock_modal.later_msg')}`, []);
            } else {
                setRejectTimeout(_rejectTimeout);
            }
        }, 1000);

        return () => {
            clearInterval(timeout);
        }
    }, []);

    const renderFooter = () => {
        if (isGuest) {
            return (
                <Row className='w-100'>
                    <Col className='col-6'>
                        <Button className='text-nowrap btn-block' color='success' size='lg' autoFocus onClick={() => { onComplete(request); chat.knockAnswer(`${t('knock_modal.answer_from', { username: user.name })}: ${t('knock_modal.accept_msg')}`, [request.userId]); }}>{t('knock_modal.guest_accept_btn')}</Button>
                    </Col>
                    <Col className='col-6'>
                        <Button className='text-nowrap btn-block' color='light' size='lg' onClick={() => { onComplete(request); chat.knockAnswer(`${t('knock_modal.answer_from', { username: user.name })}: ${t('knock_modal.later_msg')}`, []); }}>{t('knock_modal.guest_later_btn')}</Button>
                    </Col>
                </Row>
            )
        }

        return (
            <Row>
                <Col>
                    <Button className='text-nowrap btn-block mb-3' color='success' size='lg' autoFocus onClick={() => { onComplete(request); chat.knockAnswer(`${t('knock_modal.answer_from', { username: user.name })}: ${t('knock_modal.accept_msg')}`, [request.userId]); }}>{t('knock_modal.accept_btn')}</Button>
                </Col>
                <Col>
                    <Button className='text-nowrap btn-block mb-3' color='primary' size='lg' onClick={() => { onComplete(request); chat.knockAnswer(`${t('knock_modal.answer_from', { username: user.name })}: ${t('knock_modal.later_msg')}`, []); }}>{t('knock_modal.later_btn')}</Button>
                </Col>
                <Col>
                    <Button className='text-nowrap btn-block' color='secondary' size='lg' onClick={() => { onComplete(request); chat.knockAnswer(`${t('knock_modal.answer_from', { username: user.name })}: ${t('knock_modal.reject_msg')}`, []); }}>{t('knock_modal.reject_btn')}</Button>
                </Col>
            </Row>
        );
    }

    return <Modal isOpen={true}>
        {isGuest && <ModalHeader className={css.modalHeader}>{t('knock_modal.guest_knock')}</ModalHeader>}
        <ModalBody>
            <p className={cn(css.bodyText, 'mb-3')}>{t('knock_modal.guest_knock_text', {name: request.userName})}</p>
            <div className='text-center'>
                <div>
                    <Media body className='rounded-circle' style={{ width: 60, height: 60 }} src={request.userPic} alt={request.name} />
                </div>
                <span style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', fontWeight: 500, fontSize: 16 }}>{request.userName}</span>
            </div>
        </ModalBody>
        <ModalFooter className='justify-content-around'>
            {renderFooter()}
        </ModalFooter>
        <div style={{ backgroundColor: 'red', height: 4, width: Math.floor((rejectTimeout / autoRejectTimeout) * 100) + '%', transition: 'width 1s', transitionTimingFunction: 'linear' }}></div>
    </Modal>
}

export default KnockRequestModal;
