export const Configuration = {
    agoraAppId: "f30d70cf5ef645198e73184be37170b9", 
    basePath: "https://staging.teamblaze.io",
    tos: "https://teamblaze.io/tos/",
    policy: "https://teamblaze.io/privacy-policy/",
    build: "11682",

    awayDelayMs: 120000, //2 min
}

