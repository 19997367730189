import React from 'react';
import { useSelector } from 'react-redux';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

import { Button, FormGroup, ModalFooter } from 'reactstrap';

import { postInvitationsApi } from './../../data/api/invite';
import RFDatePicker from './../common/RFDatePicker';
import { RFInput } from '../common/RFInput';
import css from './../common/common.module.scss';

const selectRoleData = state => state.user.role;

function GuestRoomInviteForm({ roomId, onSuccess, submitRef }) {
    const { t } = useTranslation();
    const { teamId, teamName } = useSelector(selectRoleData);

    return (
        <Formik
            initialValues={{
                team: teamName || '',
                email: '',
                expiresAt: new Date((new Date()).setDate(new Date().getDate() + 1))
            }}
            validationSchema={Yup.object({
                expiresAt: Yup.date().required()
            })}
            onSubmit={async (values, { setSubmitting }) => {
                if (!teamId) {
                    console.warn('Empty teamId');
                    return;
                }

                setSubmitting(true);

                const { expiresAt, email } = values;
                const parsedEmail = email.trim();

                postInvitationsApi(
                    {
                        teamId,
                        publicRooms: [roomId],
                        email: parsedEmail.length > 0 ? [parsedEmail] : null,
                        expiresAt: new Date(expiresAt).toJSON(),
                        isMemberInvite: false,
                        isPublic: false,
                        publicRoomSettings: {
                            showAvatars: true,
                            showMembersDetails: true,
                            roomChatEnabled: true,
                            voiceCallEnabled: true,
                            videoCallEnabled: true
                        }
                    })
                    .then((resp) => {
                        if (!onSuccess)
                            return;

                        onSuccess(resp.data);
                    })
                    .catch(console.warn);

                setSubmitting(false);
            }}
        >
            {({ submitForm, isSubmitting }) => {
                submitRef(submitForm, isSubmitting);
                return (
                    <Form>
                        <FormGroup>
                            <Field
                                component={RFInput}
                                name='email'
                                type='text'
                                label={t('guest_room_invite_modal.guest_email_label')}
                                placeholder={t('guest_room_invite_modal.guest_email_placeholder')}
                                labelstyle={css.inputText}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Field
                                component={RFDatePicker}
                                name='expiresAt'
                                label={t('team_management_page.create_invite_form.label_expiresAt')}
                                showTimeSelect
                                dateFormat="MMMM d, yyyy HH:mm"
                                timeFormat="HH:mm"
                                className="form-control digits"
                                labelstyle={css.inputText}
                            />
                        </FormGroup>
                        {/* <ModalFooter>
                        <Button
                            color='success'
                            disabled={isSubmitting}
                            onClick={submitForm}
                        >
                            {t('common.btn.submit')}
                        </Button>
                    </ModalFooter> */}
                    </Form>
                )
            }
            }
        </Formik>
    );
}

export default GuestRoomInviteForm;
