import notify_success from '../assets/audio/n1.mp3';
import notify_msg from '../assets/audio/n2.mp3';
import notify_warn from '../assets/audio/n3.mp3';
import knock_request from '../assets/audio/knock_notify.mp3';
import chat_new_msg from '../assets/audio/chat_new_msg.mp3';
import door_open from '../assets/audio/door_open.mp3';
import door_close from '../assets/audio/door_close.mp3';
import door_knock from '../assets/audio/door_knock.mp3';

const snd_success = new Audio(notify_success);
const snd_msg = new Audio(notify_msg);
const snd_warn = new Audio(notify_warn);
const snd_knock_request = new Audio(knock_request);
const snd_chat_new_msg = new Audio(chat_new_msg);
const snd_door_open = new Audio(door_open);
const snd_door_close = new Audio(door_close);
const snd_door_knock = new Audio(door_knock);

export const play = (audio, loop = false, volume = 0.5) => {
    if (!audio.paused)
        return;

    audio.loop = loop;
    audio.volume = volume;
    audio.play();
}

export const stop = (audio) => {
    if (audio.paused)
        return;

    audio.pause();
}

export const Sounds = {
    snd_success,
    snd_msg,
    snd_warn,
    snd_knock_request,
    snd_chat_new_msg,
    snd_door_open,
    snd_door_close,
    snd_door_knock,
}

