import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import MediaPlayer from '../MediaPlayer';
import { Row, Col, Media, Card, CardBody } from 'reactstrap';
import { Maximize, Minimize } from 'react-feather';
import { agoraDevicesSelector, agoraRemoteUsersSelector, agoraTracksSelector, appSettingsSelector } from '../../data/store/selectors/appSelectors';
import { checkDevice } from '../../hooks/useAgora';
import { receiveAgoraDevices } from '../../data/store/actions/AppActions';

function VideoCallGrid({ setAgoraClientEncoder }) {
    const rooms = useSelector(state => state.team.rooms);
    const [cols, toogleMaximaze] = useState({});
    const dispatch = useDispatch();

    const remoteUsers = useSelector(agoraRemoteUsersSelector);
    const tracks = useSelector(agoraTracksSelector);
    const devices = useSelector(agoraDevicesSelector);
    const appSettings = useSelector(appSettingsSelector);

    const calcRect = (user) => {
        const style = { width: 176, height: 140, marginBottom: 0, marginTop: 15 };

        if (!cols[user.uid] || !user.videoTrack) {
            if (cols[user.uid]) {
                toogleMaximaze({ ...cols, [user.uid]: false });
                setAgoraClientEncoder(user.uid, 1);
            }

            return style;
        }

        const x = document.getElementById('call-user-grid');

        style.width = x.offsetWidth - 30;
        style.height = style.width / 1.77;

        return style;
    }

    useEffect(() => {
        if (!devices.outputDeviceId) return;

        checkDevice(devices.output)
            .then((device)=>{
                if(!device){
                    dispatch(receiveAgoraDevices({ ouput: null, ouputDeviceId: null }));
                    return;
                }

                remoteUsers.forEach(u => {
                    u.audioTrack && u.audioTrack.setPlaybackDevice(devices.outputDeviceId);
                });
            })
            .catch(() => { });
    }, [devices.outputDeviceId, remoteUsers])

    return (
        <>
            {remoteUsers.map(user => {
                const style = calcRect(user);
                var u = null;
                rooms.find(r => r.participants.find(p => { if (p.id === user.uid) { u = p; return true; }; return false; }));

                return (
                    <Col key={user.uid} className={`${cols[user.uid] ? 'order-last order-md-last order-xl-last col-12' : 'col-auto'}`}>
                        <Card style={style}>
                            <CardBody className='p-0'>
                                {user.videoTrack &&
                                    <div className='position-absolute' style={{ right: 0, top: 0, zIndex: 1 }}>
                                        <div className="bg-white" style={{ padding: 2, borderRadius: 8, cursor: 'pointer', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                                            onClick={() => {
                                                setAgoraClientEncoder(user.uid, cols[user.uid] ? 1 : 0);
                                                toogleMaximaze({ ...cols, [user.uid]: cols[user.uid] != null ? !cols[user.uid] : true });
                                            }}
                                        >
                                            {cols[user.uid] ? <Minimize /> : <Maximize />}
                                        </div>
                                        {/* <ul className='nav-menus'>
                                        <li className="bg-white" style={{ borderRadius: 15 }}>
                                            <a className={"nav-link"} href='#' >
                                                {cols[user.uid] ? <Minimize /> : <Maximize />}
                                            </a>
                                        </li>
                                    </ul> */}
                                    </div>
                                }
                                <MediaPlayer
                                    videoTrack={user.videoTrack}
                                    audioTrack={user.audioTrack}
                                    mute={!appSettings.audio}
                                    style={{
                                        //position: user.videoTrack ? 'absolute' : 'initial',
                                        width: style.width,
                                        height: style.height,
                                    }}
                                >
                                    {u && !user.videoTrack && u.picture &&
                                        <div style={{ inset: 0, width: 130, height: 130 }}>
                                            <Media body className="position-absolute rounded-circle m-auto" style={{ inset: 0, objectFit: 'cover' }} src={u.picture} alt={u.name} />
                                        </div>
                                    }
                                </MediaPlayer>
                            </CardBody>
                        </Card>
                    </Col>
                );
            })}
        </>
    );
}

export default VideoCallGrid;
