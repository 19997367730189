import { ChatMessageType } from "../data/store/actions/ChatActions"

export const isSystemMessage = (message) => {
    return message.messageType < ChatMessageType.html;
}

export const isSystemMessageWithSenderInfo = (message) => {
    return message.messageType === ChatMessageType.systemInfo
        || message.messageType === ChatMessageType.systemWarn
        || message.messageType === ChatMessageType.systemError;
}

export const isMySystemMessage = (userId, message) => {
    return isSystemMessage(message) && userId === message.senderId;
}