import axios from "axios";
import { Configuration } from './../config';

export function getTeamRoomsApi(teamId) {
    return axios.get(`${Configuration.basePath}/api/chat/team/${teamId}/rooms`);
}

export function getTeamsApi() {
    return axios.get(`${Configuration.basePath}/api/teams`);
}

export function putTeamApi(data) {
    return axios.put(`${Configuration.basePath}/api/teams`, data);
}

export function getCurrentPositionInTeamApi() {
    return axios.get(`${Configuration.basePath}/api/teams/position`);
}

export function getTeamMembersApi(teamId) {
    return axios.get(`${Configuration.basePath}/api/teams/${teamId}/members`);
}

export function removeTeamMemberApi(teamId, userId) {
    return axios.delete(`${Configuration.basePath}/api/teams/${teamId}/members/${userId}`);
}