import React, { useEffect, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { HubConnectorContext } from '../../data/context/HubConnector';

import { asyncRequestStarted, AsyncRequestType } from '../../data/store/actions/AppActions';
import Loader from '../common/Loader';
import css from './modals.module.scss';
import cn from 'classnames';
import get from 'lodash/get';
import { getUserName } from '../../utils/UserHelper';
import { AuthContext } from '../AuthProvider';

function GuestKnockModal({ room, onClose }) {
    const { t } = useTranslation();
    const ctx = useContext(AuthContext);
    const { chat } = useContext(HubConnectorContext);
    const inProgress = useSelector(state => state.app.inProgress);
    const user = useSelector(state => state.user);
    const team = useSelector(state => state.team);
    const dispatch = useDispatch();

    useEffect(() => {
        join();
    }, []);

    useEffect(() => {
        const knock = inProgress[AsyncRequestType.knock];

        if (!knock || !knock.complete)
            return;

        if (knock.data.invited)
            onClose();
    }, [inProgress]);

    const join = () => {
        if (!room) {
            console.warn('Room not found');
            return;
        }

        chat.knockToRoom(room.id, "");
        dispatch(asyncRequestStarted({ type: AsyncRequestType.knock, complete: false }));
    }

    const sendKnockRequest = () => {
        join();
    };

    const knock = inProgress[AsyncRequestType.knock];
    const onLoad = knock && !knock.complete;

    return (
        <Modal isOpen={true}>
            <ModalHeader className={css.modalHeader}>
                {onLoad
                    ? t('guest_knock_modal.welcome', { name: getUserName(user) })
                    : t('guest_knock_modal.header', { name: get(knock, 'data.userName', 'User') })
                }
            </ModalHeader>
            <ModalBody>
                {onLoad
                    ? <div className='mb-4'>
                        <p className={cn(css.bodyText, 'mb-2')}>
                            {t('guest_knock_modal.knock_in_progress', { team: team.teams.find(t => t.id === team.currentTeamId).name })}
                        </p>
                        <Loader />
                    </div>
                    : <div>
                        <p className={cn(css.bodyText, 'mb-3')}>{t('guest_knock_modal.fail_description', { name: get(knock, 'data.userName', 'User') })}</p>
                        <p className={cn(css.bodyText, 'mb-3')}>{t('guest_knock_modal.fail_rejoin', { expire: '' })}</p>
                    </div>
                }
            </ModalBody>
            <ModalFooter className='justify-content-around'>
                <Row className='w-100 justify-content-end'>
                    {!onLoad &&
                        <Col className='col-6'>
                            <Button className='text-nowrap btn-block' color='success' size='lg' autoFocus onClick={sendKnockRequest}>
                                {t('guest_knock_modal.knock_btn')}
                            </Button>
                        </Col>
                    }
                    <Col className='col-6'>
                        <Button className='text-nowrap btn-block' color='secondary' size='lg' onClick={() => { ctx.logout(); }}>
                            {t('guest_knock_modal.leave_btn')}
                        </Button>
                    </Col>
                </Row>
            </ModalFooter>
        </Modal>
    );
}

export default GuestKnockModal;
