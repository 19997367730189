import { isSystemMessage } from '../../../utils/ChatHelper';
import { CLEAR_TEAM_DATA, RECEIVE_USER_STATUS } from '../actions/TeamActions';
import { RESET } from './../actions';
import { RECEIVE_ROOM_CHAT_MESSAGE, STARTED_PRIVATE_CHAT, STARTED_ROOM_CHAT, FOCUS_ON_CHAT, RECEIVE_CHAT_HISTORY, MARK_AS_READ, TOGGLE_ROOM_CHAT, CHANGE_ROOM_CHAT, RECEIVE_PRIVATE_CHAT_HISTORY } from './../actions/ChatActions';

const initialState = {
  rooms: [],
  focus: null,
  roomChatIsOpen: false,
  currentRoomId: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case RESET:
      return initialState;
    case FOCUS_ON_CHAT:
      return {
        ...state,
        focus: {
          roomId: action.payload.id
        }
      }
    case CHANGE_ROOM_CHAT:
      return {
        ...state,
        currentRoomId: action.payload.roomId
      }
    case MARK_AS_READ:
      return {
        ...state,
        rooms: state.rooms.map(r =>
          r.roomId === action.payload.roomId
            ? {
              ...r,
              hasNewMsg: false,
              unreadMsgCount: 0,
            }
            : r
        )
      }
    case RECEIVE_ROOM_CHAT_MESSAGE: {
      const room = state.rooms.find(r => r.roomId === action.payload.message.roomId);
      const hasNewMsg = action.payload.user.id !== action.payload.message.senderId
        && !isSystemMessage(action.payload.message);

      if (!room)
        return {
          ...state,
          rooms: [
            ...state.rooms,
            {
              roomId: action.payload.message.roomId,
              messages: [action.payload],
              participants: [{ id: action.payload.message.senderId, name: action.payload.message.senderName, picture: action.payload.message.senderPic }],
              isRoomChat: action.payload.isTeamRoomMessage,
              unreadMsgCount: hasNewMsg ? 1 : 0,
              hasNewMsg, //ignore notify about system msg
            }
          ]
        };

      return {
        ...state,
        rooms: state.rooms.map(r =>
          (r.roomId === action.payload.message.roomId && !r.messages.find(m => m.id === action.payload.message.id))
            ? {
              ...r,
              messages: [...r.messages, action.payload],
              unreadMsgCount: r.unreadMsgCount + (hasNewMsg ? 1 : 0),
              hasNewMsg, //ignore notify about system msg
            }
            : r
        )
      };
    }
    case STARTED_PRIVATE_CHAT: {
      const room = state.rooms.find(r => r.roomId === action.payload.id);
      if (room)
        return state;

      return {
        ...state,
        rooms: [...state.rooms, { roomId: action.payload.id, messages: [], participants: action.payload.participants }]
      };
    }
    case STARTED_ROOM_CHAT:
      const room = state.rooms.find(r => r.roomId === action.payload.roomId);

      return {
        ...state,
        rooms: [
          room ? { ...room, isRoomChat: true } : { roomId: action.payload.roomId, messages: [], isRoomChat: true }
          , ...state.rooms.filter(r => r.roomId !== action.payload.roomId && !r.isRoomChat)
        ]
      };
    case RECEIVE_CHAT_HISTORY:
      return {
        ...state,
        rooms: state.rooms.map(r => r.roomId === action.payload[0].roomId
          ? { ...r, messages: [...action.payload.filter(x => !r.messages.find(m => m.message.id === x.id)).map(x => ({ message: x })), ...r.messages] }
          : r
        )
      }
    case RECEIVE_PRIVATE_CHAT_HISTORY:
      return {
        ...state,
        rooms: action.payload.map(x => {
          let r = state.rooms.find(r => r.roomId === x.id);

          if (r) return r;

          return ({
            roomId: x.id,
            messages: [],
            participants: x.participants,
            unreadMsgCount: x.newMessagesCount,
            hasNewMsg: x.newMessagesCount > 0,
          })
        })
      }
    case RECEIVE_USER_STATUS: {
      const { userId, status } = action.payload;

      return {
        ...state,
        rooms: state.rooms.map(r => {
          if (r.participants && r.participants.find(p => p.id === userId)) {
            return {
              ...r,
              participants: r.participants.map(x => x.id === userId ? { ...x, ...status } : x)
            }
          }

          return r;
        })
      }
    }
    case TOGGLE_ROOM_CHAT: {
      return {
        ...state,
        roomChatIsOpen: action.payload.isOpen
      }
    }
    case CLEAR_TEAM_DATA: {
      return initialState;
    }
    default:
      return state;
  }
}