import React, { useState, useRef } from 'react';
import { Col, Row, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import CreateInviteForm from '../Invites/CreateInviteForm';

import css from './modals.module.scss';
import cn from 'classnames';

function InviteTeammateModal({ isOpen, onClose }) {
    const [inviteId, setInviteId] = useState(null);
    const [emails, setInviteEmails] = useState(null);

    const inviteUrlField = useRef(null);
    const { t } = useTranslation();

    const handleCopyAndClose = () => {
        if (inviteUrlField)
            inviteUrlField.current.focus();

        let range = document.createRange();
        range.selectNodeContents(inviteUrlField.current);

        let sel = window.getSelection();
        sel.removeAllRanges();
        sel.addRange(range);

        document.execCommand('copy');

        setInviteId(null);
        onClose();
    };

    return (
        <Modal isOpen={isOpen} toggle={onClose} >
            <ModalHeader className={css.modalHeader} toggle={onClose}>
                {inviteId ? t('invite_created_modal.header') : t('invite_created_modal.invite_teammate_header')}
            </ModalHeader>
            <ModalBody className={css.modalWrapper}>
                {inviteId &&
                    <>
                        <div>{t('invite_created_modal.emails')}</div>
                        <div className={cn(css.inviteEmails, 'mb-4')}>{emails}</div>
                        <div>{t('invite_created_modal.link')}</div>
                        <div ref={inviteUrlField}>
                            <a href={`${window.location.origin}/invite/${inviteId}`}>{`${window.location.origin}/invite/${inviteId}`}</a>
                        </div>
                    </>
                }
                {!inviteId &&
                    <CreateInviteForm
                        onSuccess={(invite) => { setInviteId(invite.id); setInviteEmails(invite.email); }}
                        onClose={onClose}
                    />
                }
            </ModalBody>
            {inviteId &&
                <ModalFooter>
                    <Button color='primary' autoFocus onClick={handleCopyAndClose}>{t('invite_created_modal.copy_and_close_btn')}</Button>
                </ModalFooter>
            }
        </Modal>
    );
}

export default InviteTeammateModal;