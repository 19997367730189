import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Container, Card, CardBody, Row, Col } from 'reactstrap';
import { ArrowDown, ArrowUp, ChevronLeft, Mail } from 'react-feather';

import ChatBox from "./ChatBox";
import ChatsList from './ChatsList';
import Blink from '../common/Blink';
import { currentRoomIdSelector } from '../../data/store/selectors/chatSelectors';
import { getChatHistory, getPrivateChatHistory } from '../../data/store/actions/ChatActions';
import { currentTeamIdSelector } from '../TeamArea/selectors';
import sortBy from 'lodash/sortBy';

function PrivateChat({ contStyle }) {
    const user = useSelector(state => state.user);
    const { rooms: chatRooms, focus: chatFocus } = useSelector(state => state.chat);
    const { rooms } = useSelector(state => state.team);
    const currentRoomId = useSelector(currentRoomIdSelector);
    const currentTeamId = useSelector(currentTeamIdSelector);

    const [isMinimazed, minimaze] = useState(true);
    const [chatRoom, selectRoom] = useState({});
    const [roomsList, setRoomsList] = useState([]);

    const { t } = useTranslation();
    const dispatch = useDispatch();

    useEffect(() => {
        if (!chatFocus)
            return;

        const room = roomsList.find(r => r.id === chatFocus.roomId);
        if (!room)
            return;

        minimaze(false);
        selectRoom(room);
    }, [chatFocus]);

    useEffect(() => {
        const room = rooms.find(r => r.id === currentRoomId);

        if (!room)
            return;

        setRoomsList(
            //sortBy(
                chatRooms
                    .filter(cr => !cr.isRoomChat)
                    .map(cr => ({ ...cr, id: cr.roomId, name: getPrivateRoomName(cr) }))
            //    , ['hasNewMsg', '']
            //).reverse()
        );
    }, [rooms, chatRooms, currentRoomId]);

    useEffect(() => {
        if (chatRoom.id || roomsList.length === 0)
            return;

        selectRoom(roomsList[0]);
    }, [roomsList, chatRoom]);

    useEffect(() => {
        if (!chatRoom.id)
            return;

        dispatch(getChatHistory(chatRoom.id, (new Date()).setDate(new Date().getDate() - 3), Date.now()));
    }, [chatRoom]);

    useEffect(() => {
        if (!currentTeamId) return;

        dispatch(getPrivateChatHistory(currentTeamId));
    }, [currentTeamId]);

    const getPrivateRoomName = (room) => {
        if (!room.participants)
            return room.id;

        const p = room.participants.find(p => p.id !== user.id);

        if (!p)
            return room.id;

        return p.name;
    }

    const renderContent = () => {
        if (isMinimazed)
            return;

        // if (chatRoom && chatRoom.id)
        //     return (
        //         <ChatBox
        //             roomId={chatRoom.id}
        //             //messages={chatRoom.messages}
        //             participant={chatRoom.participants ? chatRoom.participants.find(p => p.id !== user.id) : null}
        //             maxHeight={380}
        //             emoteContStyle={{ bottom: 50, right: 8 }}
        //         />
        //     );

        return (
            <div className='d-flex'>
                <ChatsList
                    roomId={chatRoom?.id}
                    rooms={roomsList}
                    onChatSelect={(r) => {
                        selectRoom(r);
                    }}
                    contStyle={{ width: 300, paddingRight: 12, maxHeight: 380 }}
                />
                {chatRoom && chatRoom.id &&
                    <div className='pl-2' style={{ width: 400, boxShadow: '-8px 14px 10px rgba(0, 0, 0, 0.02)', zIndex: 1 }}>
                        <ChatBox
                            roomId={chatRoom.id}
                            //messages={chatRoom.messages}
                            participant={chatRoom.participants ? chatRoom.participants.find(p => p.id !== user.id) : null}
                            maxHeight={380}
                            emoteContStyle={{ bottom: 50, right: 8 }}
                        />
                    </div>
                }
            </div>
        );
    }

    const hasNewMsg = roomsList.find(rl => rl.hasNewMsg);

    if (roomsList.length === 0)
        return <></>;

    return (
        <Container className="hiding-private-chat" style={{ position: 'fixed', bottom: 0, right: 0, paddingRight: 0, paddingLeft: 0, width: 400 + 300, zIndex: 200, ...contStyle }}>
            <Container>
                <Col className="pr-0 pl-0 mb-0">
                    <Card className='mb-0'>
                        <CardBody className="p-0">
                            <Row className="mr-0 ml-0">
                                <Col className="pb-1 pt-3">
                                    <div>
                                        <div className='clearfix' role='button' onClick={() => { minimaze(!isMinimazed); }}>
                                            <ul className='list-inline d-flex flex-row justify-content-between align-items-center'>
                                                {/* {!isMinimazed && chatRoom && chatRoom.id &&
                                                    <a href={null} role='button' onClick={(e) => { e.stopPropagation(); selectRoom({}); }}>
                                                        <ChevronLeft />
                                                    </a>
                                                } */}
                                                <li className='mr-auto'>
                                                    <span className='font-weight-bold text-uppercase' style={{
                                                        whiteSpace: 'nowrap',
                                                        textOverflow: 'ellipsis',
                                                        overflow: 'hidden',
                                                        display: 'inline-block'
                                                    }}>{t('chat.title', { title: chatRoom.id ? chatRoom.name + ' ' : '' })}</span>
                                                </li>
                                                <li>
                                                    {hasNewMsg && <Blink><Mail className='mr-2' /></Blink>}
                                                    <a href={null} role='button'>
                                                        {isMinimazed ? <ArrowUp /> : <ArrowDown />}
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                        {renderContent()}
                                    </div>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Container>
        </Container>
    );
}

export default PrivateChat;
