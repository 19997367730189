import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';

import { UserReducer, OrganizationReducer, ErrorReducer, TeamReducer, ChatReducer, AppReducer } from './reducers';

export default function (initialState = {}) {
    const rootReducer = combineReducers({
        app: AppReducer,
        user: UserReducer,
        organizations: OrganizationReducer,
        errors: ErrorReducer,
        team: TeamReducer,
        chat: ChatReducer
    });

    return createStore(rootReducer, initialState, applyMiddleware(thunk));
}