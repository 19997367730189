import React, { Fragment, useState, useEffect, useContext } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import { LogIn, User } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { Button } from 'reactstrap';

import { AuthContext } from './../../components/AuthProvider';
import { RoutePaths } from '../../data/contants';
import { getUserName } from '../../utils/UserHelper';

const Rightbar = (props) => {
  const ctx = useContext(AuthContext);
  const history = useHistory();
  const user = useSelector(state => state.user);
  const { t } = useTranslation();

  return (
    <Fragment>
      <div className="nav-right col-auto pull-right right-header p-0">
        <ul className="nav-menus">
          <Button
            className='btn-room-secondary text-white mh-100 mr-2 btn-beta'
            style={{ fontSize: 12 }}
            href='https://docs.google.com/forms/d/1YHfohlM7Y87QZdZNLIBhTa5FZlyyRbkrwvwxaFHekms/edit'
            target='_blank'
          >
            {t('common.btn.beta')}
          </Button>
          {user &&
            <li className="profile-nav onhover-dropdown p-0">
              <div className="media profile-media align-items-center">
                <div className="media-body m-r-10"><span>{getUserName(user)}</span>
                  {/* <p className="mb-0 font-roboto">{Admin} <i className="middle fa fa-angle-down"></i></p> */}
                </div>
                <img className="b-r-10 img-cover-fit" src={user.picture} alt="" />
              </div>
              <ul className="profile-dropdown onhover-show-div display-x-small">
                <li onClick={() => { history.push(RoutePaths.UserProfile) }}><User /><span>{t('menu.myprofile')} </span></li>
                <li onClick={() => { ctx.logout(); }}><LogIn /><span>{t('menu.logout')}</span></li>
              </ul>
            </li>
          }
        </ul>
      </div>
    </Fragment>

  );
}
export default Rightbar;