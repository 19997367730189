import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container } from 'reactstrap';
import { Mic, Volume2, Video, Settings, Tool } from 'react-feather';
import { ModalType, receiveMediaInfo, toggleModal } from '../../data/store/actions/AppActions';

function MediaControl() {
    const appSettings = useSelector(state => state.app.media);
    const dispatch = useDispatch();

    const toggleMute = () => {
        dispatch(receiveMediaInfo({ mic: !appSettings.mic }));
    }

    const toggleCam = () => {
        dispatch(receiveMediaInfo({ cam: !appSettings.cam }));
    }

    const toggleAudio = () => {
        dispatch(receiveMediaInfo({ audio: !appSettings.audio }));
    }

    const toggleSettings = () => {
        dispatch(toggleModal({ type: ModalType.devices, isOpen: true }));
    }

    const toggleMediaMenu = () => {
        dispatch(toggleModal({ type: ModalType.mediaMenu, isOpen: true }));
    }

    return (
        <Container>
            <ul>
                <li className="level-menu">
                    <a className={appSettings.mic ? "nav-link active" : "nav-link"} href={null} role='button' onClick={toggleMute}>
                        <Mic />
                    </a>
                </li>
                <li className="level-menu">
                    <a className={appSettings.cam ? "nav-link active" : "nav-link"} href={null} role='button' onClick={toggleCam}>
                        <Video />
                    </a>
                </li>
                <li className="level-menu">
                    <a className={appSettings.audio ? "nav-link active" : "nav-link"} href={null} role='button' onClick={toggleAudio}>
                        <Volume2 />
                    </a>
                </li>
                <li className="level-menu hide-media-menu">
                    <div onClick={toggleMediaMenu} style={{ background: 'rgba(81, 187, 37, 0.15)', padding: '0.5rem 1rem', borderRadius: 5, cursor: 'pointer' }}>
                        <Tool style={{ height: 18, verticalAlign: 'middle', width: 24, stroke: '#51bb25' }} />
                    </div>
                </li>
                <li className="level-menu">
                    <div onClick={toggleSettings} style={{ background: 'rgba(81, 187, 37, 0.15)', padding: '0.5rem 1rem', marginLeft: 10, borderRadius: 5, cursor: 'pointer' }}>
                        <Settings style={{ height: 18, verticalAlign: 'middle', width: 24, stroke: '#51bb25' }} />
                    </div>
                </li>
            </ul>
        </Container>
    );
}

export default MediaControl;
