import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Container, Card, CardBody, Row, Col } from 'reactstrap';

import CreateOrganizationForm from "./CreateOrganizationForm";
import WelcomeModal from '../Modals/WelcomeModal';

function CreateOrganization() {
    const userInfo = useSelector(state => state.user);
    const { t } = useTranslation();

    const [isWelcomeModalVisible, toggleWelcomeModal] = useState(true);

    return (
        <Container fluid>
            <div className='page-title'>
                {!userInfo.isGuest && <h3>{t('create_organization_page.header_regOrganization')}</h3>}
            </div>
            <Card>
                <CardBody>
                    <Row>
                        {userInfo.isGuest
                            ? <Col>
                                <h6>{t('system_messages.guest_create_org')}</h6>
                            </Col>
                            : <Col sm='6'>
                                <CreateOrganizationForm userInfo={userInfo} />
                                <WelcomeModal isOpen={isWelcomeModalVisible} onClose={() => { toggleWelcomeModal(false); }} />
                            </Col>
                        }
                    </Row>
                </CardBody>
            </Card>
        </Container>
    );
}

export default CreateOrganization;
