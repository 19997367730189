import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Media, Row, Col, Card } from 'reactstrap';
import { UserStatus } from '../UserStatus';
import css from './UserList.module.scss';

function UsersList({ roomId, users, onClick }) {
    const [userInfo, toggleUserInfo] = useState({ open: false });
    const { t } = useTranslation();

    const mouseEnter = (e, user) => {
        const rect = e.target.getBoundingClientRect();
        const username = (user.name || '').trim();

        toggleUserInfo({
            open: true,
            username: username.length > 0 ? username : user.email,
            x: rect.x + rect.width * 0.5,
            y: rect.y + rect.height,
            availabilityStatus: user.availabilityStatus
        });
    }

    const mouseLeave = () => {
        toggleUserInfo({ open: false });
    }

    return (
        <>
            <Row className='justify-content-center no-gutters gu-unselectable' data-roomid={roomId}>
                {users.map((u, ii) =>
                    <Col key={`${u.id}+${ii}`} data-user={u.id} data-roomid={roomId} className='col-auto text-center'>
                        <div className='mr-2 ml-2 mb-2' style={{ alignItems: 'center', cursor: 'pointer', maxWidth: 100 }} data-user={u.id} data-roomid={roomId}
                            onClick={(e) => onClick(u.id, e)}
                            onMouseEnter={(e) => mouseEnter(e, u)}
                            onMouseLeave={mouseLeave}
                        >
                            <div style={{ pointerEvents: 'none' }}>
                                {/* {u.availabilityStatus === 6 &&
                                    <div className={css.away}></div>
                                } */}
                                <div>
                                    <Media body className="rounded-circle crisp-edges img-cover-fit" style={{ width: 65, height: 65, filter: u.availabilityStatus === 6 && 'grayscale(1)' }} src={u.picture} alt={u.name} />
                                </div>
                                <div className={css.status}>
                                    <UserStatus user={u} />
                                </div>
                            </div>
                        </div>
                    </Col>
                )}
                {userInfo.open &&
                    <div className='pt-1' style={{ position: 'fixed', left: userInfo.x, top: userInfo.y }}>
                        <Card className='pl-4 pr-4 pt-2 mb-0' style={{ maxWidth: 200, minHeight: 40, transform: 'translateX(-50%)' }}>
                            <div className='text-center text-truncate'>
                                <h6 className='text-truncate'>{userInfo.username}</h6>
                            </div>
                            <Row className='align-items-center mb-3'>
                                {userInfo.availabilityStatus !== 1 && userInfo.availabilityStatus !== 6
                                    ? <>
                                        <Col className='col-auto'><UserStatus user={userInfo} /></Col>
                                        <Col className='pl-0'><span>{t(`status_dictionary.select_statusCode_${userInfo.availabilityStatus}`)}</span></Col>
                                    </>
                                    : <Col><span>{t(`status_dictionary.select_statusCode_${userInfo.availabilityStatus}`)}</span></Col>}
                            </Row>
                        </Card>
                    </div>
                }
            </Row>
        </>
    );
}

export default UsersList;
