import React, { useRef } from 'react';
import { ListGroup, ListGroupItem, Media, Row, Col } from 'reactstrap';
import { useSelector } from 'react-redux';
import { UserStatus } from '../UserStatus';

const MAX_MSG_COUNT = 99;

function ChatsList({ roomId, rooms, onChatSelect, contStyle }) {
    const userId = useSelector(state => state.user.id);
    const chatHistoryRef = useRef(null);

    return (
        <>
            <div className="custom-scrollbar pt-3 pb-1 mb-1" style={{ overflow: 'auto', ...contStyle }}>
                <ListGroup className="list-group-flush">
                    {rooms.map((x, index) => {
                        const user = x.participants.find(p => p.id !== userId);

                        return (
                            <ListGroupItem
                                key={x.id}
                                onClick={() => { onChatSelect && onChatSelect(x); }}
                                style={{ cursor: 'pointer', paddingLeft: 0, backgroundColor: roomId === x.roomId ? 'rgb(242, 242, 242)' : 'unset' }}
                            >
                                <Row className='no-gutters justify-content-between'>
                                    <Col className='col-9'>
                                        <div className="d-flex align-items-center">
                                            <div>
                                                <Media body
                                                    className="rounded-circle"
                                                    style={{ width: 25, height: 25, filter: user.availabilityStatus === 6 && 'grayscale(1)' }}
                                                    src={user.picture} alt={user.name}
                                                />
                                            </div>
                                            {user.availabilityStatus != null &&
                                                <div style={{ position: 'absolute', top: 12, left: 14 }}>
                                                    <UserStatus user={user} contStyle={{ width: 16, height: 16 }} />
                                                </div>
                                            }
                                            <span style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>{x.name}</span>
                                        </div>
                                    </Col>
                                    {x.hasNewMsg && (
                                        <Col className='col-auto'>
                                            <div className='pl-2 pr-2' style={{ textAlign: 'center', minWidth: 32, maxWidth: 44, backgroundColor: 'red', borderRadius: 4, color: 'white' }}>
                                                {x.unreadMsgCount > MAX_MSG_COUNT ? `${MAX_MSG_COUNT}+` : x.unreadMsgCount}
                                            </div>
                                        </Col>
                                    )}
                                </Row>
                            </ListGroupItem>
                        );
                    })}
                    <div ref={chatHistoryRef} />
                </ListGroup>
            </div>
        </>
    );
}

export default ChatsList;
