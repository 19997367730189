import { RECEIVE_USER_PROFILE_UPDATE } from '../actions/UserActions';
import { RESET } from './../actions';

import {
  RECEIVE_ROOMS, RECEIVE_USER_ROOM_STATE, RECEIVE_ROOM_CALL_STATE, RECEIVE_ROOM_DOOR_STATE,
  RECEIVE_ROOM_ENTER_REQUEST, RECEIVE_ROOM_ENTER_REQUEST_PROCESSED,
  RECEIVE_USER_ONLINE_STATUS,
  RECEIVE_TEAMS, RECEIVE_CURRENT_TEAM,
  RECEIVE_ROOM_INVITE_REQUEST, RECEIVE_ROOM_INVITE_REQUEST_PROCESSED, RECEIVE_TEAM, CLEAR_TEAM_DATA, RECEIVE_USER_STATUS
} from './../actions/TeamActions';

const initialState = {
  currentTeamId: null,
  teams: [],
  rooms: [],
  knockRequests: [],
  inviteRequests: []
};

export default function (state = initialState, action) {
  switch (action.type) {
    case RESET:
      return initialState;
    case RECEIVE_ROOMS:
      return {
        ...state,
        rooms: action.payload.map(r => {
          let room = state.rooms.find(sr => sr.id === r.id);

          return room ?
            {
              ...r,
              call: room.call,
              callInProgress: room.callInProgress,
              participants: room.participants || r.participants,
              doorIsOpen: room.doorIsOpen
            }
            : {
              ...r,
              doorIsOpen: !r.doorIsClosed
            };
        })
      };
    case RECEIVE_ROOM_ENTER_REQUEST:
      return {
        ...state,
        knockRequests: [...state.knockRequests, action.payload]
      };
    case RECEIVE_ROOM_ENTER_REQUEST_PROCESSED:
      return {
        ...state,
        knockRequests: state.knockRequests.filter(r => r.userId !== action.payload.userId && r.roomId !== action.payload.roomId)
      };
    case RECEIVE_ROOM_INVITE_REQUEST:
      return {
        ...state,
        inviteRequests: [...state.inviteRequests, action.payload]
      };
    case RECEIVE_ROOM_INVITE_REQUEST_PROCESSED:
      return {
        ...state,
        inviteRequests: state.inviteRequests.filter(r => r.roomId !== action.payload.roomId)
      };
    case RECEIVE_ROOM_CALL_STATE: {
      //userName, userId, roomId, callId, callType, isCalling
      return {
        ...state,
        rooms: state.rooms.map(r => {
          return r.id === action.payload.roomId ? {
            ...r,
            call: {
              id: action.payload.callId,
              type: action.payload.callType,
              ...action.payload
            },
            callInProgress: action.payload.count > 0
          } : r
        })
      }
    }
    case RECEIVE_ROOM_DOOR_STATE: {
      return {
        ...state,
        rooms: state.rooms.map(r => {
          return r.id === action.payload.roomId ? {
            ...r,
            doorIsOpen: action.payload.isOpened
          } : r
        })
      }
    }
    case RECEIVE_USER_ROOM_STATE: {
      const { userId, userName, picture, roomId, isInRoom, available } = action.payload;
      let user = {};
      state.rooms.some(r => { user = r.participants.find(p => p.id === userId); return user; });

      return {
        ...state,
        rooms: state.rooms.map(r => {
          let participants = [];

          if (r.id === roomId) {
            const hasUser = r.participants.find(p => p.id === userId);

            if (hasUser)
              participants = r.participants.map(p => p.id === userId ? { ...p, picture: (picture && picture.length > 1) ? picture : p.picture, online: isInRoom } : p);
            else
              participants = [...r.participants, { ...user, id: userId, name: userName, picture, online: isInRoom }];
          } else {
            participants = r.participants.map(p => p.id === userId ? { ...p, online: false } : p);
          }

          return {
            ...r,
            available: r.id === roomId && available ? available : r.available,
            participants
          };
        })
      }
    }
    case RECEIVE_USER_ONLINE_STATUS: {
      const { userId, userName, picture, roomIds, online } = action.payload;
      //string userName, Guid userId, IEnumerable roomIds
      return {
        ...state,
        rooms: state.rooms.map(r => {
          let participants = [];

          if (roomIds.find(rId => rId === r.id)) {
            const hasUser = r.participants.find(p => p.id === userId);

            if (hasUser)
              participants = r.participants.map(p => p.id === userId ? { ...p, picture: (picture && picture.length > 1) ? picture : p.picture, online } : p);
            else
              participants = [...r.participants, { id: userId, name: userName, picture, online }];
          } else {
            participants = r.participants.map(p => p.id === userId ? { ...p, online: false } : p);
          }

          return {
            ...r,
            participants,
          };
        })
      };
    }
    case RECEIVE_TEAMS: {
      return {
        ...state,
        teams: action.payload
      }
    }
    case RECEIVE_CURRENT_TEAM: {
      return {
        ...state,
        currentTeamId: action.payload
      }
    }
    case RECEIVE_USER_PROFILE_UPDATE: {
      const { profile } = action.payload;
      const room = state.rooms.find(r => r.participants.find(p => p.online && p.id === profile.id));

      return {
        ...state,
        rooms: state.rooms.map(r => {
          if (r.id === room.id)
            return {
              ...r,
              participants: r.participants.map(x => x.id === profile.id ? { ...x, ...profile } : x)
            }

          return r;
        })
      }
    }
    case RECEIVE_USER_STATUS: {
      const { userId, status } = action.payload;

      return {
        ...state,
        rooms: state.rooms.map(r => {
          if (r.participants.find(p => p.id === userId)) {
            return {
              ...r,
              participants: r.participants.map(x => x.id === userId ? { ...x, ...status } : x)
            }
          }

          return r;
        })
      }
    }
    case RECEIVE_TEAM: {
      return {
        ...state,
        teams: state.teams.map(t => t.id === action.payload.id ? { ...t, ...action.payload } : t)
      }
    }
    case CLEAR_TEAM_DATA: {
      return {
        ...state,
        rooms: [],
        knockRequests: [],
        inviteRequests: []
      }
    }
    default:
      return state;
  }
}