import React from 'react';
import { useTranslation } from 'react-i18next';

const Loader = () => {
    const { t } = useTranslation();

    return (<div className="container h-100">
        <div className="row align-items-center h-100">
            <div className="col-6 mx-auto text-center">
                <div className="loader-box">
                    <div className="loader-19"></div>
                </div>
                {t('common.loading')}
            </div>
        </div>
    </div>);
}

export default Loader;