import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ModalType, receiveMediaInfo, toggleModal } from '../../data/store/actions/AppActions';
import { useTranslation } from 'react-i18next';
import { mediaMenuIsOpen } from '../../data/store/selectors/appSelectors';
import {  Modal, ModalBody, Button, Row, Col } from 'reactstrap';
import { Mic, Volume2, Video } from 'react-feather';
import css from './modals.module.scss';

const MediaMenuModal = () => {
    const appSettings = useSelector(state => state.app.media);
    const isOpen = useSelector(mediaMenuIsOpen);
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const toggleMute = () => {
        dispatch(receiveMediaInfo({ mic: !appSettings.mic }));
    }

    const toggleCam = () => {
        dispatch(receiveMediaInfo({ cam: !appSettings.cam }));
    }

    const toggleAudio = () => {
        dispatch(receiveMediaInfo({ audio: !appSettings.audio }));
    }

    const onClose = () => {
        dispatch(toggleModal({ type: ModalType.mediaMenu, isOpen: false }));
    }
    return (
        <Modal isOpen={isOpen} toggle={onClose}>
            <ModalBody>
                <div className="text-center align-items-center">
                    <Row>
                        <Col>
                            <a
                                className={appSettings.mic ? `nav-link ${css.mediaBtnActive}` : `nav-link ${css.mediaBtn}`}
                                href={null}
                                role='button'
                                onClick={toggleMute}
                            >
                                <Mic />
                            </a>
                        </Col>
                        <Col>
                            <a
                                className={appSettings.cam ? `nav-link ${css.mediaBtnActive}` : `nav-link ${css.mediaBtn}`}
                                href={null}
                                role='button'
                                onClick={toggleCam}
                            >
                                <Video />
                            </a>
                        </Col>
                        <Col>
                            <a
                                className={appSettings.audio ? `nav-link ${css.mediaBtnActive}` : `nav-link ${css.mediaBtn}`}
                                href={null}
                                role='button'
                                onClick={toggleAudio}
                            >
                                <Volume2 />
                            </a>
                        </Col>
                    </Row>
                    
                    <div style={{ marginTop: '20px' }}>
                        <Button color='primary' autoFocus onClick={onClose}>{t('common.btn.ok')}</Button>
                    </div>
                </div>
            </ModalBody>
        </Modal >
    );
}

export default MediaMenuModal;