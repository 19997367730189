import React, { useState, useEffect, useContext, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Smile } from 'react-feather';
import 'emoji-mart/css/emoji-mart.css';
import { Picker } from 'emoji-mart';

import { Row, Col, Media, InputGroup, Input, InputGroupAddon, Button } from 'reactstrap';

import { HubConnectorContext } from '../../data/context/HubConnector';
import { markMessageAsReaded } from './../../data/store/actions/ChatActions';
import { isSystemMessage, isSystemMessageWithSenderInfo } from '../../utils/ChatHelper';
import Linkify from 'react-linkify';
import moment from 'moment';

function ChatBox({ roomId, emoteContStyle, participant, maxHeight, chatStyle }) {
    const user = useSelector(state => state.user);
    const { rooms: chatRooms } = useSelector(state => state.chat);

    const hubCtx = useContext(HubConnectorContext);
    const chatHistoryRef = useRef(null);

    const [emoteIsOpen, toggleEmote] = useState(false);
    const [messageInput, setMessageInput] = useState('');
    const dispatch = useDispatch();
    const node = useRef();
    const { t } = useTranslation();

    const handleMessageChange = (message) => {
        setMessageInput(message)
    }

    const handleMessagePress = (e) => {
        if (e.key === "Enter" || e === "send") {
            if (messageInput.length > 0) {
                const r = chatRooms.find(cr => cr.roomId === roomId);

                if (!r) {
                    console.error(`room ${roomId} doesnt found`);
                }

                if (r.isRoomChat)
                    hubCtx.chat.sendRoomMessage(messageInput, roomId);
                else
                    hubCtx.chat.sendPrivateMessage(participant.id, messageInput);

                toggleEmote(false);
                setMessageInput('');
            }
        }
    }

    const scrollToBottom = () => {
        if (chatHistoryRef && chatHistoryRef.current)
            chatHistoryRef.current.scrollIntoView({ behavior: 'auto' })
    }

    const r = chatRooms.find(cr => cr.roomId === roomId);
    const messages = r ? r.messages : [];

    const renderChat = () => {
        return (
            <div className="custom-scrollbar pt-3 pb-3 mb-3" style={{ height: maxHeight, overflow: 'auto', ...chatStyle }}>
                <ul>
                    {messages.filter(x => !isSystemMessage(x.message)).map((item, index) => {
                        if (isSystemMessage(item.message)) {
                            return (
                                <li key={index} className='clearfix mb-1'>
                                    {isSystemMessageWithSenderInfo(item.message) && <span className='font-italic pull-left mr-1'>{item.message.senderName}</span>}
                                    <span className='font-italic'>{t(`system_messages.${item.message.message.split('.')[1]}`)}</span>
                                </li>
                            );
                        }

                        const isMe = item.message.senderId === user.id;
                        return (
                            <li key={index} className='clearfix mb-2'>
                                <div className={`message ${isMe ? 'justify-content-end' : 'other-message'} d-flex`}>
                                    {!isMe &&
                                        <div className='mr-2'>
                                            <Media src={item.message.senderPic}
                                                className={`rounded-circle chat-user-img img-30`} alt=""
                                            />
                                            {/* <span style={{ margin: '4px 8px 0px', display: 'inline-block', background: 'white' }}>{item.message.senderName}</span> */}
                                        </div>
                                    }
                                    <div>
                                        <div className='mb-1 p-2'
                                            style={{
                                                width: '100%',
                                                border: '1px solid #f4f4f4',
                                                borderRadius: 10,
                                                borderTopLeftRadius: 0,
                                                backgroundColor: isMe ? 'white' : 'rgb(242, 242, 242)',
                                            }}
                                        >
                                            <Linkify componentDecorator={(href, text, key) => (
                                                <a href={href} key={key} target="_blank" rel="noopener noreferrer">
                                                    {text}
                                                </a>
                                            )}>{item.message.message}</Linkify>
                                        </div>
                                        <div className={`message-data ${isMe ? 'text-right' : ''}`} >
                                            <span style={{
                                                letterSpacing: 1,
                                                fontSize: 12,
                                                color: '#59667a'
                                            }}>{item.message.senderName}, {moment.utc(item.message.sent).local().format('hh:mm A')}</span>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        );
                    })}
                    <div ref={chatHistoryRef} />
                </ul>
            </div>
        );
    }

    const handleOutsideClick = (e) => {
        if (e.target.id === `chat-emote-btn-${roomId}` || (node.current && node.current.contains(e.target)))
            return;

        toggleEmote(false);
    };

    useEffect(() => {
        document.addEventListener("click", handleOutsideClick);

        return () => {
            document.removeEventListener("click", handleOutsideClick);
        };
    }, []);

    useEffect(() => {
        scrollToBottom();
        dispatch(markMessageAsReaded({ roomId }));

        if (messages.length > 0) hubCtx.chat.sendSetRead(messages[messages.length - 1].message.id);
    }, [messages, roomId]);

    const addEmoji = e => {
        setMessageInput(messageInput + e.native);
        toggleEmote(false);
    };

    return (
        <>
            {renderChat()}
            <div className="clearfix">
                <Row>
                    <Col xl="12" className="d-flex">
                        <Button id={`chat-emote-btn-${roomId}`} color="primary" onClick={() => toggleEmote(true)} style={{ padding: '0 4px', marginRight: 4 }}><Smile style={{ marginTop: 4, pointerEvents: 'none' }} /></Button>
                        <InputGroup className="text-box">
                            <Input
                                type="textarea"
                                className="form-control input-txt-bx"
                                placeholder={t('chat.send_placeholder')}
                                value={messageInput}
                                onKeyPress={(e) => handleMessagePress(e)}
                                onChange={(e) => handleMessageChange(e.target.value)}
                                autoFocus={true}
                                rows={1}
                            />
                            <InputGroupAddon addonType="append">
                                <Button color="primary" onClick={() => handleMessagePress('send')} >{t('chat.send_btn')}</Button>
                            </InputGroupAddon>
                        </InputGroup>
                    </Col>
                </Row>
                <span ref={node} style={{ position: 'absolute', bottom: 50, right: 2, ...emoteContStyle }}>
                    {emoteIsOpen && <Picker onSelect={addEmoji} />}
                </span>
            </div>
        </>
    );
}

export default ChatBox;
