import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Button } from 'reactstrap';
import { MessageSquare, X } from 'react-feather';

import ChatBox from "./ChatBox";
import { getChatHistory, setRoomChat, startRoomChat, toggleRoomChat } from '../../data/store/actions/ChatActions';
import { roomChatIsOpenSelector, currentRoomIdSelector } from '../../data/store/selectors/chatSelectors';

function RoomChat() {
    const user = useSelector(state => state.user);
    const { rooms: chatRooms } = useSelector(state => state.chat);
    const { rooms } = useSelector(state => state.team);

    const [currentRoom, setCurrentRoom] = useState(null);
    const roomChatIsOpen = useSelector(roomChatIsOpenSelector);
    const currentRoomId = useSelector(currentRoomIdSelector);

    const { t } = useTranslation();
    const dispatch = useDispatch();

    useEffect(() => {
        if (!currentRoom || !currentRoom.id)
            return;

        (async () => {
            await dispatch(startRoomChat(currentRoom.id));
            await dispatch(getChatHistory(currentRoom.id, (new Date()).setDate(new Date().getDate() - 3), Date.now()));
        })();
    }, [currentRoom]);

    useEffect(() => {
        if (!currentRoomId) {
            const room = rooms.find(r => r.participants.find(p => p.online && p.id === user.id));

            if (room)
                dispatch(setRoomChat({ roomId: room.id }));

            return;
        }
        //const room = rooms.find(r => r.participants.find(p => p.online && p.id === user.id));
        const room = rooms.find(r => r.id === currentRoomId);

        if (!room)
            return;

        if (room && room.id !== currentRoom?.id) {
            setCurrentRoom(room);
        }
    }, [rooms, currentRoomId]);

    const c = chatRooms.find(cr => cr.roomId === currentRoom?.id);
    const hasNewMsg = (c && c.hasNewMsg) || false;

    return (
        <>
            <div style={{ position: 'fixed', top: 56, right: 8, zIndex: 1 }}>
                <Button className='btn-room-secondary btn-toggle-chat' onClick={() => { dispatch(toggleRoomChat({ isOpen: !roomChatIsOpen })); }}>
                    {roomChatIsOpen ? <X /> : <MessageSquare className={`${currentRoom && hasNewMsg ? 'animated infinite tada' : ''}`} />}
                </Button>
            </div>
            {currentRoom && roomChatIsOpen &&
                <div style={{ width: 360, padding: 10, position: 'fixed', right: 0, top: 48, bottom: 0, background: 'white', boxShadow: '0 0 21px 0 rgb(89 102 122 / 10%)' }}>
                    <div style={{ position: 'absolute', top: 6, left: 10 }}>
                        <h6 style={{ backgroundColor: "white" }}>{t('chat.title', { title: currentRoom.name + ' ' })}</h6>
                    </div>
                    <ChatBox
                        roomId={currentRoom.id}
                        maxHeight={'calc(100% - 50px)'}
                    />
                </div>
            }
        </>
    );
}

export default RoomChat;
