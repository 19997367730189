import React from 'react';
import { useFormikContext } from 'formik';
import DatePicker from 'react-datepicker';
import { FormFeedback, FormGroup, Label } from 'reactstrap';

const RFDatePicker = (
    {
        field: { ...fields },
        form: { touched, errors, ...rest },
        ...props
    }) => {
    const { setFieldValue } = useFormikContext();

    return (
        <FormGroup>
            <Label for={props.id} className={props.labelstyle}>{props.label}</Label>
            <DatePicker
                {...fields}
                {...props}
                invalid={Boolean(touched[fields.name] && errors[fields.name])}
                selected={(fields.value && new Date(fields.value)) || null}
                onChange={val => {
                    setFieldValue(fields.name, val);
                }}
            />
            {touched[fields.name] && errors[fields.name] ? <FormFeedback>{errors[fields.name]}</FormFeedback> : ''}
        </FormGroup>
    );
};

export default RFDatePicker;