import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Media, Row, Col, Card } from 'reactstrap';
import busyIco from './../../assets/userStatus/busy.png';
import dontDisturbIco from './../../assets/userStatus/dont_disturb.png';
import dontDisturbUrgentIco from './../../assets/userStatus/dont_disturb_urgent.png';
import focusedIco from './../../assets/userStatus/focused.png';

const getIco = (status) => {
    switch (status) {
        case 2: return dontDisturbIco;
        case 3: return dontDisturbUrgentIco;
        case 4: return busyIco;
        case 5: return focusedIco;
        default: return null;
    }
}

function UserStatus({ user, contStyle }) {
    const { t } = useTranslation();
    const ico = getIco(user.availabilityStatus);

    return (
        <>
            {ico && <img src={ico} className='crisp-edges' alt={t(`status_dictionary.select_statusCode_${user.availabilityStatus}`)} style={{ width: 24, ...contStyle }} />}
        </>
    );
}

export default UserStatus;
