import axios from "axios";
import { Configuration } from './../config';

export function getOrganizationsApi() {
    return axios.get(`${Configuration.basePath}/api/organizations`);
}

export function getOrganizationDetailsApi(orgId) {
    return axios.get(`${Configuration.basePath}/api/organizations/${orgId}`);
}

export function delOrganizationApi(orgId) {
    return axios.get(`${Configuration.basePath}/api/organizations/${orgId}`);
}

/*
{
"name": "string",
"defaultTeamName": "string"
}
*/
export function postOrganizationApi(orgDetails) {
    return axios.post(
        `${Configuration.basePath}/api/organizations`,
        orgDetails
    );
}