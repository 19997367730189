import { getActionConst } from './../../../utils/ReduxHelper';
import { getOrCreateUserApi, postProfileDetailsApi, getStatusApi, postStatusApi, getRolesApi } from "./../../api/user";

export const RECEIVE_USER_INFO = getActionConst('RECEIVE_USER_INFO');
export const RECEIVE_USER_STATUS = getActionConst('RECEIVE_USER_STATUS');
export const RECEIVE_USER_ROLE = getActionConst('RECEIVE_USER_ROLE');
export const RECEIVE_USER_PROFILE_UPDATE = getActionConst('RECEIVE_USER_PROFILE_UPDATE');

export function receiveUserInfo(payload) {
    return { type: RECEIVE_USER_INFO, payload };
}

export function receiveUserStatus(payload) {
    return { type: RECEIVE_USER_STATUS, payload };
}

export function receiveUserRole(payload) {
    return { type: RECEIVE_USER_ROLE, payload };
}

export function receiveUserProfileUpdate(payload) {
    return { type: RECEIVE_USER_PROFILE_UPDATE, payload };
}

export function getOrCreateUser() {
    return async (dispatch) => {
        try {
            const resp = await getOrCreateUserApi();
            dispatch(receiveUserInfo(resp.data));

            return resp.data;
        } catch (err) { };
    };
}

export function postProfileDetails(profileDetails) {
    return (dispatch) => {
        postProfileDetailsApi(profileDetails)
            .then((resp) => {
                dispatch(receiveUserInfo(resp.data));
            })
            .catch(err => console.warn(err));
    };
}

export function getUserStatus() {
    return (dispatch) => {
        getStatusApi()
            .then((resp) => {
                dispatch(receiveUserStatus(resp.data));
            })
            .catch(err => console.warn(err));
    };
}

export function postUserStatus(statusObj) {
    return (dispatch) => {
        postStatusApi(statusObj)
            .then((resp) => {
                dispatch(receiveUserStatus(resp.data));
            })
            .catch(err => console.warn(err));
    };
}

export function getRoles(teamId) {
    return async (dispatch) => {
        try {
            const resp = await getRolesApi(teamId);
            dispatch(receiveUserRole(resp.data));

            return resp.data;
        } catch (err) { };
    };
}