import axios from "axios";
import { Configuration } from './../config';

export function getInviteDetailsApi(id) {
    return axios.get(`${Configuration.basePath}/api/invitations/${id}`);
}

export function acceptInviteApi(id, token) {
    return axios.get(`${Configuration.basePath}/api/invitations/${id}/accept`, { headers: { Authorization: `Bearer ${token}` } });
}

export function acceptGuestInviteApi(id, userDetails) {
    return axios.post(`${Configuration.basePath}/api/invitations/${id}/accept-guest`, userDetails);
}

export function getFromLinkApi(link) {
    return axios.get(`${Configuration.basePath}/api/invitations/fromlink${link}`);
}

export function getPublicSpacesApi(teamId) {
    return axios.get(`${Configuration.basePath}/api/invitations/team/${teamId}/publics`);
}

/*
{
  "teamId": "a4ede8ba-7c0a-4485-8763-cbd9b282fbec",
  "email": "string",
  "isPublic": true,
  "link": "string",
  "expiresAt": "2019-08-24T14:15:22Z",
  "isMemberInvite": true,
  "sendImmediate": true
}
*/
export function postInvitationsApi(invite) {
    return axios.post(
        `${Configuration.basePath}/api/invitations`,
        invite
    );
}