import React, { useState, useRef } from 'react';
import { Col, Row, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import GuestRoomInviteForm from '../Invites/GuestRoomInviteForm';
import css from './modals.module.scss';

let submitRef = null;
let isSubmitting = null;

function GuestRoomInviteModal({ room, onClose }) {
    const { t } = useTranslation();
    const [invite, setInvite] = useState({});
    const inviteUrlField = useRef(null);

    const successHandler = (data) => {
        setInvite(data);
    }

    const handleCopyAndClose = () => {
        if (inviteUrlField)
            inviteUrlField.current.focus();

        let range = document.createRange();
        range.selectNodeContents(inviteUrlField.current);

        let sel = window.getSelection();
        sel.removeAllRanges();
        sel.addRange(range);

        document.execCommand('copy');

        onClose();
    };

    return (
        <Modal isOpen={true} toggle={onClose}>
            <ModalHeader toggle={onClose} className={css.modalHeader}>{t('guest_room_invite_modal.header', { roomName: room.name })}</ModalHeader>
            <ModalBody>
                {!invite.id ?
                    <GuestRoomInviteForm
                        roomId={room.id}
                        onSuccess={successHandler}
                        submitRef={(submit, submitting) => { submitRef = submit; isSubmitting = submitting }}
                    />
                    : <Row className='align-items-center'>
                        <Col className="col-auto mr-auto">
                            <p style={{ fontSize: 16, marginBottom: '0.2rem', fontWeight: 500 }}>{t('guest_room_invite_modal.link_label')}</p>
                            <span ref={inviteUrlField} style={{ fontSize: 16, fontWeight: 500 }}>{`${window.location.origin}/invite/${invite.id}`}</span>
                        </Col>
                        {/* <Col className="col-auto">
                            <Button color='primary' autoFocus onClick={handleCopyAndClose}>{t('invite_created_modal.copy_and_close_btn')}</Button>
                        </Col> */}
                    </Row>
                }
            </ModalBody>
            <ModalFooter className='justify-content-start'>
                {!invite.id
                    ? <Button
                        color='success'
                        size='lg'
                        onClick={() => { submitRef(); }}
                    >
                        {t('common.btn.submit')}
                    </Button>
                    : <Button color='success' size='lg' autoFocus onClick={handleCopyAndClose}>{t('invite_created_modal.copy_and_close_btn')}</Button>
                }
            </ModalFooter>
        </Modal>
    );
}

export default GuestRoomInviteModal;
