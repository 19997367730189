import axios from "axios";
import { Configuration } from './../config';

const ThumbSizes = {
    small: 16,
    medium: 32,
    large: 64
}

export function postImageApi(file) {
    const bodyFormData = new FormData();
    bodyFormData.append('imageFile', file);
    bodyFormData.append('smallSquareThumbSize', ThumbSizes.small);
    bodyFormData.append('mediumSquareThumbSize', ThumbSizes.medium);
    bodyFormData.append('largeSquareThumbSize', ThumbSizes.large);

    return axios.post(`${Configuration.basePath}/api/files/images/add`,
        bodyFormData,
        {
            headers: { 'Content-Type': 'multipart/form-data' },
        });
}

export function getImageApi(imageId) {
    return axios.get(`${Configuration.basePath}/api/files/images/${imageId}`);
}