import { RECEIVE_ERROR } from './../actions/ErrorActions';

//   id: '',
//   type: '', error|warning
//   message: '',
//   payload: {}
const initialState = [];


export default function (state = initialState, action) {
  switch (action.type) {
    case RECEIVE_ERROR:
      const { type, message, payload } = action.error;

      return [
        ...state,
        {
          id: new Date().getTime(),
          type,
          message,
          payload
        }
      ]
    default:
      return state;
  }
}