import React, { useContext, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import { useAuth0 } from '@auth0/auth0-react';
import get from 'lodash/get';
import has from 'lodash/has';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';

import { Container, Button, Card, CardBody, Row, Col } from 'reactstrap';

import { AuthContext } from './../components/AuthProvider';
import { getInviteDetailsApi, acceptInviteApi, acceptGuestInviteApi } from './../data/api/invite';
import { reset } from "./../data/store/actions";
import { getOrCreateUserApi } from "./../data/api/user";
import { RFInput } from '../components/common/RFInput';
import { RoutePaths } from '../data/contants';

import logo from './../assets/images/logo/Icon-150_150.png';
import css from './AcceptInvitePage.module.scss';

function AcceptInvitePage() {
    const ctx = useContext(AuthContext);
    const { id } = useParams();
    const history = useHistory();
    const { isAuthenticated, isLoading } = useAuth0();
    const [inviteDetails, setInviteDetails] = React.useState({});
    const [onLoad, setLoad] = React.useState(true);
    const { t } = useTranslation();
    const dispatch = useDispatch();

    useEffect(() => {
        (async () => {
            setLoad(true);

            if (isLoading)
                return;

            localStorage.removeItem('invite-id');
            dispatch(reset());

            try {
                const respInviteDetails = await getInviteDetailsApi(id);

                setInviteDetails(respInviteDetails.data);

                if (!respInviteDetails.data.isMemberInvite) {
                    setLoad(false);
                    return;
                }

                if (!isAuthenticated) {
                    ctx.clearToken();
                    localStorage.setItem('invite-id', respInviteDetails.data.id);
                    history.push(RoutePaths.Root);
                } else {
                    setLoad(false);
                }
            } catch (e) {
                // const teamId = get(e, 'response.data.id');
                // if (teamId) {
                //     history.replace(`${RoutePaths.Team}/${teamId}`);
                // } else {
                //     setLoad(false);
                // }
                setLoad(false);
            }
        })();


        return async () => {
        };
    }, [isLoading]);

    const acceptInvite = async () => {
        if (isAuthenticated) {
            const token = await ctx.getToken(true);

            try {
                await getOrCreateUserApi(token);
                const { data } = await acceptInviteApi(inviteDetails.id, token);

                localStorage.removeItem('invite-id');
                history.replace(`${RoutePaths.Team}/${data.id}`);
            } catch (e) {
                console.warn(e);
            }
        } else {
            ctx.clearToken();
            localStorage.setItem('invite-id', inviteDetails.id);
            history.push(RoutePaths.Root);
        }
    }

    const renderContent = () => {
        if (onLoad)
            return (
                <>{t('common.loading')}</>
            );

        if (!has(inviteDetails, 'id'))
            return (
                <div className='page-title1'>
                    <h6 style={{ whiteSpace: 'pre-line' }}>{t('system_messages.invalid_invite')}</h6>
                </div>
            );

        if (!inviteDetails.isMemberInvite)
            return (
                <>
                    <div className='m-b-25'>
                        <h6>
                            <Trans
                                i18nKey="team_management_page.accept_invite.guest_invite_description"
                                values={{
                                    teamName: get(inviteDetails, 'team.name', ''),
                                    userName: get(inviteDetails, 'createdBy.name', '')
                                }}
                            >
                                Invite <b>Join now</b> next
                            </Trans>
                        </h6>
                    </div>
                    <Formik
                        initialValues={{
                            name: ""
                        }}
                        validationSchema={Yup.object({
                            name: Yup.string().required(),
                            //email: Yup.string().email().required()
                        })}
                        onSubmit={async (values, { setSubmitting }) => {
                            setSubmitting(true);

                            const { name } = values;
                            const { data } = await acceptGuestInviteApi(inviteDetails.id, { name });
                            ctx.setToken(data.token_type, data.access_token);
                            localStorage.removeItem('invite-id');

                            //setSubmitting(false);
                            history.push(RoutePaths.Root);
                        }}
                    >
                        {({ submitForm, isSubmitting }) => (
                            <Form>
                                <div className='m-b-25'>
                                    <Field
                                        component={RFInput}
                                        name='name'
                                        type='text'
                                        //label={t('team_management_page.accept_invite.label_name')}
                                        placeholder={t('team_management_page.accept_invite.label_name')}
                                        variant='outlined'
                                        style={{ fontSize: '1.2rem' }}
                                    />
                                </div>
                                <div>
                                    <Button
                                        variant='contained'
                                        color='success'
                                        disabled={isSubmitting}
                                        onClick={submitForm}
                                        className={css.submitBtn}
                                    >
                                        {t('team_management_page.accept_invite.join')}
                                    </Button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </>
            );

        return (
            <>
                {inviteDetails &&
                    <div className='m-b-25'>
                        <h6>
                            <Trans
                                i18nKey="team_management_page.accept_invite.team_invite_description"
                                values={{
                                    teamName: get(inviteDetails, 'team.name', ''),
                                    userName: get(inviteDetails, 'createdBy.name', '')
                                }}
                            >
                                Invite <b>Join now</b> next
                            </Trans>
                        </h6>
                    </div>
                }
                <div>
                    <Button
                        variant='contained'
                        color='success'
                        onClick={acceptInvite}
                        className={css.submitBtn}
                    >
                        {t('team_management_page.accept_invite.accept')}
                    </Button>
                </div>
            </>
        );
    }

    return (
        <Container className='h-100'>
            <Row className='justify-content-center h-100 mt-5'>
                <Col sm='6' className='mx-auto'>
                    <Card className={css.wrapper}>
                        <CardBody>
                            <div>
                                <div className='page-title text-center mb-3'>
                                    <div className="logo-wrapper mb-2">
                                        <img src={logo} style={{ width: 54 }} alt='teamblaze' />
                                    </div>
                                    <h5>{t('team_management_page.accept_invite.header')}</h5>
                                </div>
                                {renderContent()}
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
}

export default AcceptInvitePage;