import React, { useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Container, Row, Col, Card, CardBody, Modal, ModalHeader, ModalBody, ModalFooter, Button, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';

import PublicSpaceInviteForm from '../../components/Invites/PublicSpaceInviteForm';
import { getPublicSpacesApi } from '../../data/api/invite';

function PublicSpacePage() {
    const { teamId } = useSelector(state => state.user.role);
    const { t } = useTranslation();

    const [invite, setInvite] = React.useState({ open: false });
    const [publicSpaces, setPublicSpaces] = React.useState([]);
    const inviteUrlField = useRef(null);

    const handleCopyAndClose = () => {
        if (inviteUrlField)
            inviteUrlField.current.focus();

        let range = document.createRange();
        range.selectNodeContents(inviteUrlField.current);

        let sel = window.getSelection();
        sel.removeAllRanges();
        sel.addRange(range);

        document.execCommand('copy');

        setInvite({ open: false });
    };

    const handleClose = () => {
        setInvite({ open: false });
    };

    useEffect(() => {
        (async () => {
            try {
                const { data } = await getPublicSpacesApi(teamId);
                setPublicSpaces(data || []);
            } catch { }
        })();

    }, []);

    return (
        <Container fluid>
            <div className="page-title">
                <h3>{t('public_space_page.header')}</h3>
            </div>
            <Row
                direction="column"
                justify="flex-start"
            >
                <Col xs={12}>
                    <Card>
                        <CardBody>
                            <Row>
                                <Col sm="6">
                                    <PublicSpaceInviteForm
                                        details={publicSpaces[0] || {}}
                                        onSuccess={(invite) => { setInvite({ open: true, id: invite.link }); }}
                                    />
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <Modal isOpen={invite.open} toggle={handleClose} >
                <ModalHeader toggle={handleClose}>{t('invite_created_modal.header')}</ModalHeader>
                <ModalBody>
                    <span ref={inviteUrlField}>{`${window.location.origin}/${invite.id}`}</span>
                </ModalBody>
                <ModalFooter>
                    <Button color='primary' autoFocus onClick={handleCopyAndClose}>{t('invite_created_modal.copy_and_close_btn')}</Button>
                </ModalFooter>
            </Modal>
        </Container>
    );
}

export default PublicSpacePage;