import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { setLocale } from 'yup';

setLocale({
    mixed: {
        default: () => i18n.t('common.form.validation.invalid'),
        required: () => i18n.t('common.form.validation.required')
    },
    string: {
        email: () => i18n.t('common.form.validation.email')
    }
});

i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        preload: ['en', 'ru'],

        backend: {
            queryStringParams: { t: new Date().getTime() },
        },
        
        //lng: "ru",
        fallbackLng: 'en',

        ns: ['common'],
        defaultNS: 'common',

        nonExplicitSupportedLngs: true,
        //keySeparator: false,

        interpolation: {
            escapeValue: false,
        },

        //debug: true,
    });

export default i18n;