import React from 'react';
import { Row, Col, Button, Media, Card, CardBody } from 'reactstrap';
import { useTranslation } from 'react-i18next';

import MediaPlayer from '../MediaPlayer';
import VideoCallGrid from '../VideoCallGrid';
import cn from 'classnames';
import css from './CallArea.module.scss';
import { useSelector } from 'react-redux';
import { agoraTracksSelector } from '../../data/store/selectors/appSelectors';

const callAreaWrapperCss = cn('justify-content-start align-items-center', css.callAreaWrapper);
const userAvatarCss = cn('position-absolute rounded-circle m-auto', css.userAvatar);

function CallArea({ callData, user, onScreenShareHandler, stopSharescreen, onLeave, setAgoraClientEncoder, startCameraTrack }) {
    const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    const { t } = useTranslation();

    const tracks = useSelector(agoraTracksSelector);

    return (
        <Row className={callAreaWrapperCss}>
            <Col className='pt-3 pb-3'>
                <Row id={'call-user-grid'} className={css.gridWrapper}>
                    <VideoCallGrid
                        setAgoraClientEncoder={setAgoraClientEncoder}
                    />
                    <Col className='col-auto'>
                        <Card className={css.userViewWrapper}>
                            <CardBody className='p-0'>
                                <MediaPlayer videoTrack={tracks.video || tracks.screen} style={{ width: 176, height: 140 }}>
                                    {!tracks.video && !tracks.screen && user.picture &&
                                        <div className={css.userAvatarWrapper}>
                                            <Media body className={userAvatarCss} src={user.picture} alt={user.name} />
                                        </div>
                                    }
                                </MediaPlayer>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col className='col-auto ml-auto d-flex align-items-center'>
                        <ul className='horizontal-menu'>
                            {!isMobile &&
                                <li className='level-menu mr-2'>
                                    {tracks.screen
                                        ? <Button color="primary" onClick={() => { stopSharescreen(); }}>{t('room.call.btn.stop_sharescreen')}</Button>
                                        : <Button color="primary" onClick={onScreenShareHandler}>{t('room.call.btn.sharescreen')}</Button>
                                    }
                                </li>
                            }
                            {/* {!callData.video &&
                        <li className='level-menu mr-2'>
                            <Button color="primary" onClick={onLeave}>{t('room.call.btn.video')}</Button>
                        </li>
                    } */}
                            {callData.isVideoCall && !tracks.video && !tracks.screen &&
                                <li className='level-menu mr-2'>
                                    <Button color="primary" onClick={startCameraTrack}>{t('room.call.btn.video_on')}</Button>
                                </li>
                            }
                            <li className='level-menu'>
                                <Button color="secondary" onClick={onLeave}>{t('room.call.btn.leave')}</Button>
                            </li>
                        </ul>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
}

export default CallArea;
