import { getActionConst } from './../../../utils/ReduxHelper';
import { getOrganizationsApi, postOrganizationApi } from "./../../api/organization";

export const RECEIVE_ORGANIZATIONS = getActionConst('RECEIVE_ORGANIZATIONS');
export const RECEIVE_ORGANIZATION_DETAILS = getActionConst('RECEIVE_ORGANIZATION_DETAILS');

export function receiveOrganizations(organizations) {
    return { type: RECEIVE_ORGANIZATIONS, payload: organizations };
}

export function receiveOrganizationDetails(details) {
    return { type: RECEIVE_ORGANIZATION_DETAILS, payload: details };
}

export function getOrganizations() {
    return async (dispatch) => {
        try {
            const resp = await getOrganizationsApi();
            dispatch(receiveOrganizations(resp.data));
        } catch (err) { };
    };
}

export function postOrganization(orgDetails) {
    return async (dispatch) => {
        try {
            const resp = await postOrganizationApi(orgDetails)
            dispatch(receiveOrganizationDetails(resp.data));
        } catch (err) { };
    };
}