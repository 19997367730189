import React, { useState, useRef } from 'react';
import { Col, Row, Button, Modal, ModalHeader, ModalBody, ModalFooter, ListGroup, ListGroupItem, Card, CardBody, CardHeader } from 'reactstrap';
import { CheckCircle } from 'react-feather';
import { useTranslation } from 'react-i18next';
import orderBy from 'lodash/orderBy';
import has from 'lodash/has';
import findIndex from 'lodash/findIndex';

import Masonry from 'react-masonry-css';
import { data as ac } from './../../data/contants/avatarBuilderData';

const requeriedCat = 'faces';

const loadImage = (imgData) => {
    return new Promise(r => { let i = new Image(); i.onload = (() => { imgData.img = i; r(imgData); }); i.src = imgData.url; });
}

function AvatarCreatorModal({ onComplete, onClose }) {
    const { t } = useTranslation();
    const [category, setCategory] = useState({ id: ac.categories[0].id, items: ac.categories[0].items });
    const [avatar, setAvatar] = useState({});
    const canvasRef = useRef();

    const changeCategory = (data) => {
        const face = ac.categories[0].items.find(x => x.preview === avatar['faces']);
        data.items = data.items.filter(x => x.required ? x.required.includes(face.type) : true);

        setCategory(data);
    }

    const updateCanvas = () => {
        if (!canvasRef.current)
            return;

        const ctx = canvasRef.current.getContext('2d');
        let imgLoader = [];

        orderBy(categories, ['zIndex']).forEach(c => {
            let localOffset = {};
            const ava = avatar[c.id];

            if (!ava)
                return;

            const isMulti = Array.isArray(ava);
            if (isMulti && ava.length === 0)
                return;

            (isMulti ? ava : [{ preview: ava }]).forEach(a => {
                const cat = categories.find(x => x.id === c.id);
                const item = cat.items.find(x => x.preview === a.preview);

                if (item && item.offset)
                    localOffset = item.offset;

                imgLoader.push(loadImage({
                    offset: c.offset,
                    localOffset,
                    url: `${window.location.origin}/${a.preview}`
                }));
            });
        });

        Promise.all(imgLoader)
            .then(imgs => {
                ctx.clearRect(0, 0, 130, 130);

                imgs.map(d =>
                    ctx.drawImage(
                        d.img
                        , d.offset.left + (d.localOffset.left || 0)
                        , has(d.offset, 'bottom') || d.localOffset.bottom
                            ? 130 - d.img.height - (d.offset.bottom || 0) - (d.localOffset.bottom || 0)
                            : (has(d.offset, 'top') ? d.offset.top : 0) + (d.localOffset.top || 0)
                    ))
            });
    }

    const face = ac.categories[0].items.find(x => x.preview === avatar['faces']);
    let categories = ac.categories;

    if (face) {
        categories = categories.filter(x => x.required ? x.required.includes(face.type) : true);
    }
    const categoriesLength = categories.length - 1;
    const currentCategoryIndex = findIndex(categories, c => c.id === category.id);

    return (
        <Modal isOpen={true} toggle={onClose} style={{ width: '94%', maxWidth: 1060, height: 615 }}>
            <ModalHeader toggle={onClose}>{t(ac.header)}</ModalHeader>
            <ModalBody>
                <Row className='align-items-center'>
                    <Col className="col-3 align-self-start">
                        <ListGroup style={{ cursor: "pointer" }}>
                            {categories.map((x => {
                                if (x.required) {
                                    const face = categories[0].items.find(x => x.preview === avatar['faces']);
                                    if (!face || !x.required.includes(face.type))
                                        return null;
                                }

                                return <ListGroupItem key={`${x.id}`}
                                    disabled={requeriedCat !== x.id && !avatar[requeriedCat]}
                                    onClick={() => changeCategory({ ...x })}
                                    className={`${avatar[x.id] ? 'list-group-item-success' : ''} ${category.id === x.id ? 'active' : ''}`}
                                >
                                    {t(x.label)}
                                </ListGroupItem>
                            }))}
                        </ListGroup>
                    </Col>
                    <Col className="col-7 align-self-start">
                        <Masonry
                            breakpointCols={4}
                            className="avatar-builder-grid"
                            columnClassName="avatar-builder-grid-column"
                            style={{ height: 450 }}
                        >
                            {category.items.map((c, i) =>
                                <Card key={`${category.id}_${i}`} style={{ width: 120, height: 130, border: '1px solid rgba(0, 0, 0, 0.2)', borderRadius: 12, marginBottom: 20 }}>
                                    <CardBody className='text-center p-2' style={{
                                        cursor: 'pointer'
                                    }} onClick={() => {
                                        if (category.id === "faces") {
                                            const isF = avatar["faces"] && avatar["faces"].includes("female");
                                            const isNewF = c.preview.includes("female");

                                            if (isF !== isNewF) {
                                                setAvatar({
                                                    [category.id]: avatar[category.id] && avatar[category.id] === c.preview ? null : c.preview
                                                });
                                            } else {
                                                setAvatar({
                                                    ...avatar,
                                                    emotions: null,
                                                    [category.id]: avatar[category.id] && avatar[category.id] === c.preview ? null : c.preview
                                                });
                                            }

                                            return;
                                        }

                                        if (category.multiple) {
                                            let res = avatar[category.id] || [];
                                            let withSameType = res.find(x => x.type === c.type);

                                            res = res.filter(x => x.type !== c.type);
                                            if (!withSameType || withSameType.preview !== c.preview)
                                                res.push({ preview: c.preview, type: c.type });

                                            setAvatar({
                                                ...avatar,
                                                [category.id]: res
                                            });

                                            return;
                                        }

                                        setAvatar({
                                            ...avatar,
                                            [category.id]: avatar[category.id] && avatar[category.id] === c.preview ? null : c.preview
                                        });
                                    }}>
                                        {(avatar[category.id] && Array.isArray(avatar[category.id]) ? avatar[category.id].find(x => x.preview === c.preview) : avatar[category.id] === c.preview) && <CheckCircle style={{ position: 'absolute', top: 0, right: 0 }} />}
                                        <img src={`${window.location.origin}/${c.preview}`} alt="" style={Object.assign({ width: 100, height: 110, objectFit: 'scale-down' }, category.id !== requeriedCat ? { position: 'relative', top: '50%', marginTop: '-50%', ...c.style } : {})} />
                                    </CardBody>
                                </Card>
                            )}
                        </Masonry>
                    </Col>
                    <Col className="col-2 align-self-start" style={{ paddingRight: 30 }}>
                        <Card>
                            <CardHeader className='p-2 text-center'>
                                <h6>{t('avatar_builder.preview_label')}</h6>
                            </CardHeader>
                            <CardBody className='p-0'>
                                {!avatar[requeriedCat] &&
                                    <div style={{ position: 'absolute', width: '100%', height: '100%', background: 'rgba(255,255,255,0.5)', zIndex: 1, backdropFilter: 'grayscale(1)' }} />
                                }
                                <div id="avatar-container" className="position-relative mt-2" style={{ width: 130, height: 130, left: 0, top: 0 }}>
                                    <canvas id="avatar-canvas" ref={canvasRef} width={130} height={130} style={{ borderRadius: "50% 50% 0 0" }} />
                                    {updateCanvas()}
                                    {!avatar[requeriedCat] &&
                                        <img src={`${window.location.origin}/${categories[0].items[0].preview}`} alt="" className="position-absolute" style={
                                            {
                                                top: 20,
                                                left: 15,
                                            }
                                        } />
                                    }
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                {/* <Row>
                    <Col>
                        {avatar.result && <img src={avatar.result} />}
                    </Col>
                </Row> */}
            </ModalBody>
            <ModalFooter className='justify-content-around'>
                <Row className='w-100 justify-content-end'>
                    <Col className='col-auto'>
                        <Button className='text-nowrap btn-block' color='primary' disabled={!avatar[requeriedCat] || currentCategoryIndex <= 0} onClick={() => {
                            const next = Math.max(currentCategoryIndex - 1, 0);

                            changeCategory({ ...categories[next] });
                        }}>{t('avatar_builder.prev_step_btn')}</Button>
                    </Col>
                    <Col className='col-auto'>
                        <Button className='text-nowrap btn-block' color='success' autoFocus disabled={!avatar[requeriedCat]} onClick={() => {
                            const canvas = document.getElementById('avatar-canvas');
                            onComplete(canvas.toDataURL());
                        }}>{t('avatar_builder.ok_btn')}</Button>
                    </Col>
                    {currentCategoryIndex < categoriesLength &&
                        <Col className='col-auto'>
                            <Button className='text-nowrap btn-block' color='primary' disabled={!avatar[requeriedCat] || currentCategoryIndex >= categoriesLength} onClick={() => {
                                const next = Math.min(currentCategoryIndex + 1, categoriesLength);

                                changeCategory({ ...categories[next] });
                            }}>{t('avatar_builder.next_step_btn')}</Button>
                        </Col>
                    }
                </Row>
            </ModalFooter>
        </Modal>
    );
}

export default AvatarCreatorModal;
