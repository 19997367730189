import { RESET } from '../actions';
import { ASYNC_REQUEST_COMPLETED, ASYNC_REQUEST_STARTED, RECEIVE_MEDIA_INFO, RECEIVE_AGORA_TRACKS, RECEIVE_AGORA_CALL_STATE, TOGGLE_MODAL, RECEIVE_AGORA_DEVICES } from './../actions/AppActions';

const getMedia = () => {
  const media = localStorage.getItem('media.setting');

  return media
    ? JSON.parse(media)
    : {
      cam: false,
      mic: true,
      audio: true
    }
}

const initialState = {
  media: getMedia(),
  inProgress: {},
  agora: {
    joinState: false,
    remoteUsers: [],
    tracks: {
      audio: null,
      video: null,
      screen: null,
    },
    devices: {
      audio: null,
      video: null,
      output: null,
      audioDeviceId: null,
      videoDeviceId: null,
      outputDeviceId: null,
    }
  },
  modals: {}
};


export default function (state = initialState, action) {
  switch (action.type) {
    case RESET:
      return initialState;
    case RECEIVE_MEDIA_INFO:
      const media = { ...state.media, ...action.payload };
      localStorage.setItem('media.setting', JSON.stringify(media));

      return {
        ...state,
        media
      }
    case RECEIVE_AGORA_TRACKS: {
      return {
        ...state,
        agora: {
          ...state.agora,
          tracks: {
            ...state.agora.tracks,
            ...action.payload
          }
        }
      }
    }
    case RECEIVE_AGORA_DEVICES: {
      return {
        ...state,
        agora: {
          ...state.agora,
          devices: {
            ...state.agora.devices,
            ...action.payload
          }
        }
      }
    }
    case RECEIVE_AGORA_CALL_STATE: {
      return {
        ...state,
        agora: {
          ...state.agora,
          ...action.payload
        }
      }
    }
    case ASYNC_REQUEST_STARTED:
    case ASYNC_REQUEST_COMPLETED:
      return {
        ...state,
        inProgress: {
          ...state.inProgress,
          [action.payload.type]: action.payload
        }
      }
    case TOGGLE_MODAL:
      const { type, isOpen } = action.payload;
      return {
        ...state,
        modals: {
          ...state.modals,
          [type]: { isOpen }
        }
      }
    default:
      return state;
  }
}