import { getPrivateChatApi, getChatHistoryApi, getPrivateChatHistoryApi } from '../../api/chat';
import { getActionConst } from './../../../utils/ReduxHelper';

export const ChatMessageType = {
    systemInfo: 1,
    systemWarn: 2,
    systemError: 3,
    systemPrivateInfo: 501,
    systemPrivateWarn: 502,
    systemPrivateError: 503,
    html: 1000,
    image: 2000,
    video: 3000,
    sound: 4000
}

export const RECEIVE_ROOM_CHAT_MESSAGE = getActionConst('RECEIVE_ROOM_CHAT_MESSAGE');
export const RECEIVE_CHAT_HISTORY = getActionConst('RECEIVE_CHAT_HISTORY');
export const RECEIVE_PRIVATE_CHAT_HISTORY = getActionConst('RECEIVE_PRIVATE__CHAT_HISTORY');
export const STARTED_ROOM_CHAT = getActionConst('STARTED_ROOM_CHAT');
export const STARTED_PRIVATE_CHAT = getActionConst('STARTED_PRIVATE_CHAT');
export const FOCUS_ON_CHAT = getActionConst('FOCUS_ON_CHAT');
export const MARK_AS_READ = getActionConst('MARK_AS_READ');
export const TOGGLE_ROOM_CHAT = getActionConst('TOGGLE_ROOM_CHAT');
export const CHANGE_ROOM_CHAT = getActionConst('CHANGE_ROOM_CHAT');

export function markMessageAsReaded(details) {
    return { type: MARK_AS_READ, payload: details };
}

export function receiveRoomChatMessage(details) {
    return { type: RECEIVE_ROOM_CHAT_MESSAGE, payload: details };
}

export function privateChatStarted(payload) {
    return { type: STARTED_PRIVATE_CHAT, payload };
}

export function roomChatStarted(payload) {
    return { type: STARTED_ROOM_CHAT, payload };
}

export function receiveChatHistory(payload) {
    return { type: RECEIVE_CHAT_HISTORY, payload };
}

export function receivePrivateChatHistory(payload) {
    return { type: RECEIVE_PRIVATE_CHAT_HISTORY, payload };
}

export function setFocusOnChat(payload) {
    return { type: FOCUS_ON_CHAT, payload };
}

export function setRoomChat(payload) {
    return { type: CHANGE_ROOM_CHAT, payload };
}

export function toggleRoomChat(payload) {
    return { type: TOGGLE_ROOM_CHAT, payload };
}

export function startRoomChat(roomId) {
    return async (dispatch) => {
        try {
            dispatch(roomChatStarted({ roomId }));
        } catch (err) { };
    };
}

export function startPrivateChat(participationId) {
    return async (dispatch) => {
        try {
            const { data } = await getPrivateChatApi(participationId);
            await dispatch(privateChatStarted(data));
            dispatch(setFocusOnChat(data));
        } catch (err) { };
    };
}

export function getChatHistory(chatId, from, to) {
    return async (dispatch) => {
        try {
            const { data } = await getChatHistoryApi(chatId, new Date(from).toJSON(), new Date(to).toJSON());
            dispatch(receiveChatHistory(data));
        } catch (err) { };
    };
}

export function getPrivateChatHistory(chatId, from, to) {
    return async (dispatch) => {
        try {
            const { data } = await getPrivateChatHistoryApi(chatId, new Date(from).toJSON(), new Date(to).toJSON());
            dispatch(receivePrivateChatHistory(data));
        } catch (err) { };
    };
}