import { CLEAR_TEAM_DATA } from '../actions/TeamActions';
import { RESET } from './../actions';
import { RECEIVE_USER_INFO, RECEIVE_USER_STATUS, RECEIVE_USER_ROLE } from './../actions/UserActions';

const initialState = {
  id: '',
  accountId: '',
  email: '',
  firstName: '',
  lastName: '',
  middleName: '',
  status: {
    statusCode: ''
  },
  defaultTeam: {},
  role: {
    permissions: []
  }
};

export default function (state = initialState, action) {
  switch (action.type) {
    case RESET:
      return initialState;
    case RECEIVE_USER_INFO:
      return {
        ...state,
        ...action.payload
      };
    case RECEIVE_USER_STATUS:
      return {
        ...state,
        status: {
          ...action.payload,
          statusCode: action.payload.availabilityStatus
        }
      };
    case RECEIVE_USER_ROLE:
      return {
        ...state,
        role: {
          ...action.payload[0]
        }
      };
    case CLEAR_TEAM_DATA: {
      return {
        ...state,
        role: {
          permissions: []
        }
      }
    }
    default:
      return state;
  }
}