import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container } from 'reactstrap';

import CreateOrganization from '../components/CreateOrganization';
import TeamArea from '../components/TeamArea';

const selectUserInfo = state => state.user;
const selectOrganizations = state => state.organizations;

function IndexPage() {
    const [onLoad, setLoadingState] = React.useState(false);

    //const dispatch = useDispatch();
    const organizations = useSelector(selectOrganizations);
    const user = useSelector(selectUserInfo);

    if (onLoad)
        return <></>;


    return (
        <Container fluid>
            {(!user.defaultTeam && organizations.length < 1)
                ? <CreateOrganization />
                : <TeamArea />
            }
        </Container>
    );
}

export default IndexPage;
