import { getActionConst } from './../../../utils/ReduxHelper';

export const RECEIVE_MEDIA_INFO = getActionConst('RECEIVE_MEDIA_INFO');
export const ASYNC_REQUEST_STARTED = getActionConst('ASYNC_REQUEST_STARTED');
export const ASYNC_REQUEST_COMPLETED = getActionConst('ASYNC_REQUEST_COMPLETED');
export const RECEIVE_AGORA_TRACKS = getActionConst('RECEIVE_AGORA_TRACKS');
export const RECEIVE_AGORA_CALL_STATE = getActionConst('RECEIVE_AGORA_CALL_STATE');
export const TOGGLE_MODAL = getActionConst('TOGGLE_MODAL');
export const RECEIVE_AGORA_DEVICES = getActionConst('RECEIVE_AGORA_DEVICES');

export const DEFAULT_MIC_LEVEL = 80;
export const DEFAULT_SOUND_LEVEL = 100;

export const AsyncRequestType = {
    knock: 'knock',
    initCall: 'init_call'
}

export const ModalType = {
    devices: 'devices',
    mediaMenu: 'media_menu'
}

export function receiveMediaInfo(payload) {
    return { type: RECEIVE_MEDIA_INFO, payload };
}

export function receiveAgoraTracks(payload) {
    return { type: RECEIVE_AGORA_TRACKS, payload };
}

export function receiveAgoraDevices(payload) {
    return { type: RECEIVE_AGORA_DEVICES, payload };
}

export function receiveAgoraCallState(payload) {
    return { type: RECEIVE_AGORA_CALL_STATE, payload };
}

export function asyncRequestStarted(payload) {
    return { type: ASYNC_REQUEST_STARTED, payload };
}

export function asyncRequestCompleted(payload) {
    return { type: ASYNC_REQUEST_COMPLETED, payload };
}

export function toggleModal(payload) {
    return { type: TOGGLE_MODAL, payload };
}