import React from 'react';
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap';
import { Trans, useTranslation } from 'react-i18next';

import { Home, Users } from 'react-feather';
import css from './modals.module.scss';
import logo from './../../assets/images/logo/Icon-150_150.png';

function WelcomeModal({ isOpen, onClose }) {
    const { t } = useTranslation();

    return (
        <Modal isOpen={isOpen} toggle={onClose} >
            <ModalBody className={css.modalWrapper}>
                <div>
                    <div className='page-title text-center mb-4'>
                        <div className="logo-wrapper mb-2">
                            <img src={logo} style={{ width: 54 }} alt='teamblaze' />
                        </div>
                        <h5>{t('welcome_modal.header')}</h5>
                    </div>
                    <div>
                        <div className='mb-4'>
                            <h6>{t('welcome_modal.description')}</h6>
                        </div>
                        <div className='mb-4'>
                            <div className='d-flex align-items-center mb-2'>
                                <Home size={32} />
                                <h6 className='ml-2 mb-0'>{t('welcome_modal.organization_label')}</h6>
                            </div>
                            <div className='d-flex align-items-center'>
                                <Users size={32} />
                                <h6 className='ml-2 mb-0'>{t('welcome_modal.invite_members_label')}</h6>
                            </div>
                        </div>
                        <div>
                            <h6>
                                <Trans
                                    i18nKey="welcome_modal.contacts"
                                    values={{
                                        email: 'contact@teamblaze.io',
                                    }}
                                >
                                    Contact <a href='mailto:contact@teamblaze.io'>email</a>
                                </Trans>
                            </h6>
                        </div>
                    </div>
                </div>
            </ModalBody>
            <ModalFooter>
                <Button
                    variant='contained'
                    color='success'
                    onClick={onClose}
                    className={css.submitBtn}
                    autoFocus
                >
                    {t('welcome_modal.ok')}
                </Button>
            </ModalFooter>
        </Modal>
    );
}

export default WelcomeModal;