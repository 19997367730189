import React from 'react';
import { useSelector } from 'react-redux';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

import { Button, FormGroup, Row, Col } from 'reactstrap';

import { postInvitationsApi } from './../../data/api/invite';
import { RFInput, RFCheckbox, RFMultiCheckbox } from './../common/RFInput';
import RFDatePicker from './../common/RFDatePicker';
import { useHistory } from 'react-router';
import { RoutePaths } from '../../data/contants';

const selectRoleData = state => state.user.role;

function CreateInviteForm({ onSuccess, onClose }) {
    const { teamId, teamName } = useSelector(selectRoleData);
    const history = useHistory();
    const { t } = useTranslation();
    //const { rooms } = useSelector(state => state.team);

    return (
        <Formik
            initialValues={{
                team: teamName || '',
                email: '',
                isOneTimeInvite: true
            }}
            validationSchema={Yup.object({
                email: Yup.array().transform((_, str) => str.split(',').map(s => s.trim())).of(Yup.string().email().required()).required()
            })}
            onSubmit={async (values, { setSubmitting }) => {
                if (!teamId) {
                    console.warn('Empty teamId');
                    return;
                }

                setSubmitting(true);

                const { email, emailText, isOneTimeInvite } = values;
                const emails = email.split(',').map(e => e.trim());
                const expireAt = (new Date()).setDate(new Date().getDate() + 7);

                postInvitationsApi(
                    {
                        teamId,
                        email: emails,
                        emailText,
                        expiresAt: new Date(expireAt).toJSON(),
                        isMemberInvite: true,
                        isPublic: !isOneTimeInvite,
                        publicRoomSettings: {
                            showAvatars: true,
                            showMembersDetails: true,
                            roomChatEnabled: true,
                            voiceCallEnabled: true,
                            videoCallEnabled: true
                        }
                    })
                    .then((resp) => {
                        if (!onSuccess)
                            return;

                        onSuccess(resp.data);
                    })
                    .catch(console.warn);

                setSubmitting(false);
            }}
        >
            {({ submitForm, isSubmitting }) => (
                <Form>
                    {/* <div>
                        <Field
                            component={RFInput}
                            name='team'
                            type='text'
                            label={t('team_management_page.create_invite_form.label_team')}
                            disabled
                        />
                    </div> */}
                    <FormGroup>
                        <Field
                            component={RFInput}
                            name='email'
                            type='text'
                            label={t('team_management_page.create_invite_form.label_email')}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Field
                            component={RFInput}
                            name='emailText'
                            label={t('team_management_page.create_invite_form.label_emailText')}
                            type='textarea'
                        />
                    </FormGroup>
                    <FormGroup className='d-flex align-items-center'>
                        <Field
                            component={RFCheckbox}
                            id='checkbox_isPublicInvite'
                            name='isOneTimeInvite'
                            type='checkbox'
                            label={t('team_management_page.create_invite_form.label_isPublicInvite')}
                            disabled
                        />
                    </FormGroup>
                    <div>
                        <Row>
                            <Col className='col-auto'>
                                <Button
                                    color='success'
                                    disabled={isSubmitting}
                                    onClick={submitForm}
                                >
                                    {t('common.btn.submit')}
                                </Button>
                            </Col>
                            <Col className='col-auto'>
                                <Button
                                    color='secondary'
                                    disabled={isSubmitting}
                                    onClick={onClose}
                                >
                                    {t('common.btn.gotoOffice')}
                                </Button>
                            </Col>
                        </Row>
                    </div>
                </Form>
            )}
        </Formik>
    );
}

export default CreateInviteForm;
