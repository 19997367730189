import React from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, ModalBody } from 'reactstrap';

import Loader from '../common/Loader';

function OnLoadModal({ isOpen }) {
    const { t } = useTranslation();

    return (
        <Modal isOpen={isOpen}>
            <ModalBody>
                <div className='mb-4 text-center align-items-center'>
                    <div>
                        <h6>{t('system_messages.call_connecting')}</h6>
                    </div>
                    <Loader />
                </div>
            </ModalBody>
        </Modal>
    );
}

export default OnLoadModal;
