import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Modal, ModalBody, Row, Col } from 'reactstrap';
import { agoraDevicesSelector, agoraJoinStateSelector, agoraTracksSelector, deviceSettingsIsOpen } from '../../data/store/selectors/appSelectors';
import AgoraRTC from 'agora-rtc-sdk-ng';
import Select from 'react-select';
import { ModalType, receiveAgoraDevices, toggleModal } from '../../data/store/actions/AppActions';
import uniqBy from 'lodash/unionBy';

let checkIntervalId = null;

function DeviceCheckModal() {
    const tracks = useSelector(agoraTracksSelector);
    const devices = useSelector(agoraDevicesSelector);
    const isOpen = useSelector(deviceSettingsIsOpen);
    const appSettings = useSelector(state => state.app.media);
    const joinState = useSelector(agoraJoinStateSelector);

    const dispatch = useDispatch();
    const { t } = useTranslation();

    const [audioDevices, setAudioDevices] = useState([]);
    const [videoDevices, setVideoDevices] = useState([]);
    const [outputDevices, setOuputDevices] = useState([]);
    const [micLevel, setMicLevel] = useState(0);

    useEffect(() => {
        if (!isOpen) {
            checkIntervalId && clearInterval(checkIntervalId);

            return;
        }

        AgoraRTC.getDevices()
            .then((devices) => {
                console.log('DEVICES:', devices);
                const options = devices.map(d => ({ value: d.deviceId, label: d.label, kind: d.kind, groupId: d.groupId }));
                const out = uniqBy(options.filter(d => d.kind === "audiooutput"), 'groupId');

                setAudioDevices(uniqBy(options.filter(d => d.kind === "audioinput"), 'groupId'));
                setVideoDevices(uniqBy(options.filter(d => d.kind === "videoinput"), 'groupId'));
                setOuputDevices(out);
            })
            .catch(console.warn);

        checkIntervalId && clearInterval(checkIntervalId);

        checkIntervalId = setInterval(() => {
            const level = tracks.audio?.getVolumeLevel();
            setMicLevel(Math.min((level || 0) * 2.0, 1.0));
        }, 100);

        return () => {
            checkIntervalId && clearInterval(checkIntervalId);
        }
    }, [isOpen, tracks.audio]);

    const handleAudioChange = (selectedOption) => {
        try {
            dispatch(receiveAgoraDevices({ audio: selectedOption.label, audioDeviceId: selectedOption.value }));

            if (!tracks.audio)
                return;

            tracks.audio.setDevice(selectedOption.value);
        } catch (e) { console.warn(e); }
    };

    const handleVideoChange = (selectedOption) => {
        try {
            dispatch(receiveAgoraDevices({ video: selectedOption.label, videoDeviceId: selectedOption.value }));

            if (!tracks.video)
                return;

            tracks.video.setDevice(selectedOption.value);
        } catch (e) { console.warn(e); }
    };

    const handleOutputChange = (selectedOption) => {
        try {
            dispatch(receiveAgoraDevices({ output: selectedOption.label, outputDeviceId: selectedOption.value }));
        } catch (e) { console.warn(e); }
    };

    const onClose = () => {
        dispatch(toggleModal({ type: ModalType.devices, isOpen: false }));
    }

    return (
        <Modal isOpen={isOpen} toggle={onClose}>
            <ModalBody>
                <div className='text-center align-items-center'>
                    <Row className='align-items-center mb-2'>
                        <Col className='col-2'>
                            <span>Output: </span>
                        </Col>
                        <Col className='pl-0'>
                            <Select
                                value={outputDevices.find(t => t.value === devices.outputDeviceId)}
                                onChange={handleOutputChange}
                                options={outputDevices}
                            />
                        </Col>
                    </Row>
                    <Row className='align-items-center mb-2'>
                        <Col className='col-2'>
                            <span>Camera: </span>
                        </Col>
                        <Col className='pl-0'>
                            <Select
                                value={videoDevices.find(t => t.label === devices.video)}
                                onChange={handleVideoChange}
                                options={videoDevices}
                            />
                        </Col>
                    </Row>
                    <Row className='align-items-center'>
                        <Col className='col-2'>
                            <span>Mic: </span>
                        </Col>
                        <Col className='pl-0'>
                            <Select
                                value={audioDevices.find(t => t.label === devices.audio)}
                                onChange={handleAudioChange}
                                options={audioDevices}
                            />
                        </Col>
                    </Row>
                    {(appSettings.mic && joinState)
                        ? (
                            <div className='mt-4 mb-4 align-items-center'>
                                <h6>Mic check</h6>
                                <div style={{ display: 'inline-block', width: 200, height: 20, border: '1px solid lightgray', borderRadius: 4 }}>
                                    <div style={{ backgroundColor: 'lightgreen', width: micLevel * 200, height: 20, borderRadius: 4 }} />
                                </div>
                            </div>
                        )
                        : (
                            <div className='mt-4 mb-4 align-items-center'>
                                <h6>Microphone test is only available during a call</h6>
                            </div>
                        )
                    }
                    <div>
                        <Button color='primary' autoFocus onClick={onClose}>{t('common.btn.ok')}</Button>
                    </div>
                </div>
            </ModalBody>
        </Modal >
    );
}

export default DeviceCheckModal;
