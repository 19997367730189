import sortBy from 'lodash/sortBy';
import get from 'lodash/get';

import { getActionConst } from './../../../utils/ReduxHelper';
import { getTeamRoomsApi, getTeamsApi, getCurrentPositionInTeamApi, getTeamMembersApi, removeTeamMemberApi, putTeamApi } from './../../api/team';

export const RECEIVE_ROOMS = getActionConst('RECEIVE_ROOMS', 'TEAM');
export const RECEIVE_USER_ONLINE_STATUS = getActionConst('RECEIVE_USER_ONLINE_STATUS', 'TEAM');
export const RECEIVE_USER_ROOM_STATE = getActionConst('RECEIVE_USER_ROOM_STATE', 'TEAM');
export const RECEIVE_ROOM_CALL_STATE = getActionConst('RECEIVE_ROOM_CALL_STATE', 'TEAM');
export const RECEIVE_ROOM_DOOR_STATE = getActionConst('RECEIVE_ROOM_DOOR_STATE', 'TEAM');
export const RECEIVE_ROOM_ENTER_REQUEST = getActionConst('RECEIVE_ROOM_ENTER_REQUEST', 'TEAM');
export const RECEIVE_ROOM_INVITE_REQUEST = getActionConst('RECEIVE_ROOM_INVITE_REQUEST', 'TEAM');
export const RECEIVE_ROOM_ENTER_REQUEST_PROCESSED = getActionConst('RECEIVE_ROOM_ENTER_REQUEST_PROCESSED', 'TEAM');
export const RECEIVE_ROOM_INVITE_REQUEST_PROCESSED = getActionConst('RECEIVE_ROOM_INVITE_REQUEST_PROCESSED', 'TEAM');
export const RECEIVE_TEAMS = getActionConst('RECEIVE_TEAMS', 'TEAM');
export const RECEIVE_TEAM = getActionConst('RECEIVE_TEAM', 'TEAM');
export const RECEIVE_CURRENT_TEAM = getActionConst('RECEIVE_CURRENT_TEAM', 'TEAM');
export const RECEIVE_TEAM_MEMBERS = getActionConst('RECEIVE_TEAM_MEMBERS', 'TEAM');
export const CLEAR_TEAM_DATA = getActionConst('CLEAR_TEAM_DATA', 'TEAM');
export const RECEIVE_USER_STATUS = getActionConst('RECEIVE_USER_STATUS', 'TEAM');

export function triggerClearTeamData() {
    return { type: CLEAR_TEAM_DATA };
}

export function receiveRooms(payload) {
    return { type: RECEIVE_ROOMS, payload };
}

export function receiveUserOnlineStatus(payload) {
    return { type: RECEIVE_USER_ONLINE_STATUS, payload };
}

export function receiveUserRoomState(payload) {
    return { type: RECEIVE_USER_ROOM_STATE, payload };
}

export function receiveRoomCallState(payload) {
    return { type: RECEIVE_ROOM_CALL_STATE, payload };
}

export function receiveRoomDoorState(payload) {
    return { type: RECEIVE_ROOM_DOOR_STATE, payload };
}

export function receiveUserStatus(payload) {
    return { type: RECEIVE_USER_STATUS, payload };
}

export function receiveTeams(payload) {
    return { type: RECEIVE_TEAMS, payload };
}

export function receiveTeam(payload) {
    return { type: RECEIVE_TEAM, payload };
}

export function receiveCurrentTeam(payload) {
    return { type: RECEIVE_CURRENT_TEAM, payload };
}

export function receiveRoomEnterRequest(payload) {
    return { type: RECEIVE_ROOM_ENTER_REQUEST, payload };
}

export function receiveRoomEnterRequestProcessed(payload) {
    return { type: RECEIVE_ROOM_ENTER_REQUEST_PROCESSED, payload };
}

export function receiveRoomInviteRequest(payload) {
    return { type: RECEIVE_ROOM_INVITE_REQUEST, payload };
}

export function receiveRoomInviteRequestProcessed(payload) {
    return { type: RECEIVE_ROOM_INVITE_REQUEST_PROCESSED, payload };
}

export function receiveTeamMembers(payload) {
    return { type: RECEIVE_TEAM_MEMBERS, payload };
}

export function getRooms(teamId) {
    return async (dispatch) => {
        try {
            const { data } = await getTeamRoomsApi(teamId)
            const rooms = sortBy(data, 'order');

            dispatch(receiveRooms(rooms));

            return rooms;
        } catch (e) { };
    };
}

export function getTeams() {
    return async (dispatch) => {
        try {
            const resp = await getTeamsApi();
            dispatch(receiveTeams(resp.data));
        } catch (e) { };
    };
}

export function updateTeam(data) {
    return async (dispatch) => {
        try {
            const resp = await putTeamApi(data);
            dispatch(receiveTeam(resp.data));
        } catch (e) { };
    };
}

export function getCurrentPositionInTeam() {
    return async (dispatch) => {
        try {
            const resp = await getCurrentPositionInTeamApi();
            //dispatch(receiveCurrentTeam(get(resp, 'data.team.id')));

            return get(resp, 'data.team.id');
        } catch (e) { };
    };
}

export function getTeamMembers(teamId) {
    return async (dispatch) => {
        try {
            const { data } = await getTeamMembersApi(teamId);
            dispatch(receiveTeamMembers(data));

            return data;
        } catch (e) { };
    };
}

export function removeTeamMember(teamId, userId) {
    return async (dispatch) => {
        try {
            const { data } = await removeTeamMemberApi(teamId, userId);
            dispatch(receiveTeamMembers(data));

            return data;
        } catch (e) { };
    };
}