import axios from 'axios';
import { Configuration } from './../config';

export function getPrivateChatApi(participantId) {
    return axios.get(`${Configuration.basePath}/api/chat/private/${participantId}`);
}

export function getChatHistoryApi(roomId, fromUtcDate, toUtcDate) {
    return axios.post(`${Configuration.basePath}/api/chat/room/${roomId}/history`, { fromUtcDate, toUtcDate });
}

export function getPrivateChatHistoryApi(teamId, fromUtcDate, toUtcDate) {
    return axios.get(`${Configuration.basePath}/api/chat/team/${teamId}/privaterooms`);
}